import React, { useEffect, useRef, useState } from 'react';
import { match } from 'ts-pattern';
import { LinearGradient } from 'expo-linear-gradient';
import { DARK_GRAY, PRIMARY_COLOR, PRIMARY_COLOR_LIGHT, WHITE } from '../../styles/appColor';
import { Image, StyleSheet, View } from 'react-native';
import { useQuery } from '@tanstack/react-query';
import { validateTVParameters } from '../../services/url.utils';
import { ScreenNames } from '../../ScreenNames';
import { getPlaylistScreenDetailsFromCode } from '../../services/api/tv.api';
import { AppLoader } from '../../components/atomic/AppLoader';
import { NavigationProp, ParamListBase, RouteProp } from '@react-navigation/native';
import WeatherForecastWidget from '../../components/molecules/WeatherForecastWidget';
import { vh } from '../../utils/Utils';
import { ScreenPlaylistScreenPlaylistItemType } from '../../entities/ScreenPlaylistScreenPlaylistItem';
import { Pdf } from '../../components/atomic/Pdf';
import { getDocumentURI, getPictureURI } from '../../services/api/helper.api';
import TVScreenYoutubeVideo from '../../components/atomic/TVScreenYoutubeVideo';
import FacebookPostsWidget from '../../components/molecules/FacebookPostsWidget';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import FullScreenButtonOverlay from '../../components/atomic/FullScreenButtonOverlay';
import LinkedInPostsWidget from '../../components/molecules/LinkedInPostsWidget';
import { isValidColor, lightenDarkenColor } from '../../utils/Color';

declare var document: any;

export type TVPublicScreenProps = {
  route: RouteProp<any, any>;
  navigation: NavigationProp<ParamListBase>;
};

export default function TVPublicScreen({ route, navigation }: TVPublicScreenProps) {
  const code = route.params?.code;
  const [currentIndex, setCurrentIndex] = useState(0);
  const nodeRef = useRef(null);

  // Redirect to main screen if code is invalid
  useEffect(() => {
    if (!validateTVParameters(code)) {
      navigation.navigate(ScreenNames.Main);
    }
  }, [code]);

  // Every seconds, check if CRISP is loaded and hide it
  useEffect(() => {
    const interval = setInterval(() => {
      const crispElems = document.getElementsByClassName('crisp-client');
      if (crispElems.length > 0) {
        clearInterval(interval);
        crispElems[0].style.display = 'none';
      }
    }, 1000);

    return () => {
      clearInterval(interval);
      const crispElems = document.getElementsByClassName('crisp-client');
      if (crispElems.length > 0) {
        crispElems[0].style.display = 'block';
      }
    };
  }, []);

  const { data: screenDetails, isLoading: isLoadingDetails } = useQuery(
    ['getPlaylistScreenDetailsFromCode', code],
    () => getPlaylistScreenDetailsFromCode(code)
  );
  const isLoading = isLoadingDetails;

  const backgroundColor = (
    isValidColor(screenDetails?.backgroundColor) ? screenDetails?.backgroundColor : PRIMARY_COLOR
  ) as string;
  const backgroundSecondaryColor = lightenDarkenColor(backgroundColor, 30);

  const handleVideoEnd = () => {
    setCurrentIndex((currentIndex + 1) % (screenDetails?.items.length ?? 0) || 0);
  };

  // If the current screen is not a video, go to the next one after the delay
  useEffect(() => {
    if (
      screenDetails?.items[currentIndex]?.type === ScreenPlaylistScreenPlaylistItemType.Media &&
      screenDetails?.items[currentIndex]?.screenPlaylistMedia?.videoUrl
    ) {
    } else {
      const timeout = setTimeout(() => {
        setCurrentIndex((currentIndex + 1) % (screenDetails?.items.length ?? 0) || 0);
      }, (currentItem?.delay || 1) * 1000);

      return () => clearTimeout(timeout);
    }
  }, [currentIndex, screenDetails?.items]);

  const currentItem = screenDetails?.items[currentIndex];

  if (isLoading || screenDetails === undefined) {
    return <AppLoader />;
  } else {
    return (
      <LinearGradient
        colors={[backgroundColor || PRIMARY_COLOR, backgroundSecondaryColor || WHITE]}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 1 }}
        style={styles.container}
      >
        <SwitchTransition mode="out-in">
          <CSSTransition<HTMLDivElement>
            key={currentItem?.id || 0}
            in={!!currentItem}
            nodeRef={nodeRef}
            addEndListener={(done) => {
              (nodeRef.current as any)?.addEventListener('transitionend', done, false);
            }}
            classNames="fade-slow"
          >
            <div ref={nodeRef} style={styles.container}>
              {currentItem &&
                match(currentItem.type)
                  .with(ScreenPlaylistScreenPlaylistItemType.Media, () =>
                    !currentItem.screenPlaylistMedia ? (
                      <View />
                    ) : currentItem.screenPlaylistMedia.videoUrl ? (
                      <TVScreenYoutubeVideo
                        youtubeUrl={currentItem.screenPlaylistMedia.videoUrl}
                        onVideoEnd={handleVideoEnd}
                      />
                    ) : currentItem.screenPlaylistMedia.documentId &&
                      currentItem.screenPlaylistMedia?.document?.format === 'application/pdf' ? (
                      <Pdf
                        source={getDocumentURI(currentItem.screenPlaylistMedia.documentId)}
                        style={styles.container}
                      />
                    ) : currentItem.screenPlaylistMedia.imageId ? (
                      <Image style={styles.container} source={getPictureURI(currentItem.screenPlaylistMedia.imageId)} />
                    ) : (
                      <View />
                    )
                  )
                  .with(ScreenPlaylistScreenPlaylistItemType.Meteo, () =>
                    screenDetails?.latitude && screenDetails?.longitude ? (
                      <WeatherForecastWidget latitude={screenDetails.latitude} longitude={screenDetails.longitude} />
                    ) : (
                      <View />
                    )
                  )
                  .with(ScreenPlaylistScreenPlaylistItemType.Facebook, () => (
                    <FacebookPostsWidget code={screenDetails.code} />
                  ))
                  .with(ScreenPlaylistScreenPlaylistItemType.LinkedIn, () => (
                    <LinkedInPostsWidget code={screenDetails.code} />
                  ))
                  .otherwise(() => <View />)}
            </div>
          </CSSTransition>
        </SwitchTransition>
        {/*<FullScreenButtonOverlay />*/}
      </LinearGradient>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    display: 'flex',
  },
  columnContainer: {
    flex: 1,
    flexDirection: 'row',
    zIndex: 1,
  },
  header: {
    height: vh(8),
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: vh(1),
    flexDirection: 'row',
    color: DARK_GRAY,
    marginTop: vh(1),
  },
  dateTimeContainer: {
    flexDirection: 'row',
    paddingTop: vh(0.25),
  },
  timeText: {
    fontSize: vh(6),
  },
  dateText: {
    fontSize: vh(2.5),
  },
  dateContainer: {
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    marginBottom: vh(2),
    marginLeft: vh(2),
  },
  logoContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  logo: {
    height: '100%',
    width: '100%',
  },
  weatherContainer: {
    justifyContent: 'flex-end',
  },
  column: {
    flex: 1,
    margin: vh(2),
    flexDirection: 'column',
  },
  newsCard: {
    padding: 0,
    flex: 1,
  },
  rssContainer: {
    flex: 1,
    marginRight: vh(2),
  },
  footer: {
    flexDirection: 'row',
    margin: vh(2),
  },
});
