import axios from 'axios';
import { apiUrlBase } from '../../../configuration';
import { CompanyStatistics } from '../../entities/CompanyStatistics';
import { ContributionsCountByIntervalStatistics } from '../../entities/ContributionsCountByIntervalStatistics';
import { ContributionsCountByIntervalStatisticsFilter } from '../../entities/ContributionsCountByIntervalStatisticsFilter';
import { NewsCountByIntervalStatisticsFilter } from '../../entities/NewsCountByIntervalStatisticsFilter';
import { TopNewsStatistics } from '../../entities/TopNewsStatistics';
import { UserSaved } from '../storage';
import { headers } from './base';

export async function getCompanyStatistics(user: UserSaved): Promise<CompanyStatistics> {
  return axios
    .get(`${apiUrlBase}statistics/company`, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function getTopNewsStatistics(user: UserSaved): Promise<TopNewsStatistics> {
  return axios
    .get(`${apiUrlBase}statistics/top-news`, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function getNewsByIntervalStatistics(
  user: UserSaved,
  filters: NewsCountByIntervalStatisticsFilter
): Promise<ContributionsCountByIntervalStatistics> {
  return axios
    .get(`${apiUrlBase}statistics/news-by-interval`, {
      headers: headers(user.token),
      params: filters,
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function getContributionsCountByIntervalStatistics(
  user: UserSaved,
  filters: ContributionsCountByIntervalStatisticsFilter
): Promise<ContributionsCountByIntervalStatistics> {
  return axios
    .get(`${apiUrlBase}statistics/contributions-by-interval`, {
      headers: headers(user.token),
      params: filters,
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}
