import React from 'react';
import { StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native';
import AppText from './AppText';

export interface ChipProps {
  text: string;
  subtext?: string | undefined | null;
  color: string;
  style?: StyleProp<ViewStyle>;
  onPress?: () => void;
  disable?: boolean;
  selected?: boolean;
  small?: boolean;
  inverted?: boolean;
}

export const Chip = ({ text, subtext, color, style, disable, onPress, selected, small, inverted }: ChipProps) => (
  <TouchableOpacity
    disabled={disable ?? !onPress}
    onPress={onPress}
    style={[
      {
        backgroundColor: inverted ? 'white' : color,
        height: subtext ? 46 : 32,
        minWidth: 128,
        padding: 4,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 16,
        borderWidth: !!selected || inverted ? 2 : 0,
        borderColor: inverted ? color : '#4FDFC4',
      },
      style,
      small ? { display: 'flex', height: 28 } : {},
    ]}
  >
    <AppText style={{ color: inverted ? color : 'white' }} numberOfLines={1}>
      {text}
    </AppText>
    {!!subtext && (
      <AppText style={{ color: inverted ? color : 'white', fontSize: 10 }} numberOfLines={1}>
        {subtext}
      </AppText>
    )}
  </TouchableOpacity>
);
