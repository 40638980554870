import { useState } from 'react';
import React from 'react';
import { Pressable, StyleSheet, TouchableOpacity, View } from 'react-native';
import { useQuery } from '@tanstack/react-query';
import { useField } from 'formik';
import * as Yup from 'yup';
import Feather from 'react-native-vector-icons/Feather';
import { t } from '../../services/translations';
import TextField from '../atomic/formik/TextField';
import Separator from '../atomic/Separator';
import AppText from '../atomic/AppText';
import { ALERT_COLOR, PRIMARY_COLOR } from '../../styles/appColor';
import DraggableFlatList from 'react-native-draggable-flatlist';
import { FAB } from '@rneui/themed';
import SubmitButton from '../atomic/formik/SubmitButton';
import { SelectField } from '../atomic/formik/SelectField';
import { ScreenPlaylistScreenPlaylistItemType } from '../../entities/ScreenPlaylistScreenPlaylistItem';
import { ScreenPlaylistItemDetails } from '../../entities/ScreenPlaylistDetails';
import { listScreenMedias } from '../../services/api/tv.api';
import useLoggedUser from '../../hooks/useLoggedUser';
import { GestureHandlerRootView } from 'react-native-gesture-handler';

export const schema = Yup.object({
  name: Yup.string().min(1, t('field_required')).required(t('field_required')),
  items: Yup.array()
    .of(
      Yup.object().shape({
        type: Yup.mixed().oneOf(Object.values(ScreenPlaylistScreenPlaylistItemType)).required(t('field_required')),
        screenPlaylistMediaId: Yup.number().nullable(),
        delay: Yup.number().min(1).required(t('field_required')),
      })
    )
    .min(1),
});

export default function ScreenPlaylistFormFields() {
  const user = useLoggedUser();
  const [{ value: items }, , { setValue: setItems }] = useField<(ScreenPlaylistItemDetails | null)[]>('items');
  const { data: playlistMedias } = useQuery(['listScreenMedias'], () => listScreenMedias(user), { cacheTime: 0 });

  return (
    <GestureHandlerRootView>
      <TextField name="name" mandatory required title={t('title')} />

      <DraggableFlatList
        data={items}
        onDragEnd={({ from, to }) => {
          const newData = [...items];
          newData.splice(to, 0, newData.splice(from, 1)[0]);
          setItems(newData);
        }}
        keyExtractor={(item, index) => index.toString()}
        ItemSeparatorComponent={Separator}
        ListHeaderComponent={
          <View style={styles.itemContainer}>
            <View style={styles.itemActions} />
            <AppText style={styles.tableHeader}>{t('my_medias')}</AppText>
            <AppText style={styles.tableHeader}>{t('display_duree')}</AppText>
            <View style={styles.itemActions} />
          </View>
        }
        renderItem={({ item, drag, getIndex }) => {
          const isVideo =
            item?.type === ScreenPlaylistScreenPlaylistItemType.Media &&
            item?.screenPlaylistMediaId &&
            !!playlistMedias?.find((media) => media.id === item?.screenPlaylistMediaId)?.videoUrl;
          const index = getIndex() as number;

          return (
            <View style={styles.itemContainer}>
              <Pressable style={styles.itemActions} onPressIn={drag}>
                <Feather size={24} name="menu" color={PRIMARY_COLOR} style={styles.actionIcon} />
              </Pressable>
              <View style={styles.tableHeader}>
                <SelectField<ScreenPlaylistScreenPlaylistItemType | string>
                  name={
                    item?.type === ScreenPlaylistScreenPlaylistItemType.Media
                      ? `items.${index}.screenPlaylistMediaIdString`
                      : `items.${index}.type`
                  }
                  required
                  style={{ flex: 1 }}
                  onSelected={(value) => {
                    const item = items[index];

                    if (value === ScreenPlaylistScreenPlaylistItemType.Meteo) {
                      setItems([
                        ...items.slice(0, index),
                        {
                          type: ScreenPlaylistScreenPlaylistItemType.Meteo,
                          screenPlaylistMediaId: null,
                          delay: item?.delay || 0,
                        } as any as ScreenPlaylistItemDetails,
                        ...items.slice(index + 1),
                      ]);
                    } else if (value === ScreenPlaylistScreenPlaylistItemType.Facebook) {
                      setItems([
                        ...items.slice(0, index),
                        {
                          type: ScreenPlaylistScreenPlaylistItemType.Facebook,
                          screenPlaylistMediaId: null,
                          delay: item?.delay || 0,
                        } as any as ScreenPlaylistItemDetails,
                        ...items.slice(index + 1),
                      ]);
                    } else if (value === ScreenPlaylistScreenPlaylistItemType.LinkedIn) {
                      setItems([
                        ...items.slice(0, index),
                        {
                          type: ScreenPlaylistScreenPlaylistItemType.LinkedIn,
                          screenPlaylistMediaId: null,
                          delay: item?.delay || 0,
                        } as any as ScreenPlaylistItemDetails,
                        ...items.slice(index + 1),
                      ]);
                    } else {
                      const strValue = value as string;
                      const id = parseInt(strValue.replace('id', ''), 10);
                      const isVideo = !!playlistMedias?.find((media) => media.id === id && media.videoUrl);

                      setItems([
                        ...items.slice(0, index),
                        {
                          type: ScreenPlaylistScreenPlaylistItemType.Media,
                          screenPlaylistMediaId: id,
                          screenPlaylistMediaIdString: value,
                          delay: item?.delay || isVideo ? 1 : 0,
                        } as any as ScreenPlaylistItemDetails,
                        ...items.slice(index + 1),
                      ]);
                    }
                  }}
                  data={[
                    {
                      label: t('weather_widget'),
                      value: ScreenPlaylistScreenPlaylistItemType.Meteo,
                    },
                    {
                      label: t('facebook_widget'),
                      value: ScreenPlaylistScreenPlaylistItemType.Facebook,
                    },
                    {
                      label: t('linkedin_widget'),
                      value: ScreenPlaylistScreenPlaylistItemType.LinkedIn,
                    },
                    ...(playlistMedias
                      ? playlistMedias.map((media) => ({
                          label: media.name,
                          value: `id${media.id}`,
                        }))
                      : []),
                  ]}
                />
              </View>
              <View style={styles.tableHeader}>
                {!isVideo && (
                  <SelectField<number>
                    name={`items.${index}.delay`}
                    required
                    style={{ flex: 1 }}
                    data={[
                      {
                        label: t('seconds', { value: Math.floor(1) }),
                        value: 1,
                      },
                      {
                        label: t('seconds', { value: Math.floor(5) }),
                        value: 5,
                      },
                      {
                        label: t('seconds', { value: Math.floor(10) }),
                        value: 10,
                      },
                      {
                        label: t('seconds', { value: Math.floor(15) }),
                        value: 15,
                      },
                      {
                        label: t('seconds', { value: Math.floor(30) }),
                        value: 30,
                      },
                      {
                        label: t('minutes', { value: Math.floor(1) }),
                        value: 60,
                      },
                      {
                        label: t('minutes', { value: Math.floor(5) }),
                        value: 300,
                      },
                      {
                        label: t('minutes', { value: Math.floor(10) }),
                        value: 600,
                      },
                      {
                        label: t('minutes', { value: Math.floor(15) }),
                        value: 900,
                      },
                      {
                        label: t('minutes', { value: Math.floor(30) }),
                        value: 1800,
                      },
                    ]}
                  />
                )}
              </View>
              <TouchableOpacity
                style={styles.actionIcon}
                onPress={() => {
                  const newData = [...items];
                  newData.splice(index, 1);
                  setItems(newData);
                }}
              >
                <Feather size={24} name="trash" color={ALERT_COLOR} style={styles.actionIcon} />
              </TouchableOpacity>
            </View>
          );
        }}
      />
      <FAB
        visible
        title={t('add_media')}
        upperCase
        icon={{ name: 'add', color: 'white' }}
        color={PRIMARY_COLOR}
        size="small"
        onPress={() => {
          setItems([...items, null]);
        }}
      />
      <SubmitButton text={t('put')} />
    </GestureHandlerRootView>
  );
}

const styles = StyleSheet.create({
  itemContainer: {
    flexDirection: 'row',
    marginVertical: 10,
  },
  tableHeader: {
    color: PRIMARY_COLOR,
    flex: 1,
  },
  itemColumn: {
    flex: 1,
  },
  itemActions: {
    color: PRIMARY_COLOR,
    minWidth: 24,
  },
  actionIcon: {
    marginRight: 10,
  },
});
