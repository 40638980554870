import { array, number, object, string } from 'yup';
import { SpreadingType } from '../entities/SpreadingType';
import { CompanyNewsUpload } from '../models/CompanyNews';
import { getCompanyNewsDetail } from '../services/api/news.contributions.api';
import { UserSaved } from '../services/storage';
import useEditContribution from './useEditContribution';
import useLoggedUser from './useLoggedUser';

const fetcher =
  (user: UserSaved) =>
  (id: number): Promise<Partial<CompanyNewsUpload>> =>
    getCompanyNewsDetail(user, id).then((value) => ({
      titre: value.titre,
      texte: value.texte,
      translation: value.translations.map((translation) => ({
        lng: translation.languageCode,
        titre: translation.titre,
        description: translation.texte,
      })),
      videoUrl: value.videoUrl,
      commentsActivated: value.mActu.commentsActivated,
      notifActivated: value.mActu.notifActivated,
      emailActivated: value.mActu.emailActivated,
      fichierId: value.imageId,
      spreadingType: value.mActu.spreadingType as any as number,
      userIds: value.mActu.userTargets.map((e) => e.userId),
      entrepriseServiceIds: value.mActu.entrepriseServices.map((e) => e.entrepriseServiceId),
      entrepriseSiteGeoIds: value.mActu.entrepriseSiteGeos.map((e) => e.entrepriseSiteGeoId),
      entrepriseGroupIds: value.mActu.entrepriseGroups.map((e) => e.entrepriseGroupId),
      visibleStartDate: value.visibleStartDate || value.dateCreation,
      visibleEndDate: value.visibleEndDate,
      categoryId: value.mActu.categoryId,
      publicationMode: !value.visibleStartDate
        ? 'immediate_posting'
        : !value.visibleEndDate
        ? 'planned_with_start_date'
        : 'planned_with_start_and_end_date',
    }));

const useEditCompanyNews = (id: number | undefined) => {
  const user = useLoggedUser();
  const initial: Partial<CompanyNewsUpload> = {
    entrepriseId: user.entrepriseId,
    userId: user.id,
    translations: [],
    titre: '',
    texte: '',
    featured: false,
    commentsActivated: true,
    notifActivated: true,
    emailActivated: false,
    publicationMode: null,
  };

  const validationSchema = object({
    titre: string().required().min(1),
    texte: string().required().min(1),
    spreadingType: number().required(),
    userIds: array(number()).when('spreadingType', (spreadingType, schema) => {
      if (spreadingType === SpreadingType.Users) {
        return schema.required().min(1);
      }
    }),
    entrepriseSiteGeoIds: array(number()).when('spreadingType', (spreadingType, schema) => {
      if ([SpreadingType.Sites, SpreadingType.SitesAndServices].includes(spreadingType)) {
        return schema.required().min(1);
      }
    }),
    entrepriseServiceIds: array(number()).when('spreadingType', (spreadingType, schema) => {
      if ([SpreadingType.Services, SpreadingType.SitesAndServices].includes(spreadingType)) {
        return schema.required().min(1);
      }
    }),
    entrepriseGroupIds: array(number()).when('spreadingType', (spreadingType, schema) => {
      if (spreadingType === SpreadingType.Groups) {
        return schema.required().min(1);
      }
    }),
  });

  return useEditContribution<CompanyNewsUpload>(id, initial, fetcher(user), validationSchema);
};

export default useEditCompanyNews;
