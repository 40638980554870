import React from 'react';
import { SpreadingPostData } from '../../models/SpreadingPostData';
import { PickUsers } from '../atomic/PickUsers';

export interface PickUsersFormProps<T extends SpreadingPostData> {
  onChange: (key: keyof T) => (value: any) => void;
  data: Partial<T>;
}

const PickUsersForm = <T extends SpreadingPostData>({ onChange, data }: PickUsersFormProps<T>) => {
  return (
    <PickUsers
      enabled
      spreadingType={data.spreadingType}
      pickedUsers={data.userIds ?? []}
      pickedServices={data.entrepriseServiceIds ?? []}
      pickedGeoSites={data.entrepriseSiteGeoIds ?? []}
      pickedGroups={data.entrepriseGroupIds ?? []}
      setSpreadingType={(value) => onChange('spreadingType')(isNaN(value as number) ? null : value)}
      setPickedUsers={(users) => onChange('userIds')(Array.from(users))}
      setPickedGeoSites={(geosites) => onChange('entrepriseSiteGeoIds')(Array.from(geosites))}
      setPickedGroups={(groups) => onChange('entrepriseGroupIds')(Array.from(groups))}
      setPickedServices={(services) => onChange('entrepriseServiceIds')(Array.from(services))}
    />
  );
};

export default PickUsersForm;
