import React, { createRef, useCallback, useEffect, useState } from 'react';
import { KeyboardType, View } from 'react-native';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Picker, { IEmojiData } from 'emoji-picker-react';

export const colors = [
  '#000000',
  '#e60000',
  '#ff9900',
  '#ffff00',
  '#008a00',
  '#0066cc',
  '#9933ff',
  '#ffffff',
  '#facccc',
  '#ffebcc',
  '#ffffcc',
  '#cce8cc',
  '#cce0f5',
  '#ebd6ff',
  '#bbbbbb',
  '#f06666',
  '#ffc266',
  '#ffff66',
  '#66b966',
  '#66a3e0',
  '#c285ff',
  '#888888',
  '#a10000',
  '#b26b00',
  '#b2b200',
  '#006100',
  '#0047b2',
  '#6b24b2',
  '#444444',
  '#5c0000',
  '#663d00',
  '#666600',
  '#003700',
  '#002966',
  '#3d1466',
];

var Link = Quill.import('formats/link');
var builtInFunc = Link.sanitize;
Link.sanitize = function customSanitizeLinkInput(linkValueInput: string) {
  let PROTOCOL_WHITELIST = ['http:', 'https:', 'mailto:'];
  for (let i = 0; i < PROTOCOL_WHITELIST.length; i++)
    if (linkValueInput.startsWith(PROTOCOL_WHITELIST[i])) return builtInFunc.call(this, linkValueInput);
  return builtInFunc.call(this, `https://${linkValueInput}`);
};

let id = 0;

const getId = (): number => {
  id += 1;
  return id;
};

export function AppRTEEditor(props: {
  maxLength?: number;
  displayOnly?: boolean;
  style: any;
  onSubmitEditing?: any;
  title: any;
  defaultValue: string;
  onChangeText: any;
  keyboardType?: KeyboardType;
  secureTextEntry?: boolean;
}) {
  const reactQuill = createRef<ReactQuill>();
  const [openEmoji, setOpenEmoji] = useState(false);
  const [id] = useState(getId().toString());

  const onEmojiClick = useCallback(
    (data: IEmojiData) => {
      if (!reactQuill.current) return;
      const quill = reactQuill.current.getEditor();
      if (!quill) return null;
      const position = quill.getSelection()?.index ?? quill.getLength() - 1;

      quill.insertText(position, data.emoji, 'silent');
      setOpenEmoji(false);
    },
    [reactQuill]
  );

  const Toolbar = useCallback(
    () => (
      <div id={'toolbar-' + id}>
        <button className="ql-bold"></button>
        <button className="ql-italic"></button>
        <button className="ql-link"></button>
        <select className="ql-color">
          {colors.map((color) => (
            <option value={color} key={color}></option>
          ))}
        </select>
        <button onClick={() => setOpenEmoji((prev) => !prev)}>🙂</button>
      </div>
    ),
    [setOpenEmoji]
  );

  return (
    <View style={{ flex: 1 }}>
      {openEmoji && (
        <Picker
          native
          disableSearchBar
          disableSkinTonePicker
          onEmojiClick={onEmojiClick}
          pickerStyle={{
            position: 'absolute',
            'z-index': '100',
            'margin-top': '40px',
            border: '1px solid gray',
          }}
        />
      )}
      <Toolbar />
      <ReactQuill
        ref={reactQuill}
        formats={['bold', 'italic', 'link', 'color']}
        theme="snow"
        modules={{
          toolbar: {
            container: '#toolbar-' + id,
          },
        }}
        onFocus={() => setOpenEmoji(false)}
        value={props.defaultValue ?? ''}
        onChange={props.onChangeText}
        style={{ minHeight: '300px', marginBottom: 100 }}
      />
    </View>
  );
}
