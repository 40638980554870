import React, { useState } from 'react';
import { language, t } from '../../services/translations';
import { TextFormField } from '../../components/atomic/FormField';
import { AppCheckbox } from '../../components/atomic/AppCheckbox';
import { AppButton, Type } from '../../components/atomic/AppButton';
import { alertInfo, isMailValid, openURL } from '../../services/utils';
import { useDispatch } from 'react-redux';
import { saveLanguage, saveToken } from '../../services/storage';
import { setLoading } from '../../store/action';
import AppText, { Mode } from '../../components/atomic/AppText';
import { HypertextLink } from '../../components/atomic/HypertextLink';
import { TwoColumnsLayout } from '../../components/containers/TwoColumnsLayout';
import { Space1, Space2 } from '../../styles/spaces';
import { FormBody } from '../../components/containers/FormBody';
import { FormButtons } from '../../components/containers/FormButtons';
import {
  checkCompanyCode,
  ErrorReason,
  generateValidationCode,
  login,
  register,
  registerWithCompanyCode,
} from '../../services/api/login';
import { AppLanguagePicker } from '../../components/atomic/AppLanguagePicker';
import { ScreenNames } from '../../ScreenNames';
import { ActivityIndicator, View, Platform } from 'react-native';
import { Icon } from '@rneui/base';
import { PRIMARY_COLOR } from '../../styles/appColor';
import { urlBase } from '../../../configuration';
import { setUser } from '../../store/user';
import { NavigationProp, useNavigation } from '@react-navigation/native';

const registrationBackground = require('../../../assets/registration-background.png');

export function Registration() {
  const navigation = useNavigation<NavigationProp<any>>();
  const [emailAddress, setEmailAddress] = useState(__DEV__ ? 'alexandre.perles@gmail.com' : '');
  const [password, setPassword] = useState(__DEV__ ? 'testtest' : '');
  const [passwordConfirmation, setPasswordConfirmation] = useState(__DEV__ ? 'testtest' : '');
  const [hasCGUBeenApproved, setCGUApproved] = useState(false);
  const [hasRGDPConsent, setRGPDConsent] = useState(false);
  const [codeShown, setCodeShown] = useState(false);
  const [companyCode, setCompanyCode] = useState('');
  const [companyCodeCorresponding, setCompanyCodeCorresponding] = useState(undefined);
  const [companyCodeConfirmation, setCompanyCodeConfirmation] = useState(undefined);
  const [companyCodeLoading, setCompanyCodeLoading] = useState(false);
  const dispatch = useDispatch();

  const validateCompanyCode = () => {
    setCompanyCodeLoading(true);
    checkCompanyCode(companyCode)
      .then((entreprise) => {
        setCompanyCodeCorresponding(entreprise);
        setCompanyCodeConfirmation(true);
      })
      .catch((e) => {
        setCompanyCodeCorresponding(undefined);
        setCompanyCodeConfirmation(false);
      })
      .finally(() => {
        setCompanyCodeLoading(false);
      });
  };

  function _register() {
    if (!isFormReadyToBeSent()) return;
    dispatch(setLoading(true));
    if (codeShown) {
      registerWithCompanyCode(emailAddress, password, hasCGUBeenApproved, hasRGDPConsent, companyCode)
        .then(() => {
          return login(emailAddress, password);
        })
        .then((userData) => {
          saveToken(userData.token);
          return setUser({ ...userData }, dispatch);
        })
        .catch((e) => {
          alertInfo(t('unknown_error'));
        })
        .finally(() => dispatch(setLoading(false)));
    } else {
      register(emailAddress, password, hasCGUBeenApproved, hasRGDPConsent)
        .then(async (id) => {
          await generateValidationCode(emailAddress);
          return id;
        })
        .then((id) => {
          navigation.navigate(ScreenNames.ValidationCode, { email: emailAddress });
        })
        .catch((e) => {
          if (e.message === ErrorReason.ACCOUNT_ALREADY_EXISTS) {
            alertInfo(t('account_already_exists'));
          } else {
            alertInfo(t('unknown_error'));
          }
        })
        .finally(() => dispatch(setLoading(false)));
    }
  }

  const isFormReadyToBeSent = () => {
    let isValid =
      isMailValid(emailAddress) &&
      password.length >= 4 &&
      password === passwordConfirmation &&
      hasCGUBeenApproved &&
      hasRGDPConsent;
    if (codeShown) {
      isValid = isValid && companyCodeConfirmation;
    }
    return isValid;
  };

  let [, forceRefresh] = useState({});

  return (
    <TwoColumnsLayout background={registrationBackground}>
      <FormBody>
        <TextFormField
          keyboardType="email-address"
          title={t('email_address')}
          value={emailAddress}
          onChangeText={setEmailAddress}
        />

        <Space2 />
        <TextFormField secureTextEntry={true} title={t('password')} value={password} onChangeText={setPassword} />
        <Space2 />
        <TextFormField
          secureTextEntry={true}
          title={t('password_confirmation')}
          value={passwordConfirmation}
          onChangeText={setPasswordConfirmation}
        />
        {password !== passwordConfirmation && passwordConfirmation.length > 0 ? (
          <AppText mode={Mode.ERROR}>{t('passwords_dont_match')}</AppText>
        ) : null}
        <Space2 />
        <AppCheckbox value={codeShown} onValueChange={setCodeShown} title={t('i_have_company_code') + ' '} />
        {codeShown && (
          <>
            <Space1 />
            <TextFormField
              keyboardType={Platform.OS === 'ios' ? 'default' : 'visible-password'}
              title={t('company_code')}
              autoCapitalize="characters"
              value={companyCode}
              onChangeText={(v) => {
                const newV = v.replace(/\W/g, '').toUpperCase();
                setCompanyCode(newV);
                setCompanyCodeConfirmation(undefined);
              }}
              onBlur={() => {
                validateCompanyCode();
              }}
            />
            {companyCodeLoading && (
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <ActivityIndicator color={PRIMARY_COLOR} size="small" />
              </View>
            )}
            {companyCodeConfirmation !== undefined && !companyCodeLoading && (
              <>
                {companyCodeConfirmation ? (
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <View
                      style={{
                        borderWidth: 1,
                        borderRadius: 100,
                        height: 24,
                        width: 24,
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderColor: 'green',
                      }}
                    >
                      <Icon size={16} name="check" color="green" />
                    </View>
                    <AppText style={{ color: 'green', fontWeight: 'bold', marginLeft: 6 }}>
                      {t('code_valid')}
                      {companyCodeCorresponding ? ' : ' + companyCodeCorresponding.nom : ''}
                    </AppText>
                  </View>
                ) : (
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <View
                      style={{
                        borderWidth: 1,
                        borderRadius: 100,
                        height: 24,
                        width: 24,
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderColor: 'red',
                      }}
                    >
                      <Icon size={16} name="close" color="red" />
                    </View>
                    <AppText style={{ color: 'red', fontWeight: 'bold', marginLeft: 6 }}>{t('code_invalid')}</AppText>
                  </View>
                )}
              </>
            )}
          </>
        )}
        <Space1 />
        <AppCheckbox
          value={hasCGUBeenApproved}
          onValueChange={setCGUApproved}
          title={
            <>
              {t('read_and_accept') + ' '}
              <HypertextLink
                onPress={() => {
                  openURL(`${urlBase}assets/documents/CGU-KonecTeam_2022.pdf`);
                }}
                text={t('cgu')}
              />
            </>
          }
        />
        <Space1 />
        <AppCheckbox
          value={hasRGDPConsent}
          onValueChange={setRGPDConsent}
          title={
            <>
              {t('rgpd_text_1') + ' '}
              <HypertextLink
                onPress={() => {
                  openURL(`${urlBase}assets/documents/Politique-de-confidentialité-2022.pdf`);
                }}
                text={t('privacy')}
              />
              .
            </>
          }
          subtitle={t('rgpd_text_2')}
        />
      </FormBody>

      <FormButtons>
        <Space2 />
        <AppButton
          title={t('register_button')}
          type={Type.PRIMARY}
          enabled={isFormReadyToBeSent()}
          onPress={_register}
        />
        <Space2 />
        <AppButton title={t('login_button')} onPress={() => navigation.navigate(ScreenNames.Login)} />
        <Space1 />
        <AppLanguagePicker
          containerStyle={{ alignSelf: 'center', width: 200 }}
          onLanguageChanged={(lng) => {
            saveLanguage(lng).then(() => {
              forceRefresh({});
            });
          }}
          defaultValue={language()}
        />
      </FormButtons>
    </TwoColumnsLayout>
  );
}
