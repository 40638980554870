import { useCallback, useMemo } from 'react';
import { FlatList, StyleSheet, View } from 'react-native';
import { match } from 'ts-pattern';
import { contributionColors } from '../../services/api/contributions';
import { t } from '../../services/translations';
import AppText from './AppText';
import { LIGHT_GRAY, WHITE } from '../../styles/appColor';
import { vh } from '../../utils/Utils';
import { MActuEntrepriseGroup } from '../../entities/MActuEntrepriseGroup';
import { MActuEntrepriseService } from '../../entities/MActuEntrepriseService';
import { MActuEntrepriseSiteGeo } from '../../entities/MActuEntrepriseSiteGeo';
import { SpreadingType } from '../../types';

const colorList = Object.values(contributionColors);

export type TVScreenSpreadingChipsListProps = {
  item: {
    id: number;
    spreadingType: SpreadingType;
    entrepriseServices: MActuEntrepriseService[];
    entrepriseSiteGeos: MActuEntrepriseSiteGeo[];
    entrepriseGroups: MActuEntrepriseGroup[];
  };
};

export default function TVScreenSpreadingChipsList({ item }: TVScreenSpreadingChipsListProps) {
  const spreadingItems = useMemo(() => {
    if (!item) {
      return [];
    } else {
      return match(item)
        .with({ spreadingType: SpreadingType.ENTREPRISE }, () => [t('company_wide')])
        .with({ spreadingType: SpreadingType.USERS }, () => [t('some_users')])
        .with({ spreadingType: SpreadingType.SERVICES }, () =>
          item.entrepriseServices.map((e) => e.entrepriseService?.libelle)
        )
        .with({ spreadingType: SpreadingType.SITES }, () =>
          item.entrepriseSiteGeos.map((e) => e.entrepriseSiteGeo?.siteGeo)
        )
        .with({ spreadingType: SpreadingType.SITES_AND_SERVICES }, () => [
          ...item.entrepriseServices.map((e) => e.entrepriseService?.libelle),
          ...item.entrepriseSiteGeos.map((e) => e.entrepriseSiteGeo?.siteGeo),
        ])
        .with({ spreadingType: SpreadingType.GROUPS }, () => item.entrepriseGroups.map((e) => e.entrepriseGroup?.label))
        .otherwise(() => [t('company_wide')]);
    }
  }, [item]);

  const getLabelColor = useCallback((label: string | null | undefined) => {
    if (!label || label.length == 0) {
      return colorList[0];
    }

    let hash = 0;
    for (let i = 0; i < label.length; i++) {
      const char = label.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash;
    }

    return colorList[Math.abs(hash) % colorList.length];
  }, []);

  return (
    <FlatList
      horizontal
      style={styles.container}
      data={spreadingItems}
      keyExtractor={(item) => item}
      renderItem={({ item }) => (
        <View style={[styles.chipContainer, { backgroundColor: getLabelColor(item) }]}>
          <AppText style={styles.text} numberOfLines={1}>
            {item}
          </AppText>
        </View>
      )}
    />
  );
}

const styles = StyleSheet.create({
  container: {
    height: vh(3),
  },
  chipContainer: {
    height: vh(3),
    minWidth: vh(12),
    paddingHorizontal: vh(1.5),
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: vh(1.5),
    borderWidth: 2,
    borderColor: LIGHT_GRAY,
    marginRight: vh(1),
  },
  text: {
    color: WHITE,
    fontSize: vh(1.5),
  },
});
