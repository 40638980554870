import React from 'react';
import translate, { DeeplLanguages } from 'deepl';
import { Icon } from '@rneui/base';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useDispatch } from 'react-redux';
import { Translation } from '../../models/CompanyNews';
import { NEWS_TRANSLATION_ENABLED } from '../../services/features';
import { setLoading } from '../../store/action';
import { PRIMARY_COLOR } from '../../styles/appColor';
import AppText from './AppText';

export interface LanguageSelectorProps {
  titre: string;
  texte: string;
  language: {
    code: string;
    text: string;
  };
  translations: Translation[];
  setTranslations: (value: Translation[]) => void;
}

const LanguageSelector = ({ texte, setTranslations, titre, translations, language: e }: LanguageSelectorProps) => {
  const dispatch = useDispatch();

  return (
    <TouchableOpacity
      key={e.code}
      disabled={NEWS_TRANSLATION_ENABLED}
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 5,
        backgroundColor: 'white',
        borderColor: PRIMARY_COLOR,
        borderWidth: 1,
        marginRight: 10,
        padding: 5,
        borderRadius: 5,
        opacity: !NEWS_TRANSLATION_ENABLED ? 1 : 0.5,
      }}
      onPress={async () => {
        {
          dispatch(setLoading(true));
          let translatedTitle = '';
          if (titre!.length !== 0)
            translatedTitle = await translate({
              free_api: true,
              text: titre!,
              target_lang: e.code.split('-')[0] as DeeplLanguages,
              auth_key: '94f4223b-fcc2-1bee-34e3-4c8f3f62a6b8:fx',
            })
              .then((result) => result.data.translations[0].text)
              .catch((e) => '');
          let translatedDescription = '';
          if (texte!.length !== 0)
            translatedDescription = await translate({
              free_api: true,
              text: texte!,
              target_lang: e.code.split('-')[0] as DeeplLanguages,
              auth_key: '94f4223b-fcc2-1bee-34e3-4c8f3f62a6b8:fx',
            })
              .then((result) => result.data.translations[0].text)
              .catch((e) => '');

          let newTranslations = translations!.filter((el) => el.lng !== e.code);
          newTranslations.push({
            lng: e.code,
            titre: translatedTitle,
            description: translatedDescription,
          });
          setTranslations(newTranslations);
          dispatch(setLoading(false));
        }
      }}
    >
      <Icon name="add" />
      <AppText>{e.text}</AppText>
    </TouchableOpacity>
  );
};

export default LanguageSelector;
