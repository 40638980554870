import { Image, ScrollView, View } from 'react-native';
import { getPictureURI } from '../../services/api/helper.api';
import { FONT_FAMILY_DEFAULT } from '../../styles/globalStyle';
import AppText, { Mode } from './AppText';
import { Icon } from '@rneui/base';
import { HypertextLink } from './HypertextLink';
import { combineNames, isMobile, openURL } from '../../services/utils';
import React, { ReactNode } from 'react';
import { ProfilePicture } from './ProfilePicture';
import { AppModalOverlay } from './AppModalOverlay';
import { headerBackgroundPopup } from '../../services/assets';
import { SECONDARY_COLOR } from '../../styles/appColor';
import { t } from '../../services/translations';
import { User } from '../../entities/User';
import { hasLicenceGoogleChat, hasLicenceMessaging, hasLicenceTeams } from '../../services/licences';
import { navigateToMessagingNewThread } from '../../utils/Messaging';
import { AppButton, Type } from './AppButton';
import { TeamsButton } from './TeamsButton';
import { GoogleChatButton } from './GoogleChatButton';
import { SlackButton } from './SlackButton';
import useLoggedUser from '../../hooks/useLoggedUser';
import FontAwesome6 from 'react-native-vector-icons/FontAwesome6';

function AttributeRow(props: { iconName: string; children: ReactNode; visible: boolean }) {
  if (!props.visible) return null;
  return (
    <View
      style={{
        paddingHorizontal: 20,
        alignItems: 'center',
        flexDirection: 'row',
        marginBottom: 20,
        flex: 1,
      }}
    >
      <View style={{ width: 40 }}>
        <FontAwesome6 name={props.iconName} style={{ marginRight: 10 }} />
      </View>
      <View style={{ flex: 1 }}>{props.children}</View>
    </View>
  );
}

const logoFile = require('../../../assets/adaptive-icon.png');

export function UserProfileOverlay(props: {
  onBackdropPress: any;
  userProfileData: User;
  isVisible?: boolean;
  showAdminTag?: boolean;
}) {
  if (props.userProfileData == undefined) return <></>;
  const user = useLoggedUser();
  const email = props.userProfileData.hideEmail ? '' : props.userProfileData.aspNetUsers?.email;
  const service =
    props.userProfileData.entrepriseService?.libelle === undefined
      ? ''
      : props.userProfileData.entrepriseService?.libelle;
  const siteGeo =
    props.userProfileData.entrepriseSiteGeo?.siteGeo === undefined
      ? ''
      : props.userProfileData.entrepriseSiteGeo?.siteGeo;

  const hasPortable =
    !props.userProfileData.hidePhone &&
    props.userProfileData.portable != null &&
    props.userProfileData.portable?.length !== 0;

  const hasPhone =
    !props.userProfileData.hidePhone &&
    props.userProfileData.telephone != null &&
    props.userProfileData.telephone?.length !== 0;

  return (
    <AppModalOverlay
      overlayStyle={{
        // minHeight: isMobile() ? mobileSize : 450,
        flexDirection: 'column',
        width: 350,
        padding: 0,
        borderRadius: 5,
        overflow: 'hidden',
      }}
      onBackdropPress={props.onBackdropPress}
      isVisible={props.isVisible}
    >
      <ScrollView
        contentContainerStyle={{ alignItems: 'center', justifyContent: 'flex-start', padding: 0, flexGrow: 1 }}
        bounces={false}
      >
        <Image
          source={headerBackgroundPopup}
          style={{
            height: 135,
            width: 350,
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
          resizeMode="cover"
        />
        <View>
          <ProfilePicture
            pictureId={props.userProfileData.fichierIdProfil}
            size="big"
            borderStyle={{ marginTop: -75 }}
            borderColor="white"
          />
          {(props.showAdminTag || true) && (
            <View style={{ position: 'absolute', left: isMobile() ? 100 : 95, top: -40 }}>
              {props.userProfileData.lovIdType == 13 && (
                <View style={{ flexDirection: 'row' }}>
                  <Image source={logoFile} style={{ width: 30, height: 30 }} />
                  <AppText
                    style={{
                      marginLeft: 0,
                      lineHeight: 30,
                      fontSize: isMobile() ? 11 : 12,
                      textAlign: 'center',
                      fontFamily: FONT_FAMILY_DEFAULT.bold,
                    }}
                  >
                    Responsable
                  </AppText>
                </View>
              )}
              {props.userProfileData.lovIdType != 13 && props.userProfileData.userSubAdmins?.length > 0 && (
                <View style={{ flexDirection: 'row' }}>
                  <Image source={logoFile} style={{ width: 30, height: 30 }} />
                  <AppText
                    style={{
                      marginLeft: 0,
                      lineHeight: 30,
                      fontSize: isMobile() ? 11 : 12,
                      textAlign: 'center',
                      fontFamily: FONT_FAMILY_DEFAULT.bold,
                    }}
                  >
                    Administrateur
                  </AppText>
                </View>
              )}
            </View>
          )}
        </View>

        <AppText
          mode={Mode.BOLD}
          style={{
            fontSize: 25,
            marginTop: 21,
            marginBottom: 9,
            textAlign: 'center',
            marginLeft: 10,
            marginRight: 10,
          }}
          numberOfLines={2}
        >
          {combineNames(props.userProfileData)}
        </AppText>
        <Image
          source={getPictureURI(user?.entreprise?.fichierIdLogo)}
          resizeMode="contain"
          style={{ width: 102, height: 34, marginBottom: 28 }}
        />
        <AppText
          mode={Mode.BOLD}
          style={{ marginBottom: 20, color: SECONDARY_COLOR, textAlign: 'center', maxWidth: 300 }}
        >
          {props.userProfileData.fonction}
        </AppText>
        <AppText style={{ marginBottom: 20 }}>{service}</AppText>
        <View style={{ padding: 10, flexDirection: 'column', alignContent: 'center', width: '100%' }}>
          <AttributeRow iconName="location-dot" visible={siteGeo !== undefined && siteGeo?.length !== 0}>
            <AppText>{props.userProfileData.entrepriseSiteGeo?.siteGeo}</AppText>
          </AttributeRow>
          <AttributeRow iconName="envelope" visible={email !== undefined && email?.length !== 0}>
            <HypertextLink onPress={() => openURL('mailto:' + email)} text={email} numberOfLines={1} />
          </AttributeRow>
          <AttributeRow iconName="mobile-screen" visible={hasPortable}>
            <HypertextLink
              onPress={() => openURL('tel:' + props.userProfileData.portable)}
              text={props.userProfileData.portable}
              numberOfLines={1}
            />
          </AttributeRow>
          <AttributeRow iconName="phone" visible={hasPhone}>
            <HypertextLink
              onPress={() => openURL('tel:' + props.userProfileData.telephone)}
              text={props.userProfileData.telephone}
              numberOfLines={1}
            />
          </AttributeRow>
          <TeamsButton email={email} visible={hasLicenceTeams(user)} />
          <SlackButton
            userId={props.userProfileData.slackId}
            teamId={user?.entreprise?.slackTeamId}
            visible={hasLicenceGoogleChat(user) && !!props.userProfileData.slackId && !!user?.entreprise?.slackTeamId}
          />
          <GoogleChatButton
            userId={props.userProfileData.googleChatId}
            visible={hasLicenceGoogleChat(user) && !!props.userProfileData.googleChatId}
          />
          {hasLicenceMessaging(user) && (
            <AppButton
              title={t('send_a_message')}
              type={Type.PRIMARY}
              onPress={() => navigateToMessagingNewThread(props.userProfileData)}
              enabled
            />
          )}
        </View>
      </ScrollView>
      <View style={{ width: '100%', height: 10, backgroundColor: SECONDARY_COLOR }}></View>
    </AppModalOverlay>
  );
}
