import React, { useEffect, useState } from 'react';
import { ImageSourcePropType, StyleSheet, View } from 'react-native';
import useUnsplash from '../../hooks/useUnsplash';
import { t } from '../../services/translations';
import { PickPhotoOrDocumentResult, PickPhotoResult } from '../../services/utils';
import { DARK_GRAY } from '../../styles/appColor';
import { FONT_FAMILY_DEFAULT } from '../../styles/globalStyle';
import { MediasInfos } from '../../utils/Medias';
import { AppModalOverlay } from '../atomic/AppModalOverlay';
import AppText from '../atomic/AppText';
import { TextFormField } from '../atomic/FormField';
import { PhotoPicker } from '../atomic/PhotoPicker';

export interface PhotoFormFieldProps {
  defaultPhoto?: ImageSourcePropType;
  photo: PickPhotoOrDocumentResult | null | undefined;
  videoUrl: string | null | undefined;
  allowPdf?: boolean;
  onPhotoChanged: (photo: PickPhotoOrDocumentResult | null) => void;
  onVideoUrlChanged: (videoUrl: string | null) => void;
}

export default function PhotoFormField({
  defaultPhoto,
  photo,
  videoUrl,
  allowPdf,
  onPhotoChanged,
  onVideoUrlChanged,
}: PhotoFormFieldProps) {
  const { images, isLoading, notifyImageDownload, searchImages, fetchNext } = useUnsplash();
  const [showVideoPopup, setShowVideoPopup] = useState(false);

  useEffect(() => {
    if (videoUrl) {
      for (const mediaName in MediasInfos) {
        const mediaInfo = MediasInfos[mediaName];
        if (mediaInfo.isMedia(videoUrl)) {
          setShowVideoPopup(false);
          onPhotoChanged({ uri: mediaInfo.imagePreview(videoUrl) } as PickPhotoResult);
        }
      }
    }
  }, [videoUrl]);

  return (
    <View style={styles.container}>
      <AppText style={styles.acceptedFormatsText}>{t('accepted_formats')}</AppText>
      <AppText style={styles.idealFormatText}>{`${t('ideal_format')} : 540 x 540 px`}</AppText>
      <PhotoPicker
        defaultPhoto={defaultPhoto}
        photo={photo}
        setPhoto={(value) => {
          onPhotoChanged(value);
          onVideoUrlChanged(null);
        }}
        images={images}
        isLoading={isLoading}
        extraOption={[
          {
            label: t('choose_a_video'),
            icon: 'video',
            onPress: () => setShowVideoPopup(true),
          },
        ]}
        allowPdf={allowPdf}
        onPress={notifyImageDownload}
        searchImages={searchImages}
        fetchNext={fetchNext}
      />
      <AppModalOverlay isVisible={showVideoPopup} onBackdropPress={() => setShowVideoPopup(false)}>
        <AppText style={{ textAlign: 'center' }}>{t('insert_video_link')}</AppText>
        <TextFormField style={{ width: 400 }} value={videoUrl || undefined} onChangeText={onVideoUrlChanged} />
        <AppText style={{ color: 'red', textAlign: 'center' }}>{t('invalid_link')}</AppText>
      </AppModalOverlay>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    marginBottom: 20,
  },
  acceptedFormatsText: {
    fontFamily: FONT_FAMILY_DEFAULT.light,
    fontSize: 11,
    color: DARK_GRAY,
    paddingBottom: 10,
  },
  idealFormatText: {
    paddingBottom: 30,
    fontFamily: FONT_FAMILY_DEFAULT.light,
    fontSize: 11,
    color: DARK_GRAY,
  },
});
