import React from 'react';
import { Login } from '../screens/guest/Login';
import { t } from '../services/translations';
import { ValidationCode } from '../screens/guest/ValidationCode';
import { Registration } from '../screens/guest/Registration';
import { ForgottenPasswordStep1 } from '../screens/guest/ForgottenPasswordStep1';
import { createStackNavigator } from '@react-navigation/stack';
import { ForgottenPasswordStep2 } from '../screens/guest/ForgottenPasswordStep2';
import { ForgottenPasswordStep3 } from '../screens/guest/ForgottenPasswordStep3';
import { NoProfessionalAddress } from '../screens/guest/NoProfessionalAddress';
import { View } from 'react-native';
import { ScreenNames } from '../ScreenNames';
import BackIcon from '../components/atomic/BackIcon';

const Stack = createStackNavigator();

export default function GuestRoute() {
  return (
    <View style={{ flex: 1 }}>
      <Stack.Navigator screenOptions={{ headerShown: false, headerBackImage: BackIcon }}>
        <Stack.Screen name={ScreenNames.Login} component={Login} options={{ title: t('login') }} />
        <Stack.Screen name={ScreenNames.Registration} component={Registration} options={{ title: t('registration') }} />
        <Stack.Screen
          name={ScreenNames.ForgottenPasswordStep1}
          component={ForgottenPasswordStep1}
          options={{ title: t('forgotten_password') }}
        />
        <Stack.Screen
          name={ScreenNames.ForgottenPasswordStep2}
          component={ForgottenPasswordStep2}
          options={{ title: t('forgotten_password') }}
        />
        <Stack.Screen
          name={ScreenNames.ForgottenPasswordStep3}
          component={ForgottenPasswordStep3}
          options={{ title: t('forgotten_password') }}
        />
        <Stack.Screen
          name={ScreenNames.NoProfessionalAddress}
          component={NoProfessionalAddress}
          options={{ title: t('no_professional_address') }}
        />
        <Stack.Screen
          name={ScreenNames.ValidationCode}
          component={ValidationCode}
          options={{
            headerShown: false,
          }}
        />
      </Stack.Navigator>
    </View>
  );
}
