import React, { useMemo } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle, useWindowDimensions } from 'react-native';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import AppText from '../atomic/AppText';
import { LIGHT_GRAY, WHITE } from '../../styles/appColor';
import { getContributionImageSource, getDocumentURI } from '../../services/api/helper.api';
import { ContributionType } from '../../types';
import { FONT_FAMILY_DEFAULT } from '../../styles/globalStyle';
import { formatDateToTimeAgo } from '../../services/utils';
import { vh } from '../../utils/Utils';
import ColoredCard from '../atomic/ColoredCard';
import { contributionTypeToColor, contributionTypeToText } from '../../services/api/contributions';
import { isString } from 'lodash';
import SquaredView from '../atomic/SquaredView';
import { ProfilePicture } from '../atomic/ProfilePicture';
import { User } from '../../entities/User';
import ThreeDotText from '../atomic/ThreeDotText';
import { useScreenOrientation } from '../../hooks/useScreenOrientation';

export type TVScreenContributionCardProps = {
  item: {
    id: number | string;
    type: ContributionType | 'facebook' | 'linkedin';
    title: string | null | undefined;
    description: string | null | undefined;
    imageId: number | string | undefined;
    creationDate: string;
    author?: User | null;
  } | null;
  style?: StyleProp<ViewStyle>;
};

function itemTypeToColor(type: ContributionType | 'facebook' | 'linkedin') {
  if (type === 'facebook') {
    return '#4267B2';
  } else if (type === 'linkedin') {
    return '#0077b5';
  } else {
    return contributionTypeToColor(type);
  }
}

function itemTypeToText(type: ContributionType | 'facebook' | 'linkedin') {
  if (type === 'facebook') {
    return 'Facebook';
  } else if (type === 'linkedin') {
    return 'LinkedIn';
  } else {
    return contributionTypeToText(type);
  }
}

export default function TVScreenContributionCard({ item, style }: TVScreenContributionCardProps) {
  const contributionColor = item ? itemTypeToColor(item.type) : WHITE;
  const { height } = useWindowDimensions();
  const orientation = useScreenOrientation();

  const nodeRef = React.useRef(null);
  const imageSource = useMemo(() => {
    if (item?.imageId && isString(item.imageId)) {
      return item?.imageId;
    } else if (item?.imageId) {
      return getDocumentURI(item.imageId);
    } else if (item?.type === 'facebook') {
      return require('../../../assets/logo-facebook.png');
    } else if (item?.type === 'linkedin') {
      return require('../../../assets/logo-linkedin.png');
    } else {
      return getContributionImageSource(undefined, item?.type as ContributionType) as string;
    }
  }, [item?.imageId, item?.type]);

  const labelComponent = useMemo(() => {
    if (item?.type === 'facebook') {
      return <img src={require('../../../assets/facebook-logo.svg')} style={styles.logo} />;
    } else if (item?.type === 'linkedin') {
      return <img src={require('../../../assets/linkedin-logo.svg')} style={styles.logo} />;
    } else {
      return (
        <View
          style={
            orientation === 'landscape'
              ? [styles.chipContainer, { backgroundColor: contributionColor }]
              : [styles.chipContainer, styles.verticalChipContainer, { backgroundColor: contributionColor }]
          }
        >
          <AppText
            style={orientation === 'landscape' ? styles.chipText : [styles.chipText, styles.verticalChipText]}
            numberOfLines={1}
          >
            {item && itemTypeToText(item.type)}
          </AppText>
        </View>
      );
    }
  }, [item, contributionColor, orientation]);

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition<HTMLDivElement>
        key={item?.id}
        in={!!item}
        nodeRef={nodeRef}
        addEndListener={(done) => {
          (nodeRef.current as any)?.addEventListener('transitionend', done, false);
        }}
        classNames="fade-slide-left"
      >
        <div ref={nodeRef} style={styles.container}>
          {item && (
            <ColoredCard style={[style, styles.card]} color={contributionColor}>
              <View style={styles.container}>
                <SquaredView fit="vertical" style={styles.imageContainer}>
                  <img src={imageSource} style={styles.image} />
                </SquaredView>
                <View style={styles.content}>
                  <View style={orientation === 'landscape' ? styles.header : [styles.header, styles.verticalHeader]}>
                    {labelComponent}
                    {item.creationDate && (
                      <AppText
                        style={
                          orientation === 'landscape'
                            ? styles.headerText
                            : [styles.headerText, styles.verticalHeaderText]
                        }
                      >
                        {formatDateToTimeAgo(new Date(item.creationDate))}
                      </AppText>
                    )}
                  </View>
                  {item.title && (
                    <AppText
                      style={orientation === 'landscape' ? styles.title : [styles.title, styles.verticalTitle]}
                      numberOfLines={2}
                    >
                      {item?.title}
                    </AppText>
                  )}
                  {item.description && (
                    <View style={styles.descriptionContainer}>
                      <ThreeDotText
                        style={
                          orientation === 'landscape'
                            ? styles.description
                            : [styles.description, styles.verticalDescription]
                        }
                        lineHeights={(2.7 * height) / 100}
                      >
                        {item.description}
                      </ThreeDotText>
                    </View>
                  )}
                  {item.author && (
                    <View style={styles.footer}>
                      <ProfilePicture pictureId={item.author.fichierIdProfil} style={styles.authorIcon} size="small" />
                      <AppText
                        numberOfLines={1}
                        style={
                          orientation === 'landscape'
                            ? styles.authorName
                            : [styles.authorName, styles.verticalAuthorName]
                        }
                      >
                        {item.author.prenom} {item.author.nom}
                      </AppText>
                    </View>
                  )}
                </View>
              </View>
            </ColoredCard>
          )}
        </div>
      </CSSTransition>
    </SwitchTransition>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    height: '100%',
  },
  card: {
    padding: 0,
  },
  imageContainer: {
    height: '100%',
  },
  image: {
    opacity: 0.8,
    aspectRatio: 1,
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
  content: {
    flex: 1,
    padding: vh(1),
    maxHeight: vh(35),
  },
  header: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingBottom: vh(2),
  },
  verticalHeader: {
    paddingBottom: vh(1),
  },
  headerText: {
    fontSize: vh(2),
    color: WHITE,
  },
  verticalHeaderText: {
    fontSize: vh(1.5),
  },
  logo: {
    height: vh(3),
    paddingHorizontal: vh(1.5),
    borderRadius: vh(1.5),
    borderWidth: 2,
    borderColor: LIGHT_GRAY,
  },
  chipContainer: {
    height: vh(3),
    minWidth: vh(12),
    paddingHorizontal: vh(1.5),
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: vh(1.5),
    borderWidth: 2,
    borderColor: LIGHT_GRAY,
  },
  verticalChipContainer: {
    height: vh(2.5),
    minWidth: vh(10),
    paddingHorizontal: vh(1),
  },
  chipText: {
    color: WHITE,
    fontSize: vh(1.5),
  },
  verticalChipText: {
    fontSize: vh(1.25),
  },
  title: {
    fontSize: vh(4),
    marginBottom: 20,
    color: WHITE,
    overflow: 'hidden',
  },
  verticalTitle: {
    fontSize: vh(2.5),
    marginBottom: vh(0.5),
  },
  descriptionContainer: {
    flex: 1,
  },
  description: {
    color: WHITE,
    fontSize: vh(2),
    lineHeight: vh(2.5),
    fontFamily: FONT_FAMILY_DEFAULT.regular,
    height: '100%',
    overflow: 'hidden',
  },
  verticalDescription: {
    fontSize: vh(1.5),
    lineHeight: vh(1.75),
  },
  footer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  authorIcon: {
    marginRight: vh(1),
    width: vh(3),
    height: vh(3),
  },
  authorName: {
    fontSize: vh(2),
    color: WHITE,
  },
  verticalAuthorName: {
    fontSize: vh(1.5),
  },
});
