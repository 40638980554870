export const sellImage = require('../../assets/drawable/Vente.jpg');
export const activityImage = require('../../assets/drawable/hobbies.png');
export const mutualAidImage = require('../../assets/drawable/Entraide.jpeg');
export const carPoolImage = require('../../assets/drawable/covoiturage.jpg');
export const eventImage = require('../../assets/drawable/event.jpg');
export const lostPropertyImage = require('../../assets/drawable/perdu.jpg');
export const surveyImage = require('../../assets/drawable/sondage.jpg');
export const suggestionImage = require('../../assets/drawable/idee.jpg');
export const newsImage = require('../../assets/drawable/news-actualite.png');
export const documentImage = require('../../assets/drawable/document.jpg');
export const headerBackgroundPopup = require('../../assets/drawable/backgrd-popup.png');
export const genericBackground = require('../../assets/generic-background.png');
export const genericBackgroundHeader = require('../../assets/generic-background-header.png');
export const teams = require('../../assets/drawable/teams.png');
export const slack = require('../../assets/drawable/slack.png');
export const googleChat = require('../../assets/drawable/google-chat.png');
export const youtube = require('../../assets/drawable/youtube.png');

export const qrcodeStoreImage = require('../../assets/onlink_to_x9fqhu_small.png');
