import { StyleSheet } from 'react-native';
import { FORM_LIGHT_GRAY, PRIMARY_COLOR_LIGHT } from './appColor';

export const FONT_FAMILY_DEFAULT = {
  regular: 'Montserrat-Regular',
  bold: 'Montserrat-SemiBold',
  light: 'Montserrat-Light',
};

export const globalStyle = StyleSheet.create({
  container: {
    flex: 1,
    fontFamily: FONT_FAMILY_DEFAULT.regular,
  },
  safeArea: {
    flex: 1,
  },
  screen: {
    flex: 1,
  },
  logo: {
    width: '100%',
    height: 100,
  },
  formItemTitle: {
    marginTop: 50,
  },
  formHorizontalSpaced: {
    height: 10,
  },
  forgottenPassword: {
    marginTop: 20,
  },
  two_columns_layout: {
    flex: 1,
    flexDirection: 'row',
  },
  column: {
    backgroundColor: '#fff',
    flex: 1,
  },
  checkboxContainer: {
    flexDirection: 'row',
  },
  checkbox: {
    width: 30,
    height: 30,
    alignSelf: 'center',
  },
  appText: {
    fontSize: 14,
    textAlignVertical: 'center',
    color: '#272727',
    fontFamily: FONT_FAMILY_DEFAULT.regular,
  },
  textInput: {
    backgroundColor: 'white',
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderBottomColor: FORM_LIGHT_GRAY,
    borderBottomWidth: 1,
    fontSize: 14,
    paddingLeft: 10,
    marginTop: 0,
    paddingTop: 10,
    paddingBottom: 3,
  },
  pickerTextInput: {
    backgroundColor: 'white',
    borderWidth: 0,
    borderTopWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderBottomWidth: 1,
    borderBottomColor: FORM_LIGHT_GRAY,
    fontSize: 14,
    width: '100%',
  },
  pickerTextInputWeb: {
    backgroundColor: 'white',
    borderWidth: 0,
    borderBottomWidth: 1,
    height: 30,
    borderBottomColor: FORM_LIGHT_GRAY,
    fontSize: 14,
    width: '100%',
    maxWidth: 300,
  },
  listRow: {
    paddingBottom: 18,
    paddingTop: 18,
    borderBottomWidth: 1,
    borderBottomColor: FORM_LIGHT_GRAY,
  },
  hypertextLink: {
    color: '#272727',
    fontSize: 14,
    fontFamily: FONT_FAMILY_DEFAULT.bold,
    textDecorationLine: 'underline',
  },
  buttonDisabled: {
    backgroundColor: '#CCCCCC',
  },
  button: {
    maxWidth: 315,
    width: '100%',
    alignSelf: 'center',
  },
  button_content: {
    minHeight: 25,
    padding: 3,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
  },
  button_primary: {
    borderRadius: 8,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,

    elevation: 3,
  },
  button_light: {
    borderWidth: 2,
    borderColor: PRIMARY_COLOR_LIGHT,
    borderRadius: 8,
  },
  button_ultra_light: {
    borderWidth: 1,
    borderColor: '#d4d4d4',
    borderRadius: 8,
  },
  guest_form_pc: {
    flex: 1,
    maxWidth: 600,
    padding: 10,
    paddingTop: 110,
  },
  guest_form_mobile: {
    flex: 1,
    padding: 10,
  },
  main_column: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  guest_form: {},
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ffffffcc',
  },
  overlayBackground: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: '#00000033',
  },
  avatarPicker: {
    position: 'absolute',
    backgroundColor: '#fff',
    top: '20%',
    left: '20%',
    right: '20%',
    bottom: '20%',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  textInputMultiline: {
    height: 100,
    borderWidth: 1,
    borderColor: FORM_LIGHT_GRAY,
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderRadius: 8,
  },
  detailRow: {
    padding: 18,
    borderBottomWidth: 1,
    borderBottomColor: FORM_LIGHT_GRAY,
  },
});
