import { StyleSheet, Text, View } from 'react-native';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { vh } from '../../utils/Utils';
import useTodayDateTime from '../../hooks/useTodayDateTime';
import { WHITE } from '../../styles/appColor';
import AppText from '../atomic/AppText';
import Separator from '../atomic/Separator';
import WeatherIcon from '../atomic/WeatherIcon';
import { useMemo } from 'react';
import { t } from '../../services/translations';
import WeatherVerticalCard from '../atomic/WeatherVerticalCard';

export type OpenMeteoResponse = {
  current: {
    temperature_2m: number;
    rain: number;
    showers: number;
    snowfall: number;
    cloud_cover: number;
    is_day: number;
  };
  hourly: {
    time: string[];
    temperature_2m: number[];
    rain: number[];
    showers: number[];
    snowfall: number[];
    cloud_cover: number[];
    is_day: number[];
  };
  daily: {
    time: string[];
    temperature_2m_max: number[];
    temperature_2m_min: number[];
    sunrise: string[];
    sunset: string[];
    precipitation_sum: number[];
    wind_speed_10m_max: number[];
  };
};

async function getWheather(latitude: number, longitude: number): Promise<OpenMeteoResponse> {
  const result = await axios.get(
    `https://api.open-meteo.com/v1/forecast?latitude=${latitude}&longitude=-${longitude}&current=temperature_2m,is_day,precipitation,rain,showers,snowfall,wind_speed_10m&hourly=temperature_2m,rain,showers,snowfall,cloud_cover,is_day&daily=temperature_2m_max,temperature_2m_min,sunrise,sunset,precipitation_sum,wind_speed_10m_max&timezone=Europe%2FBerlin&forecast_days=1`
  );
  return result.data;
}

export type WeatherForecastWidgetProps = {
  latitude: number;
  longitude: number;
};

export default function WeatherForecastWidget({ latitude, longitude }: WeatherForecastWidgetProps) {
  const todayDateTime = useTodayDateTime();
  const { data, error, isLoading } = useQuery(
    ['weather-big', latitude, longitude],
    () => getWheather(latitude, longitude),
    {
      refetchInterval: 1000 * 60 * 60,
    }
  );

  const currentWeatherDescription = useMemo(() => {
    if (!data?.current) {
      return '';
    }

    if (data?.current?.cloud_cover > 80) {
      return t('weather.cloudy');
    }

    if (data?.current?.rain > 0) {
      return t('weather.rainy');
    }

    if (data?.current?.showers > 0) {
      return t('weather.showers');
    }

    if (data?.current?.snowfall > 0) {
      return t('weather.snowy');
    }

    return t('weather.sunny');
  }, [data?.current, data?.current?.cloud_cover, data?.current?.rain, data?.current?.showers, data?.current?.snowfall]);

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <View style={styles.dateTimeContainer}>
          <AppText style={styles.timeText}>{todayDateTime.time}</AppText>
          <View style={styles.dateContainer}>
            <AppText style={styles.dateText}>{todayDateTime.shortDate}</AppText>
            <AppText style={styles.dateText}>{todayDateTime.ephemerisOfTheDay}</AppText>
          </View>
        </View>
      </View>
      <View style={styles.columnContainer0}>
        <View style={[styles.columnContainer, styles.flexEnd]}>
          <WeatherIcon
            rain={(data?.current.rain || 0) > 0}
            showers={(data?.current.showers || 0) > 0}
            snowfall={(data?.current.snowfall || 0) > 0}
            cloudCover={data?.current.cloud_cover || 0}
            isDay={data?.current.is_day === 1}
            color={WHITE}
            style={styles.icon}
            hq
          />
          <View>
            <Text style={styles.currentTemperatureText}>{Math.round(data?.current.temperature_2m || 0)}°</Text>
            <Text style={styles.currentTemperatureSubText}>{currentWeatherDescription}</Text>
          </View>
        </View>
        <Separator vertical style={styles.verticalSeparator} />
        <View style={styles.column}>
          <View style={styles.columnContainer}>
            <View style={[styles.currentWeatherInfoContainer, styles.center]}>
              <Text style={styles.currentWeatherInfoText}>
                {(data?.daily?.temperature_2m_max?.length || 0) > 0
                  ? Math.round(data?.daily?.temperature_2m_max[0] || 0)
                  : ' '}
                °
              </Text>
              <Text style={styles.currentWeatherInfoSubText}>{t('weather.maximum')}</Text>
            </View>
            <View style={[styles.currentWeatherInfoContainer, styles.center]}>
              <Text style={styles.currentWeatherInfoText}>
                {(data?.daily?.wind_speed_10m_max?.length || 0) > 0 ? data?.daily?.wind_speed_10m_max[0] : ' '}
                <Text style={styles.currentWeatherInfoSubText}>km/h</Text>
              </Text>
              <Text style={styles.currentWeatherInfoSubText}>{t('weather.wind')}</Text>
            </View>
            <View style={[styles.currentWeatherInfoContainer, styles.center]}>
              <Text style={styles.currentWeatherInfoText}>
                {(data?.daily?.sunrise?.length || 0) > 0 ? data?.daily?.sunrise[0].substring(11, 16) : ' '}
              </Text>
              <Text style={styles.currentWeatherInfoSubText}>{t('weather.sunrise')}</Text>
            </View>
          </View>
          <View style={styles.columnContainer}>
            <View style={[styles.currentWeatherInfoContainer, styles.center]}>
              <Text style={styles.currentWeatherInfoText}>
                {(data?.daily?.temperature_2m_min?.length || 0) > 0
                  ? Math.round(data?.daily?.temperature_2m_min[0] || 0)
                  : ' '}
                °
              </Text>
              <Text style={styles.currentWeatherInfoSubText}>{t('weather.minimum')}</Text>
            </View>
            <View style={[styles.currentWeatherInfoContainer, styles.center]}>
              <Text style={styles.currentWeatherInfoText}>
                {(data?.daily?.precipitation_sum?.length || 0) > 0 ? data?.daily?.precipitation_sum[0] : ' '}
                <Text style={styles.currentWeatherInfoSubText}>mm</Text>
              </Text>
              <Text style={styles.currentWeatherInfoSubText}>{t('weather.humidity')}</Text>
            </View>
            <View style={[styles.currentWeatherInfoContainer, styles.center]}>
              <Text style={styles.currentWeatherInfoText}>
                {(data?.daily?.sunset?.length || 0) > 0 ? data?.daily?.sunset[0].substring(11, 16) : ' '}
              </Text>
              <Text style={styles.currentWeatherInfoSubText}>{t('weather.sunset')}</Text>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.bottom}>
        <View>
          <Text style={styles.currentWeatherInfoText}>{t('weather.todays_weather')}</Text>
          <View style={styles.weatherCardContainer}>
            <WeatherVerticalCard
              hour={3}
              temperature={Math.round(data?.hourly?.temperature_2m?.[3] || 0)}
              rain={(data?.hourly?.rain?.[3] || 0) > 0}
              showers={(data?.hourly?.showers?.[3] || 0) > 0}
              snowfall={(data?.hourly?.snowfall?.[3] || 0) > 0}
              cloudCover={data?.hourly?.cloud_cover?.[3] || 0}
              isDay={data?.hourly?.is_day?.[3] === 1}
            />
            <WeatherVerticalCard
              hour={6}
              temperature={Math.round(data?.hourly?.temperature_2m?.[6] || 0)}
              rain={(data?.hourly?.rain?.[6] || 0) > 0}
              showers={(data?.hourly?.showers?.[6] || 0) > 0}
              snowfall={(data?.hourly?.snowfall?.[6] || 0) > 0}
              cloudCover={data?.hourly?.cloud_cover?.[6] || 0}
              isDay={data?.hourly?.is_day?.[6] === 1}
            />
            <WeatherVerticalCard
              hour={9}
              temperature={Math.round(data?.hourly?.temperature_2m?.[9] || 0)}
              rain={(data?.hourly?.rain?.[9] || 0) > 0}
              showers={(data?.hourly?.showers?.[9] || 0) > 0}
              snowfall={(data?.hourly?.snowfall?.[9] || 0) > 0}
              cloudCover={data?.hourly?.cloud_cover?.[9] || 0}
              isDay={data?.hourly?.is_day?.[9] === 1}
            />
            <WeatherVerticalCard
              hour={12}
              temperature={Math.round(data?.hourly?.temperature_2m?.[12] || 0)}
              rain={(data?.hourly?.rain?.[12] || 0) > 0}
              showers={(data?.hourly?.showers?.[12] || 0) > 0}
              snowfall={(data?.hourly?.snowfall?.[12] || 0) > 0}
              cloudCover={data?.hourly?.cloud_cover?.[12] || 0}
              isDay={data?.hourly?.is_day?.[12] === 1}
            />
            <WeatherVerticalCard
              hour={15}
              temperature={Math.round(data?.hourly?.temperature_2m?.[15] || 0)}
              rain={(data?.hourly?.rain?.[15] || 0) > 0}
              showers={(data?.hourly?.showers?.[15] || 0) > 0}
              snowfall={(data?.hourly?.snowfall?.[15] || 0) > 0}
              cloudCover={data?.hourly?.cloud_cover?.[15] || 0}
              isDay={data?.hourly?.is_day?.[15] === 1}
            />
            <WeatherVerticalCard
              hour={18}
              temperature={Math.round(data?.hourly?.temperature_2m?.[18] || 0)}
              rain={(data?.hourly?.rain?.[18] || 0) > 0}
              showers={(data?.hourly?.showers?.[18] || 0) > 0}
              snowfall={(data?.hourly?.snowfall?.[18] || 0) > 0}
              cloudCover={data?.hourly?.cloud_cover?.[18] || 0}
              isDay={data?.hourly?.is_day?.[18] === 1}
            />
            <WeatherVerticalCard
              hour={21}
              temperature={Math.round(data?.hourly?.temperature_2m?.[21] || 0)}
              rain={(data?.hourly?.rain?.[21] || 0) > 0}
              showers={(data?.hourly?.showers?.[21] || 0) > 0}
              snowfall={(data?.hourly?.snowfall?.[21] || 0) > 0}
              cloudCover={data?.hourly?.cloud_cover?.[21] || 0}
              isDay={data?.hourly?.is_day?.[21] === 1}
            />
          </View>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.7)',
  },
  header: {
    height: vh(8),
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: vh(1),
    flexDirection: 'row',
    marginTop: vh(1),
    marginBottom: vh(8),
  },
  dateTimeContainer: {
    flexDirection: 'row',
    paddingTop: vh(0.25),
  },
  timeText: {
    fontSize: vh(6),
    color: WHITE,
  },
  dateText: {
    fontSize: vh(2.5),
    color: WHITE,
  },
  dateContainer: {
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    marginBottom: vh(2),
    marginLeft: vh(2),
  },
  columnContainer0: {
    flexDirection: 'row',
  },
  columnContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  column: {
    flex: 1,
  },
  flexEnd: {
    justifyContent: 'flex-end',
  },
  center: {
    justifyContent: 'center',
  },
  verticalSeparator: {
    marginHorizontal: vh(4),
  },
  currentTemperatureText: {
    color: WHITE,
    fontSize: vh(16),
  },
  currentTemperatureSubText: {
    color: WHITE,
    fontSize: vh(4),
  },
  currentWeatherInfoContainer: {
    marginRight: vh(2),
    width: vh(18),
  },
  currentWeatherInfoText: {
    color: WHITE,
    fontSize: vh(4),
  },
  currentWeatherInfoSubText: {
    color: WHITE,
    fontSize: vh(2),
  },
  icon: {
    width: vh(24),
    height: vh(24),
    marginTop: 10,
  },
  bottom: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  weatherCardContainer: {
    flexDirection: 'row',
  },
});
