import { NavigationProp, useNavigation } from '@react-navigation/native';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { alertInfo } from '../../../../services/utils';
import { setContributionsListNeedsRefresh } from '../../../../store/action';
import { t } from '../../../../services/translations';
import Form from '../../../../components/atomic/formik/Form';
import SurveyFormFields, { schema } from './SurveyFormFields';
import useLoggedUser from '../../../../hooks/useLoggedUser';
import { sendSurvey } from '../../../../services/api/survey.contributions.api';
import { CreateSurvey } from '../../../../entities/CreateSurvey';
import { CSondageQuestionType } from '../../../../entities/CSondageQuestion';

export default function CreateSurveyScreen() {
  const user = useLoggedUser();
  const navigation = useNavigation<NavigationProp<any>>();
  const dispatch = useDispatch();

  const { mutateAsync, isError } = useMutation({
    mutationFn: async (values: CreateSurvey) => {
      await sendSurvey(user, values);
      dispatch(setContributionsListNeedsRefresh(true));
      navigation.goBack();
    },
  });

  // Display error message if needed
  useEffect(() => {
    if (isError) {
      alertInfo(t('error_occurred'));
    }
  }, [isError]);

  return (
    <Form
      initialValues={{
        questions: [
          {
            text: '',
            answers: ['', '', ''],
            type: CSondageQuestionType.SINGLE,
          },
        ],
        isAnonym: false,
        commentsActivated: true,
        notifActivated: true,
        emailActivated: false,
      }}
      onSubmit={mutateAsync as any}
      validationSchema={schema}
      validateOnMount
    >
      <SurveyFormFields />
    </Form>
  );
}
