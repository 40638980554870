import React from 'react';
import { RenderHTMLProps } from 'react-native-render-html';
import { FONT_FAMILY_DEFAULT } from '../../styles/globalStyle';

export const Html = (props: RenderHTMLProps) => {
  return (
    <div
      style={{
        fontSize: 14,
        color: '#272727',
        fontFamily: FONT_FAMILY_DEFAULT.regular,
      }}
    >
      {props.source?.html && <p dangerouslySetInnerHTML={{ __html: props.source.html }} />}
    </div>
  );
};
