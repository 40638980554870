import YouTube from 'react-youtube';
import { getYoutubeIdFromURL } from '../../models/Media';

export type TVScreenYoutubeVideoProps = {
  youtubeUrl: string;
  onVideoEnd?: () => void;
};

export default function TVScreenYoutubeVideo({ youtubeUrl, onVideoEnd }: TVScreenYoutubeVideoProps) {
  return (
    <YouTube
      style={{ height: '100%', width: '100%' }}
      videoId={getYoutubeIdFromURL(youtubeUrl)}
      opts={{
        height: '100%',
        width: '100%',
        playerVars: {
          autoplay: 1,
          mute: 1,
          controls: 0,
          showinfo: 0,
          rel: 0,
          modestbranding: 1,
          cc_load_policy: 1,
        },
      }}
      onEnd={onVideoEnd}
    />
  );
}
