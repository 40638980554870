import React from 'react';
import { View } from 'react-native';
import AppText from '../AppText';
import { t } from '../../../services/translations';
import SwitchField from './SwitchField';
import useLoggedUser from '../../../hooks/useLoggedUser';

export type ContributionAuthorizationsFieldProps = {
  subAdminCanShareWithEveryone?: boolean;
};

export default function ContributionAuthorizationsField({}: ContributionAuthorizationsFieldProps) {
  const user = useLoggedUser();
  const isAdmin = user.lovIdType === 13;

  return (
    <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
      <View style={{ flexDirection: 'row', marginTop: 40, marginRight: 40 }}>
        <SwitchField name="commentsActivated" style={{ marginRight: 28 }} />
        <AppText>{t('authorize_comments')}</AppText>
      </View>
      <View style={{ flexDirection: 'row', marginTop: 40, marginRight: 40 }}>
        <SwitchField name="notifActivated" style={{ marginRight: 28 }} />
        <AppText>{t('generate_notif')}</AppText>
      </View>
      {isAdmin && (
        <View style={{ flexDirection: 'row', marginTop: 40 }}>
          <SwitchField name="emailActivated" style={{ marginRight: 28 }} />
          <AppText>{t('generate_email')}</AppText>
        </View>
      )}
    </View>
  );
}
