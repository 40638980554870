import { DrawerScreenProps } from '@react-navigation/drawer';
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { AppState, FlatList, TouchableOpacity, View, Image } from 'react-native';
import { AppButton, Type } from '../../../../components/atomic/AppButton';
import AppText, { Mode } from '../../../../components/atomic/AppText';
import { CategoryChip } from '../../../../components/atomic/CategoryChip';
import { FeaturedLabel } from '../../../../components/atomic/FeaturedLabel';
import { HtmlText } from '../../../../components/atomic/HtmlText';
import { ProfileCard } from '../../../../components/atomic/ProfileCard';
import SpreadingChipList from '../../../../components/atomic/SpreadingChipsList';
import { UserProfileOverlay } from '../../../../components/atomic/UserProfileOverlay';
import { UserSubRow } from '../../../../components/atomic/UserSubRow';
import { DefaultBackground } from '../../../../components/containers/AppScreen';
import { CommentList } from '../../../../components/molecules/CommentList';
import { Interest } from '../../../../entities/Interest';
import useContribution from '../../../../hooks/useContribution';
import { getPictureURI } from '../../../../services/api/helper.api';
import { setCompanyNewsView } from '../../../../services/api/interest.contributions.api';
import { getCompanyNewsDetail, getNewsViewCount } from '../../../../services/api/news.contributions.api';
import { newsImage } from '../../../../services/assets';
import { language, t } from '../../../../services/translations';
import { getReadableDate, isMobile } from '../../../../services/utils';
import { ContributionType } from '../../../../types';
import { MediaToPictureSource } from '../../../../utils/Medias';
import { BigDetailRow, ViewDetailRow, BigDetailRowInline } from '../ContributionItemDetailScreen';
import { ContentWithImageHeaderScreen } from '../../../../components/containers/ContentWithImageHeaderScreen';
import useLoggedUser from '../../../../hooks/useLoggedUser';

type ScreenProps = DrawerScreenProps<any>;

const CompanyNewsDetailScreen = (props: ScreenProps) => {
  const user = useLoggedUser();
  const [viewCount, setViewCount] = useState(0);
  const [maxImageSize, setMaxImageSize] = useState(540);
  const { reload, data, loading, visible, setVisible, interest, zoom } = useContribution(
    ContributionType.COMPANY_NEWS,
    props.route.params as any,
    () => getCompanyNewsDetail(user, props.route.params?.id),
    (d) => d.mActu.mActuInterest
  );

  const appStateCurrent = useRef(AppState.currentState);
  const [appStateVisible, setAppStateVisible] = useState(appStateCurrent.current);

  // when coming back to app in mobile
  useEffect(() => {
    const subscription = AppState.addEventListener('change', (nextAppState) => {
      if (appStateCurrent.current.match(/inactive|background/) && nextAppState === 'active') {
        reload();
      }

      appStateCurrent.current = nextAppState;
      setAppStateVisible(appStateCurrent.current);
    });

    return () => {
      subscription.remove();
    };
  }, []);

  useEffect(() => {
    if (!data) return;
    setCompanyNewsView(user, data.mActuId).then(() => getNewsViewCount(user, data.mActuId).then(setViewCount));
  }, [data]);

  const { titre, texte } = useMemo(() => {
    if (!data) return { titre: '', texte: '' };
    for (const translation of data.translations) {
      if (translation.languageCode == language()) {
        return translation;
      }
    }
    return data;
  }, [data]);

  const image = useMemo(() => {
    if (!!data?.imageId) {
      let res = getPictureURI(data.imageId);
      Image.getSize(res.uri, (w, h) => {
        let maxSize = h > 540 && w > 540 ? 540 : h < w ? h : w;
        setMaxImageSize(maxSize);
      });

      return res;
    }
    if (!!data?.videoUrl) return MediaToPictureSource(data.videoUrl);
    return newsImage;
  }, [data]);

  if (loading) return <DefaultBackground></DefaultBackground>;

  return (
    <DefaultBackground>
      <ContentWithImageHeaderScreen
        contribution={data}
        contributionType={ContributionType.COMPANY_NEWS}
        underImageContent={
          <View
            style={{
              flexDirection: 'row',
              paddingTop: 10,
              paddingLeft: 10,
              paddingRight: 10,
              width: isMobile() ? undefined : 540,
            }}
          >
            <UserSubRow user={data?.userIdCreateurNavigation} withPicture={true} style={{ flex: 1 }} />
            <AppText style={{ width: isMobile() ? undefined : 200, textAlign: 'right' }}>
              {getReadableDate(data?.visibleStartDate ? data?.visibleStartDate : data?.dateCreation, true)}
            </AppText>
          </View>
        }
        navigation={props.navigation}
      >
        <View style={{ padding: 18 }}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <AppText
              mode={Mode.BOLD}
              style={{
                flex: 1,
                fontSize: 20,
                textAlign: 'center',
              }}
            >
              {titre}
            </AppText>
            {data.featured && <FeaturedLabel />}
          </View>
          {!!data.mActu.category && (
            <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
              <CategoryChip style={{ marginLeft: 8, marginVertical: 16, height: 24 }} category={data.mActu.category} />
            </View>
          )}
        </View>
        <BigDetailRow borderBottomWidth={0} content={<HtmlText text={texte} />} />
        <ViewDetailRow first title={t('view_count')} content={viewCount} />
        <TouchableOpacity onPress={() => interest.set((p) => !p)}>
          <ViewDetailRow content={interest.interested.length} title={t('interested_people')} />
        </TouchableOpacity>
        {interest.show && (
          <FlatList<Interest>
            horizontal={false}
            numColumns={isMobile() ? 2 : undefined}
            contentContainerStyle={
              isMobile()
                ? undefined
                : {
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                  }
            }
            data={interest.interested}
            renderItem={(item) => <ProfileCard user={item.item.user} />}
            keyExtractor={(item) => item.user.id.toString()}
          />
        )}
        <BigDetailRowInline
          content={
            <>
              {/*<AppText>hello</AppText>*/}
              <SpreadingChipList item={data.mActu} monocolor={true} inline={true} />
            </>
          }
          title={t('who_can_see')}
        />
        {!!data?.userIdCreateurNavigation && data?.userIdCreateurNavigation?.aspNetUsers?.deletedAt === null && (
          <AppButton
            title={t('contact_original_poster')}
            style={{ margin: 29, marginBottom: 19 }}
            textStyle={{ fontSize: 16 }}
            type={Type.PRIMARY}
            onPress={() => setVisible(true)}
          />
        )}
        <AppButton
          title={t('i_am_interested')}
          icon="heart"
          style={{ margin: 10 }}
          textStyle={{ fontSize: 16 }}
          type={interest.isInterested ? Type.PRIMARY : Type.LIGHT}
          onPress={interest.toggle}
        />
        <UserProfileOverlay
          onBackdropPress={() => setVisible(false)}
          userProfileData={data?.userIdCreateurNavigation}
          isVisible={visible}
        />
        {!!data?.mActu?.commentsActivated && <CommentList newsId={data.mActu.id} />}
      </ContentWithImageHeaderScreen>
    </DefaultBackground>
  );
};

export default CompanyNewsDetailScreen;
