import { useEffect, useState } from 'react';
import { View } from 'react-native';
import * as Yup from 'yup';
import AppText from '../atomic/AppText';
import { t } from '../../services/translations';
import AvatarField from '../atomic/formik/AvatarField';
import { Header2 } from '../containers/Header2';
import { ResponsiveRow } from '../containers/ResponsiveRow';
import { ResponsiveColumn } from '../containers/ResponsiveColumn';
import TextField from '../atomic/formik/TextField';
import RadioGroupField from '../atomic/formik/RadioGroupField';
import { Space2 } from '../../styles/spaces';
import SwitchField from '../atomic/formik/SwitchField';
import SubmitButton from '../atomic/formik/SubmitButton';
import { LanguagePickerField } from '../atomic/formik/LanguagePickerField';
import { SelectField } from '../atomic/formik/SelectField';
import {
  CompanyGeoSiteReduced,
  CompanyGroupReduced,
  CompanyServiceReduced,
  getCompanyGeoSites,
  getCompanyServices,
  getLoggedUserCompanyGroups,
} from '../../services/api/company.api';
import { emailRegex } from '../../services/formUtil';
import { useFormikContext } from 'formik';
import useLoggedUser from '../../hooks/useLoggedUser';

export const schema = Yup.object({
  email: Yup.string().min(1).required(t('field_required')).matches(emailRegex, t('invalid_email')),
  firstName: Yup.string().min(1).required(t('field_required')),
  lastName: Yup.string().min(1).required(t('field_required')),
  service: Yup.number().required(t('field_required')),
  geoSite: Yup.number().required(t('field_required')),
  languageCode: Yup.string().required(t('field_required')),
});

export type UserFormFieldsProps = {
  title?: string;
  displayEmailWarning?: boolean;
  allowAvatarEdit?: boolean;
  allowEmailEdit?: boolean;
  displayHideEmail?: boolean;
  displayGender?: boolean;
};

export default function UserFormFields({
  title,
  displayEmailWarning,
  allowAvatarEdit,
  allowEmailEdit,
  displayHideEmail,
  displayGender,
}: UserFormFieldsProps) {
  const user = useLoggedUser();
  const userToken = user?.token;
  const companyId = user?.entrepriseId;
  const initialValues = useFormikContext<any>().initialValues;

  const [companyServices, setCompanyServices] = useState<CompanyServiceReduced[]>([]);
  const [companyGeoSites, setCompanyGeoSites] = useState<CompanyGeoSiteReduced[]>([]);
  const [companyGroups, setCompanyGroups] = useState<CompanyGroupReduced[]>([]);

  useEffect(() => {
    const asyncFunc = async () => {
      const companyServicePromise = getCompanyServices(companyId, userToken!).then(setCompanyServices);
      const companyGeoSitesPromise = getCompanyGeoSites(companyId, userToken!).then(setCompanyGeoSites);
      const companyGroupsPromise = getLoggedUserCompanyGroups(userToken!).then(setCompanyGroups);

      await Promise.all([companyServicePromise, companyGeoSitesPromise, companyGroupsPromise]);
    };

    asyncFunc();
  }, [userToken]);

  return (
    <>
      {title && <AppText style={{ marginVertical: 22, fontSize: 20, textAlign: 'center' }}>{title}</AppText>}
      {allowAvatarEdit && <AvatarField name="photo" />}
      <Header2>{t('personal_information')}</Header2>
      <ResponsiveRow>
        <ResponsiveColumn first={true}>
          <TextField
            name="email"
            required
            mandatory={allowEmailEdit}
            displayOnly={!allowEmailEdit && !!initialValues.email && initialValues.email !== ''}
            title={t('email')}
          />
        </ResponsiveColumn>
      </ResponsiveRow>
      {displayHideEmail && (
        <ResponsiveRow>
          <ResponsiveColumn>
            <View
              style={{
                flexDirection: 'row',
                marginTop: 20,
                marginBottom: 20,
                alignItems: 'center',
              }}
            >
              <SwitchField name="hideEmail" style={{ transform: [{ scaleX: 0.8 }, { scaleY: 0.8 }] }} />
              <AppText
                style={{
                  marginLeft: 10,
                  fontSize: 12,
                }}
              >
                {t('hide_email')}
              </AppText>
            </View>
          </ResponsiveColumn>
        </ResponsiveRow>
      )}
      <ResponsiveRow>
        <ResponsiveColumn first={true}>
          <TextField
            name="firstName"
            required
            mandatory={allowEmailEdit}
            displayOnly={!allowEmailEdit && !!initialValues.firstName && initialValues.firstName !== ''}
            title={t('first_name')}
          />
        </ResponsiveColumn>
        <ResponsiveColumn>
          <TextField
            name="lastName"
            required
            mandatory={allowEmailEdit}
            displayOnly={!allowEmailEdit && !!initialValues.lastName && initialValues.lastName !== ''}
            title={t('last_name')}
          />
        </ResponsiveColumn>
      </ResponsiveRow>
      <ResponsiveRow>
        <ResponsiveColumn first={true}>
          <TextField name="landlineNumber" title={t('landline_number')} />
        </ResponsiveColumn>
        <ResponsiveColumn>
          <TextField name="mobileNumber" title={t('mobile_number')} />
        </ResponsiveColumn>
      </ResponsiveRow>
      <View
        style={{
          flexDirection: 'row',
          marginTop: 20,
          marginBottom: 20,
          alignItems: 'center',
        }}
      >
        <SwitchField name="hidePhone" style={{ transform: [{ scaleX: 0.8 }, { scaleY: 0.8 }] }} />
        <AppText
          style={{
            marginLeft: 10,
            fontSize: 12,
          }}
        >
          {t('hide_phone')}
        </AppText>
      </View>
      {displayGender && (
        <ResponsiveRow>
          <ResponsiveColumn first={true}>
            <RadioGroupField
              name="gender"
              title={t('genre')}
              options={[
                { label: t('woman'), value: 'F' },
                { label: t('man'), value: 'H' },
              ]}
              vertical
            />
          </ResponsiveColumn>
        </ResponsiveRow>
      )}
      <Space2 />
      <Header2>{t('professional_information')}</Header2>

      <ResponsiveRow>
        <ResponsiveColumn first={true}>
          <TextField
            name="jobTitle"
            title={t('job_title')}
            displayOnly={!allowEmailEdit && !!initialValues.lastName && initialValues.lastName !== ''}
          />
        </ResponsiveColumn>
        <ResponsiveColumn></ResponsiveColumn>
      </ResponsiveRow>

      <ResponsiveRow>
        <ResponsiveColumn first={true}>
          <SelectField
            name="service"
            required
            title={t('service_faculty')}
            style={{ flex: 1 }}
            data={companyServices.map((v) => {
              return { label: v.libelle, value: v.id };
            })}
            displayOnly={!allowEmailEdit && !!initialValues.service}
          />
        </ResponsiveColumn>
        <ResponsiveColumn>
          <SelectField
            name="geoSite"
            required
            title={t('geo_site')}
            data={companyGeoSites.map((s) => {
              return { label: s.geoSite, value: s.id };
            })}
            displayOnly={!allowEmailEdit && !!initialValues.geoSite}
          />
        </ResponsiveColumn>
        <ResponsiveColumn>
          <SelectField
            name="group"
            title={t('group')}
            data={companyGroups.map((s) => {
              return { label: s.label, value: s.id };
            })}
            displayOnly
          />
        </ResponsiveColumn>
      </ResponsiveRow>

      <ResponsiveRow>
        <ResponsiveColumn first={true}>
          <LanguagePickerField name="languageCode" title={t('language')} required />
        </ResponsiveColumn>
      </ResponsiveRow>
      {displayEmailWarning && (
        <ResponsiveRow>
          <ResponsiveColumn>
            <AppText>{t('take_care_email_exists')}</AppText>
          </ResponsiveColumn>
        </ResponsiveRow>
      )}
      <SubmitButton text={t('save')} />
    </>
  );
}
