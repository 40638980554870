import { match } from 'ts-pattern';
import { Dispatch } from 'redux';
import * as Notifications from 'expo-notifications';
import { navigateToMessaging, navigateToMessagingThread } from '../utils/Messaging';
import { messagingMessageReceived, messagingThreadSeenReceived } from '../store/messaging';
import { navigateToNotification } from '../utils/Notification';
import { isMobile } from '../services/utils';
import { markAsReadNotification } from '../services/api/notification.api';
import { ContributionType } from '../types';
import { UserSaved } from '../services/storage';

export type NotificationType =
  | {
      type: 'redirection';
      notificationId: string;
      contributionId: string;
      contributionType: string;
      isAdminView: string;
    }
  | {
      type: 'new_message';
      id: string;
      threadId: string;
      content: string;
      fileId: string;
      dateCreated: string;
      userId: string;
      profileFileId: string;
      firstName: string;
      lastName: string;
    }
  | {
      type: 'thread_seen';
      threadId: string;
      userId: string;
      date: string;
    };

export async function initialNotificationHandler(messageData: NotificationType | undefined, user: UserSaved, dispatch: Dispatch) {
  console.log(messageData);
  match(messageData)
    .with({ type: 'redirection' }, ({ notificationId, contributionId, contributionType, isAdminView }) => {
      markAsReadNotification(parseInt(notificationId)).finally(async () => {
        try {
          if (isMobile()) await Notifications.setBadgeCountAsync((await Notifications.getBadgeCountAsync()) - 1);
        } catch (error) {
          console.error('Failed to decrease badge count');
        }

        navigateToNotification(
          parseInt(contributionType) as ContributionType,
          parseInt(contributionId),
          isAdminView === 'true',
          user,
          dispatch
        );
      });
    })
    .with({ type: 'new_message' }, (data) => {
      navigateToMessaging();
      navigateToMessagingThread({
        id: parseInt(data.threadId),
        contactFirstName: data.firstName,
        contactLastName: data.lastName,
      });
    })
    .with({ type: 'thread_seen' }, () => {})
    .with(undefined, () => {})
    .exhaustive();
}

export async function notificationHandler(messageData: NotificationType | undefined, dispatch: Dispatch) {
  match(messageData)
    .with({ type: 'redirection' }, () => {})
    .with({ type: 'new_message' }, (data) => {
      messagingMessageReceived(dispatch, {
        ...data,
        id: parseInt(data.id),
        threadId: parseInt(data.threadId),
        fileId: parseInt(data.fileId),
        userId: parseInt(data.userId),
        profileFileId: parseInt(data.profileFileId),
      });
    })
    .with({ type: 'thread_seen' }, (data) => {
      messagingThreadSeenReceived(dispatch, parseInt(data.threadId), parseInt(data.userId), new Date(data.date));
    })
    .with(undefined, () => {})
    .exhaustive();
}
