import { Category } from '../entities/Category';
import { t } from '../services/translations';
import { ContributionType } from '../types';

export const CategoriesContributions = [
  ContributionType.COMPANY_NEWS,
  ContributionType.SELL,
  ContributionType.ACTIVITY,
  ContributionType.MUTUAL_AID,
];

export const GlobalCategory: Category = {
  id: -1,
  color: '#45AFE8',
  name: t('global_category'),
  order: -1,
  type: 'all'
}

export const hasCategories = (type: ContributionType) => CategoriesContributions.findIndex((c) => c === type) >= 0;
