import React, { useEffect, useState } from 'react';
import { PRIMARY_COLOR, PRIMARY_COLOR_LIGHT } from '../../styles/appColor';
import { FlatList, RefreshControl, TouchableOpacity } from 'react-native';
import { useDispatch } from 'react-redux';
import AppText, { Mode } from './AppText';
import { globalStyle } from '../../styles/globalStyle';
import { getReadableDate, isMobile, routeFromNotification } from '../../services/utils';
import {
  getNotifications,
  getNotificationsCount,
  markAsReadNotification,
  readUserNotifications,
} from '../../services/api/notification.api';
import { AppModalOverlay } from './AppModalOverlay';
import { t } from '../../services/translations';
import * as Notifications from 'expo-notifications';
import { Notification } from '../../entities/Notification';
import { navigateToNotification } from '../../utils/Notification';
import { Badge } from './Badge';
import { Chip } from './Chip';
import useLoggedUser from '../../hooks/useLoggedUser';
import FontAwesome6 from 'react-native-vector-icons/FontAwesome6';

function NotificationsList(props: { onExit: () => void }) {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [refreshing, setRefreshing] = useState(false);
  const user = useLoggedUser();

  const dispatch = useDispatch();

  useEffect(() => {
    _onRefresh();
  }, []);

  function _onRefresh() {
    setRefreshing(true);
    getNotifications(user)
      .then((json) => setNotifications(json.reverse()))
      .finally(() => setRefreshing(false));
  }

  function _handleClick(notification: Notification) {
    Notifications.getBadgeCountAsync().then((count) => {
      markAsReadNotification(notification.id).finally(async () => {
        try {
          if (isMobile()) await Notifications.setBadgeCountAsync(count - 1);
        } catch (error) {
          console.error('Failed to decrease badge count');
        }
        props.onExit();
        if (!!notification.post && !!notification.contributionType) {
          navigateToNotification(
            notification.contributionType,
            notification.post,
            notification.isAdminView,
            user,
            dispatch
          );
        } else {
          routeFromNotification(
            dispatch,
            notification.title,
            notification.content,
            notification.contributionId,
            notification.contributionType
          );
        }
      });
    });
  }

  function handleReadAll() {
    Notifications.setBadgeCountAsync(0);
    readUserNotifications(user.id).finally(() => props.onExit());
  }

  return (
    <>
      <Chip
        text={t('read_all_notifications')}
        color={PRIMARY_COLOR}
        style={{ height: 28, marginBottom: 8 }}
        onPress={handleReadAll}
        inverted
      />
      <FlatList
        data={notifications}
        refreshControl={<RefreshControl refreshing={refreshing} onRefresh={_onRefresh} />}
        keyExtractor={(n) => n.id.toString()}
        renderItem={(n) => (
          <TouchableOpacity
            style={[
              globalStyle.listRow,
              { paddingHorizontal: 5 },
              !n.item.seen ? { backgroundColor: PRIMARY_COLOR_LIGHT } : {},
            ]}
            onPress={() => _handleClick(n.item)}
          >
            <AppText mode={Mode.BOLD}>{n.item.title}</AppText>
            <AppText>{n.item.content}</AppText>
            <AppText mode={Mode.SMALL}>{getReadableDate(n.item.dateCreated)}</AppText>
          </TouchableOpacity>
        )}
      />
    </>
  );
}

export function NotificationButton() {
  const [modalVisible, setModalVisible] = useState(false);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const user = useLoggedUser();

  useEffect(() => {
    _refresh();
  }, []);

  function _refresh() {
    getNotificationsCount(user)
      .then(setNotificationsCount)
      .catch(() => {});
  }

  return (
    <TouchableOpacity
      style={{ paddingHorizontal: 12, flexDirection: 'row' }}
      onPress={() => setModalVisible(!modalVisible)}
    >
      <Badge value={notificationsCount} />
      <FontAwesome6 size={24} name="bell" color={PRIMARY_COLOR} />
      {modalVisible ? (
        <AppModalOverlay
          overlayStyle={{ height: 500, width: 300 }}
          onBackdropPress={() => setModalVisible(false)}
          isVisible={modalVisible}
        >
          <NotificationsList
            onExit={() => {
              _refresh();
              setModalVisible(false);
            }}
          />
        </AppModalOverlay>
      ) : null}
    </TouchableOpacity>
  );
}
