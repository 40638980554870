import React, { useRef } from 'react';
import { StyleSheet, KeyboardAvoidingView, Platform, AppState, View } from 'react-native';
import { RouteProp, useNavigation } from '@react-navigation/native';
import useMessagingThreadMessages from '../../hooks/useMessagingThreadMessages';
import { MessageList } from '../../components/molecules/MessageList';
import { NewMessageForm } from '../../components/molecules/NewMessageForm';

export type MessagingThreadScreenProps = {
  route: RouteProp<any, any>;
};

export function MessagingThreadScreen({ route }: MessagingThreadScreenProps) {
  const paramThreadId: number | null | undefined = route?.params?.threadId;
  const contactId: number | null | undefined = route?.params?.contactId;

  const { isLoading, messages, members, fetchNext, refresh, sendNewMessage } = useMessagingThreadMessages(
    paramThreadId,
    contactId
  );

  // Refresh when this screen is displayed by a notification on iOS
  React.useEffect(() => {
    if (Platform.OS === 'ios') {
      const subscription = AppState.addEventListener('change', (nextAppState) => {
        if (nextAppState === 'active') {
          refresh();
        }
      });

      return () => {
        subscription.remove();
      };
    }
  }, [refresh]);

  return (
    <View style={{ backgroundColor: '#fff', flex: 1 }}>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        keyboardVerticalOffset={80}
        style={styles.container}
      >
        <MessageList
          messages={messages}
          members={members}
          isLoading={isLoading}
          refresh={refresh}
          fetchNext={fetchNext}
        />
        <NewMessageForm sendNewMessage={sendNewMessage} />
      </KeyboardAvoidingView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 16,
    backgroundColor: '#ffffff',
  },
});
