import React, { createElement, useState } from 'react';
import { globalStyle } from '../../styles/globalStyle';

function digits2(digit: number) {
  return (digit < 10 ? '0' : '') + digit;
}

export function AppDateTimePicker(props: {
  withTime?: boolean;
  formattedDate: Date;
  onChangeText(date: string): void;
}) {
  const day = props.formattedDate.getDate();
  const month = props.formattedDate.getMonth() + 1;
  const year = props.formattedDate.getFullYear();

  const value = year + '-' + digits2(month) + '-' + digits2(day);

  const dateInput = createElement('input', {
    type: 'date',
    value: value,
    style: globalStyle.textInput,
    onChange: function (syntheticEvent) {
      const dateText = syntheticEvent.target.value.split('-');

      let newDate = new Date(props.formattedDate);
      newDate.setDate(dateText[2] as unknown as number);
      newDate.setMonth((dateText[1] as unknown as number) - 1);
      newDate.setFullYear(dateText[0] as unknown as number);

      props.onChangeText(newDate.toISOString());
    },
  });

  const timeInput = createElement('input', {
    type: 'time',
    value: digits2(props.formattedDate.getHours()) + ':' + digits2(props.formattedDate.getMinutes()),
    style: globalStyle.textInput,
    onChange: function (syntheticEvent) {
      const timeText = syntheticEvent.target.value.split(':');
      let newDate = new Date(props.formattedDate);

      newDate.setHours(timeText[0], timeText[1]);
      props.onChangeText(newDate.toISOString());
    },
  });

  return (
    <>
      {dateInput}
      {props.withTime && timeInput}
    </>
  );
}
