import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import * as Yup from 'yup';
import useLoggedUser from '../../hooks/useLoggedUser';
import { setLoading } from '../../store/action';
import { alertInfo } from '../../services/utils';
import { t } from '../../services/translations';
import Form from '../atomic/formik/Form';
import TextField from '../atomic/formik/TextField';
import SubmitButton from '../atomic/formik/SubmitButton';
import { createScreenMedia } from '../../services/api/tv.api';
import PhotoField from '../atomic/formik/PhotoField';

export const schema = Yup.object({
  name: Yup.string().required(t('field_required')),
  file: Yup.mixed().required(t('field_required')),
});

export type CreateScreenPlaylistMediaProps = {
  onSubmitEnd?: () => void;
};

export default function CreateScreenPlaylistMedia({ onSubmitEnd }: CreateScreenPlaylistMediaProps) {
  const user = useLoggedUser();
  const dispatch = useDispatch();

  const mutation = useMutation({
    mutationFn: async (values: any) => {
      dispatch(setLoading(true));

      try {
        await createScreenMedia(user, values);
      } catch (err: any) {
        alertInfo(t('error_occurred'));
      } finally {
        dispatch(setLoading(false));
      }
    },
  });

  const handleSubmit = async (values: any, { resetForm }: { resetForm: () => void }) => {
    await mutation.mutateAsync(values);
    resetForm();
    onSubmitEnd && onSubmitEnd();
  };

  const isLoading = mutation.isLoading;

  const initialValues = {
    name: '',
    file: null,
  };

  // Display big loading indicator if refreshing
  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [isLoading, dispatch]);

  return (
    <Form onSubmit={handleSubmit} initialValues={initialValues} validationSchema={schema} validateOnMount>
      <>
        <TextField name="name" mandatory required title={t('document_title')} />
        <PhotoField name="file" defaultPhoto={undefined} allowPdf />
        <SubmitButton text={t('put')} />
      </>
    </Form>
  );
}
