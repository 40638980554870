import React from 'react';
import { StyleSheet, View, ViewStyle } from 'react-native';
import { SMOKE_WHITE } from '../../styles/appColor';

export type SeparatorProps = {
  vertical?: boolean;
  style?: ViewStyle;
};

export default function Separator({ vertical, style }: SeparatorProps) {
  return <View style={[style, vertical ? styles.separatorVertical : styles.separatorHorizontal]} />;
}

const styles = StyleSheet.create({
  separatorHorizontal: {
    width: '100%',
    height: 1,
    backgroundColor: SMOKE_WHITE,
  },
  separatorVertical: {
    width: 1,
    height: '100%',
    backgroundColor: SMOKE_WHITE,
  },
});
