import React, { useMemo } from 'react';
import { createDrawerNavigator, DrawerNavigationOptions } from '@react-navigation/drawer';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import { CustomDrawerContent } from './CustomDrawerContent';
import { t } from '../services/translations';
import { ContributionRouter } from './ContributionRouter';
import { DirectoryList } from '../screens/loggedIn/DirectoryScreen';
import { ContributionType } from '../types';
import { ContributionItemDetailScreen } from '../screens/loggedIn/contribution/ContributionItemDetailScreen';
import { ContributionFormScreen } from '../screens/loggedIn/contribution/ContributionFormScreen';
import { drawerIcon, isMobile } from '../services/utils';
import { LoggedInHomeScreen } from '../screens/loggedIn/LoggedInHomeScreen';
import { ScreenNames } from '../ScreenNames';
import {
  hasLicenceActivity,
  hasLicenceCarPool,
  hasLicenceDocument,
  hasLicenceEvent,
  hasLicenceTraining,
  hasLicenceLostProperty,
  hasLicenceMutualAid,
  hasLicenceSell,
  hasLicenceOrganizationalChart,
  hasLicenceSuggestion,
  hasLicenceSurvey,
  hasLicenceEmergencySms,
} from '../services/licences';
import { AdminRoute } from './AdminRoute';
import { BackButton } from '../components/atomic/BackButton';
import CompanyNewsFormScreen from '../screens/loggedIn/contribution/forms/CompanyNewsFormScreen';
import CompanyNewsDetailScreen from '../screens/loggedIn/contribution/details/CompanyNewsDetailScreen';
import { HeaderButtons } from '../components/atomic/HeaderButtons';
import { MessagingRoute } from './MessagingRoute';
import OrganizationChartsScreen from '../screens/loggedIn/OrganizationChartsScreen';
import { DocumentsRouter } from './DocumentsRouter';
import { useQuery } from '@tanstack/react-query';
import { listCustomModules } from '../services/api/custom.module.api';
import { CustomModuleRouter } from './CustomModuleRouter';
import DetailsCustomModuleContributionScreen from '../screens/loggedIn/contribution/custom/DetailsCustomModuleContributionScreen';
import { usePushNotifications } from '../hooks/usePushNotifications';
import { getPictureURI } from '../services/api/helper.api';
import FontAwesome6 from 'react-native-vector-icons/FontAwesome6';
import { Image, View } from 'react-native';
import useLoggedUser from '../hooks/useLoggedUser';
import useReviewApp from '../hooks/useReviewApp';
import { useDeviceSize } from '../hooks/useDeviceSize';
import { EmergencySmsRouter } from './EmergencySmsRouter';
import DrawerToggleButton from '../components/atomic/DrawerToggleButton';

const Drawer = createDrawerNavigator();

export default function LoggedInDrawerNavigation() {
  let user = useLoggedUser();
  const userEmail = user.aspNetUsers.email;
  const userEmailDomain = userEmail.split('@')[1];
  const entrepriseDomains = user.entreprise.entrepriseNomDomaine.map((e) => e.nomDomaine);
  const isUserInDomain = entrepriseDomains.includes(userEmailDomain);

  useReviewApp();
  const { isSmallDevice } = useDeviceSize();

  const {
    data: customModules,
    isFetched,
    isError,
  } = useQuery(['customModules'], () => listCustomModules(user), {
    // Refetch every minute
    refetchInterval: 60000,
  });
  const isPushNotificationsActivated = isFetched || isError;
  usePushNotifications(isPushNotificationsActivated);

  const defaultScreenOptions: DrawerNavigationOptions = isMobile()
    ? {
        headerShown: true,
        headerTitleAlign: 'center',
        headerRight: HeaderButtons,
      }
    : {
        headerShown: true,
        headerTitleAlign: 'center',
        headerRight: HeaderButtons,
      };

  const drawerItems = useMemo(() => {
    let result = [
      () => (
        <Drawer.Screen
          key={ScreenNames.LoggedInHome}
          name={ScreenNames.LoggedInHome}
          component={LoggedInHomeScreen}
          options={{
            ...defaultScreenOptions,
            title: t('home'),
            drawerIcon: drawerIcon('house'),
          }}
        />
      ),
      ...(user.lovIdType === 13 || (user.userSubAdmins?.length ?? 0) > 0
        ? [
            () => (
              <Drawer.Screen
                key={ScreenNames.AdminRoute}
                name={ScreenNames.AdminRoute}
                component={AdminRoute}
                options={{
                  ...defaultScreenOptions,
                  title: t('admin_page_title'),
                  drawerIcon: drawerIcon('gear'),
                }}
              />
            ),
          ]
        : []),
      ...(hasLicenceSell(user)
        ? [
            () => (
              <Drawer.Screen
                key={ScreenNames.SellMainRouter}
                name={ScreenNames.SellMainRouter}
                component={ContributionRouter}
                initialParams={{
                  contributionType: ContributionType.SELL,
                  title: t('contribution_sell'),
                }}
                options={{
                  ...defaultScreenOptions,
                  title: t('contribution_sell'),
                  drawerIcon: drawerIcon('tags'),
                }}
              />
            ),
          ]
        : []),
      ...(hasLicenceActivity(user)
        ? [
            () => (
              <Drawer.Screen
                key={ScreenNames.ActivityMainRouter}
                name={ScreenNames.ActivityMainRouter}
                component={ContributionRouter}
                initialParams={{
                  contributionType: ContributionType.ACTIVITY,
                  title: t('contribution_activity'),
                }}
                options={{
                  ...defaultScreenOptions,
                  title: t('contribution_activity'),
                  drawerIcon: drawerIcon('rocket'),
                }}
              />
            ),
          ]
        : []),
      ...(hasLicenceMutualAid(user)
        ? [
            () => (
              <Drawer.Screen
                key={ScreenNames.MutualAidMainRouter}
                name={ScreenNames.MutualAidMainRouter}
                component={ContributionRouter}
                initialParams={{
                  contributionType: ContributionType.MUTUAL_AID,
                  title: t('contribution_mutual_aid'),
                }}
                options={{
                  ...defaultScreenOptions,
                  title: t('contribution_mutual_aid'),
                  drawerIcon: drawerIcon('question-circle'),
                }}
              />
            ),
          ]
        : []),
      ...(hasLicenceCarPool(user)
        ? [
            () => (
              <Drawer.Screen
                key={ScreenNames.CarPoolMainRouter}
                name={ScreenNames.CarPoolMainRouter}
                component={ContributionRouter}
                initialParams={{
                  contributionType: ContributionType.CARPOOL,
                  title: t('contribution_carpool'),
                }}
                options={{
                  ...defaultScreenOptions,
                  title: t('contribution_carpool'),
                  drawerIcon: drawerIcon('car'),
                }}
              />
            ),
          ]
        : []),
      ...(hasLicenceEvent(user)
        ? [
            () => (
              <Drawer.Screen
                key={ScreenNames.EventMainRouter}
                name={ScreenNames.EventMainRouter}
                component={ContributionRouter}
                initialParams={{
                  contributionType: ContributionType.EVENT,
                  title: t('contribution_event'),
                }}
                options={{
                  ...defaultScreenOptions,
                  title: t('contribution_event'),
                  drawerIcon: drawerIcon('calendar'),
                }}
              />
            ),
          ]
        : []),
      ...(hasLicenceTraining(user)
        ? [
            () => (
              <Drawer.Screen
                key={ScreenNames.TrainingMainRouter}
                name={ScreenNames.TrainingMainRouter}
                component={ContributionRouter}
                initialParams={{
                  contributionType: ContributionType.FORMATION,
                  title: t('contribution_training'),
                }}
                options={{
                  ...defaultScreenOptions,
                  title: t('contribution_training'),
                  drawerIcon: drawerIcon('graduation-cap'),
                }}
              />
            ),
          ]
        : []),
      ...(hasLicenceOrganizationalChart(user)
        ? [
            () => (
              <Drawer.Screen
                key={ScreenNames.OrganizationCharts}
                name={ScreenNames.OrganizationCharts}
                component={OrganizationChartsScreen}
                options={{
                  ...defaultScreenOptions,
                  title: t('organizational_charts'),
                  drawerIcon: drawerIcon('sitemap'),
                }}
              />
            ),
          ]
        : []),
      ...(hasLicenceLostProperty(user)
        ? [
            () => (
              <Drawer.Screen
                key={ScreenNames.LostPropertyMainRouter}
                name={ScreenNames.LostPropertyMainRouter}
                component={ContributionRouter}
                initialParams={{
                  contributionType: ContributionType.LOST_PROPERTY,
                  title: t('contribution_lost_property'),
                }}
                options={{
                  ...defaultScreenOptions,
                  title: t('contribution_lost_property'),
                  drawerIcon: drawerIcon('key'),
                }}
              />
            ),
          ]
        : []),
      ...(hasLicenceSurvey(user)
        ? [
            () => (
              <Drawer.Screen
                key={ScreenNames.SurveyMainRouter}
                name={ScreenNames.SurveyMainRouter}
                component={ContributionRouter}
                initialParams={{
                  contributionType: ContributionType.SURVEY,
                  title: t('i_say'),
                }}
                options={{
                  ...defaultScreenOptions,
                  title: t('i_say'),
                  drawerIcon: drawerIcon('box'),
                }}
              />
            ),
          ]
        : []),
      ...(hasLicenceSuggestion(user)
        ? [
            () => (
              <Drawer.Screen
                key={ScreenNames.SuggestionMainRouter}
                name={ScreenNames.SuggestionMainRouter}
                component={ContributionRouter}
                initialParams={{
                  contributionType: ContributionType.SUGGESTION,
                  title: t('suggestions_box'),
                }}
                options={{
                  ...defaultScreenOptions,
                  title: t('suggestions_box'),
                  drawerIcon: drawerIcon('lightbulb'),
                }}
              />
            ),
          ]
        : []),
      ...(hasLicenceDocument(user)
        ? [
            () => (
              <Drawer.Screen
                key={ScreenNames.DocumentMainRouter}
                name={ScreenNames.DocumentMainRouter}
                component={DocumentsRouter}
                initialParams={{
                  contributionType: ContributionType.DOCUMENT,
                  title: t('kiosk'),
                }}
                options={{
                  ...defaultScreenOptions,
                  title: t('kiosk'),
                  drawerIcon: drawerIcon('file'),
                }}
              />
            ),
          ]
        : []),
      () => (
        <Drawer.Screen
          key={ScreenNames.Directory}
          name={ScreenNames.Directory}
          component={DirectoryList}
          options={{
            ...defaultScreenOptions,
            title: t('directory_page_title'),
            drawerIcon: drawerIcon('users'),
          }}
        />
      ),
      ...(hasLicenceEmergencySms(user) && isUserInDomain
        ? [
            () => (
              <Drawer.Screen
                key={ScreenNames.EmergencySmsRouter}
                name={ScreenNames.EmergencySmsRouter}
                component={EmergencySmsRouter}
                options={{
                  ...defaultScreenOptions,
                  title: t('sms_alerts'),
                  drawerIcon: () => (
                    <MaterialCommunityIcons size={15} style={{ width: 24, overflow: 'hidden' }} name="alarm-light" />
                  ),
                }}
              />
            ),
          ]
        : []),
      // Hidden drawer screens */}
      () => (
        <Drawer.Screen
          key={ScreenNames.ContributionItemDetailScreen}
          name={ScreenNames.ContributionItemDetailScreen}
          component={ContributionItemDetailScreen}
          options={{
            drawerItemStyle: { display: 'none' },
            drawerLabel: () => null,
            headerShown: false,
            title: t('contribution'),
          }}
        />
      ),
      () => (
        <Drawer.Screen
          key={ScreenNames.ContributionEditFormScreen}
          name={ScreenNames.ContributionEditFormScreen}
          component={ContributionFormScreen}
          options={{
            drawerItemStyle: { display: 'none' },
            headerShown: true,
            headerLeft: () => <BackButton relative />,
            title: t('redact'),
          }}
        />
      ),
      () => (
        <Drawer.Screen
          key={ScreenNames.EditCompanyNews}
          name={ScreenNames.EditCompanyNews}
          component={CompanyNewsFormScreen}
          options={{
            drawerItemStyle: { display: 'none' },
            headerShown: true,
            headerLeft: () => <BackButton higher absoluteScreenName={ScreenNames.LoggedInHome} relative />,
            title: t('redact'),
          }}
        />
      ),
      () => (
        <Drawer.Screen
          key={ScreenNames.DetailCompanyNews}
          name={ScreenNames.DetailCompanyNews}
          component={CompanyNewsDetailScreen}
          options={{
            drawerItemStyle: { display: 'none' },
            headerShown: false,
            title: t('company_news'),
          }}
        />
      ),
      () => (
        <Drawer.Screen
          key={ScreenNames.CustomModuleDetails}
          name={ScreenNames.CustomModuleDetails}
          component={DetailsCustomModuleContributionScreen}
          options={{
            drawerItemStyle: { display: 'none' },
            headerShown: false,
            title: '',
          }}
        />
      ),
      () => (
        <Drawer.Screen
          key={ScreenNames.Messaging}
          name={ScreenNames.Messaging}
          component={MessagingRoute}
          options={{
            drawerItemStyle: { display: 'none' },
            headerShown: false,
            title: t('messaging'),
          }}
        />
      ),
    ];

    if (customModules) {
      customModules.forEach((customModule) => {
        result.splice(customModule.order, 0, () => (
          <Drawer.Screen
            key={customModule.identifier}
            name={customModule.identifier}
            component={CustomModuleRouter}
            initialParams={{
              moduleIdentifier: customModule.identifier,
            }}
            options={{
              ...defaultScreenOptions,
              title: customModule.title,
              drawerIcon: () =>
                customModule.iconId ? (
                  <View style={{ width: 24, height: 15, overflow: 'hidden' }}>
                    <Image style={{ width: 15, height: 15 }} source={getPictureURI(customModule.iconId)} />
                  </View>
                ) : (
                  <FontAwesome6 size={15} style={{ width: 24, overflow: 'hidden' }} name="undefined" />
                ),
            }}
          />
        ));
      });
    }

    return result;
  }, [user, customModules]);

  return (
    <Drawer.Navigator
      screenOptions={{
        unmountOnBlur: true,
        drawerType: isMobile() || isSmallDevice ? 'front' : 'permanent',
        headerLeft: isMobile() || isSmallDevice ? DrawerToggleButton : () => <></>,
      }}
      drawerContent={CustomDrawerContent}
      useLegacyImplementation={false}
      backBehavior="history"
    >
      {drawerItems.map((item) => item())}
    </Drawer.Navigator>
  );
}
