import React, { useState } from 'react';
import { t } from '../../services/translations';
import { TextFormField } from '../../components/atomic/FormField';
import { AppButton, Type } from '../../components/atomic/AppButton';
import { alertInfo, isMailValid } from '../../services/utils';
import { setLoading } from '../../store/action';
import { useDispatch } from 'react-redux';
import { TwoColumnsLayout } from '../../components/containers/TwoColumnsLayout';
import AppText, { Mode } from '../../components/atomic/AppText';
import { Space1, Space2 } from '../../styles/spaces';
import { FormButtons } from '../../components/containers/FormButtons';
import { FormBody } from '../../components/containers/FormBody';
import { generateValidationCodeLostPassword, sendRequestForgottenPassword } from '../../services/api/login';
import { ScreenNames } from '../../ScreenNames';

const registrationBackground = require('../../../assets/registration-background.png');

export function ForgottenPasswordStep1(props: { route; navigation }) {
  const [emailAddress, setEmailAddress] = useState('');
  const dispatch = useDispatch();

  const _sendInitialRequest = () => {
    if (!isMailValid(emailAddress)) return;
    dispatch(setLoading(true));
    sendRequestForgottenPassword(emailAddress)
      .then(async () => {
        await generateValidationCodeLostPassword(emailAddress);
        props.navigation.navigate(ScreenNames.ForgottenPasswordStep2, {
          emailAddress: emailAddress,
        });
      })
      .catch((e) => {
        alertInfo(t('unknown_error'));
      })
      .finally(() => dispatch(setLoading(false)));
  };
  return (
    <TwoColumnsLayout background={registrationBackground}>
      <FormBody>
        <AppText mode={Mode.HEADER1}>{t('forgotten_password_step_1_title')}</AppText>
        <AppText>{t('forgotten_password_step_1_text')}</AppText>
        <Space1 />
        <TextFormField
          keyboardType="email-address"
          title={t('email_address')}
          value={emailAddress}
          onChangeText={setEmailAddress}
          onSubmitEditing={_sendInitialRequest}
        />
      </FormBody>
      <FormButtons>
        <AppButton
          title={t('send_button')}
          onPress={_sendInitialRequest}
          type={Type.PRIMARY}
          enabled={isMailValid(emailAddress)}
        />
        <Space2 />
        <AppButton
          title={t('back_button')}
          onPress={() => props.navigation.navigate(ScreenNames.Login)}
          enabled={true}
        />
      </FormButtons>
    </TwoColumnsLayout>
  );
}
