import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Image,
  ImageSourcePropType,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  View,
  Linking,
  FlatList,
  TouchableOpacity,
  TouchableWithoutFeedback,
} from 'react-native';
import { AppButton, Type } from './AppButton';
import { t } from '../../services/translations';
import {
  alertInfo,
  isMobile,
  pickDocument,
  pickPhoto,
  PickPhotoOrDocumentResult,
  takePhoto,
  _compressImage,
} from '../../services/utils';
import { AppModalOverlay } from './AppModalOverlay';
import { LIGHT_GRAY, PRIMARY_COLOR, WHITE } from '../../styles/appColor';
import AppText, { Mode } from './AppText';
import { Asset } from 'expo-asset';
import { SearchTextInput } from './SearchTextInput';
import { Icon } from '@rneui/base';
import FadeInView from './FadeInView';
import { FONT_FAMILY_DEFAULT } from '../../styles/globalStyle';
import { Pdf } from './Pdf';

export type PhotoPickerProps = {
  photo: PickPhotoOrDocumentResult | null | undefined;
  defaultPhoto?: ImageSourcePropType;
  images?: {
    file: ImageSourcePropType;
    uri: string;
    id?: string;
    author?: string;
    url?: string;
  }[];
  isLoading?: boolean;
  extraOption?: {
    label: string;
    icon: string;
    onPress: () => void;
  }[];
  allowPdf?: boolean;
  onPress?: (id: string) => void;
  setPhoto: (photo: PickPhotoOrDocumentResult) => void;
  searchImages?: (query: string) => void;
  fetchNext?: () => void;
};

export function PhotoPicker({
  photo,
  defaultPhoto,
  images,
  isLoading,
  extraOption,
  allowPdf,
  onPress,
  setPhoto,
  searchImages,
  fetchNext,
}: PhotoPickerProps) {
  // If photo is a File, we compute the uri as base64
  let photoUri = useMemo(() => {
    if (photo && photo.type === 'application/pdf') {
      return photo.uri;
    } else if (photo && photo.uri) {
      return photo.uri;
    } else if (photo instanceof File) {
      return URL.createObjectURL(photo);
    } else {
      return undefined;
    }
  }, [photo]);

  return (
    <View style={styles.container}>
      {photo && photo.type === 'application/pdf' ? (
        <Pdf source={photo} style={styles.document} />
      ) : (
        (photoUri !== null || defaultPhoto !== undefined) && (
          <Image source={photoUri ? { uri: photoUri } : defaultPhoto} style={styles.image} resizeMode="cover" />
        )
      )}
      <PhotoPickButtons
        modalText={t('image_bank')}
        images={images}
        setPhoto={setPhoto}
        isLoading={isLoading}
        extraOption={extraOption}
        allowPdf={allowPdf}
        onPress={onPress}
        searchImages={searchImages}
        fetchNext={fetchNext}
      />
    </View>
  );
}

export interface PhotoPickButtonsProps {
  modalText: string;
  images?: {
    file?: number;
    uri: string;
    id?: string;
    author?: string;
    url?: string;
  }[];
  isLoading?: boolean;
  extraOption?: {
    label: string;
    icon: string;
    onPress: () => void;
  }[];
  allowPdf?: boolean;
  onPress?: (id: string) => void;
  setPhoto: (photo: PickPhotoOrDocumentResult) => void;
  searchImages?: (query: string) => void;
  fetchNext?: () => void;
}

export function PhotoPickButtons({
  modalText,
  images,
  isLoading,
  extraOption,
  allowPdf,
  onPress,
  setPhoto,
  searchImages,
  fetchNext,
}: PhotoPickButtonsProps) {
  const [modalVisible, setModalVisible] = useState(false);
  const [mobileOptionChoiceModal, setMobileOptionChoiceModal] = useState(false);

  const options = useMemo(
    () =>
      !isMobile()
        ? [
            {
              label: t('import_image'),
              icon: 'upload',
              onPress: () => {
                if (allowPdf) {
                  pickDocument(undefined, ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'svg', 'pdf'])
                    .then((document) => {
                      setPhoto(document);
                    })
                    .catch((e) => {
                      console.error(e);
                      alertInfo(e?.message);
                    });
                } else {
                  pickPhoto().then((photo) => {
                    setPhoto(photo);
                  });
                }
              },
            },
            {
              label: modalText || t('image_bank'),
              icon: 'image',
              onPress: () => setModalVisible(true),
            },
            ...(extraOption || []),
          ]
        : [
            {
              label: t('take_a_picture'),
              icon: 'camera',
              onPress: () => {
                takePhoto()
                  .then((photo) => {
                    setPhoto(photo);
                  })
                  .catch((e) => {
                    console.error(e?.message);
                  })
                  .finally(() => {
                    setMobileOptionChoiceModal(false);
                  });
              },
            },
            {
              label: t('import_image'),
              icon: 'upload',
              onPress: () => {
                pickPhoto()
                  .then((photo) => {
                    setPhoto(photo);
                  })
                  .catch((e) => {
                    console.error(e?.message);
                  })
                  .finally(() => {
                    setMobileOptionChoiceModal(false);
                  });
              },
            },
            {
              label: modalText || t('image_bank'),
              icon: 'image',
              onPress: () => {
                setModalVisible(true);
                setMobileOptionChoiceModal(false);
              },
            },
          ],
    []
  );

  return (
    <>
      <View style={styles.buttonContainer}>
        {!isMobile() ? (
          options.map((elem) => (
            <AppButton
              key={elem.label}
              style={styles.button}
              textStyle={{ fontSize: 11 }}
              title={elem.label}
              type={Type.LIGHT}
              onPress={elem.onPress}
              icon={elem.icon}
              iconOnTopEdge
            />
          ))
        ) : (
          <>
            <AppButton
              style={styles.button}
              textStyle={{ fontSize: 11 }}
              title={t('pick_photo')}
              type={Type.LIGHT}
              onPress={() => setMobileOptionChoiceModal(true)}
              icon="image"
              iconOnTopEdge
            />
            {extraOption?.map((elem) => (
              <AppButton
                key={elem.label}
                style={styles.button}
                textStyle={{ fontSize: 11 }}
                title={elem.label}
                type={Type.LIGHT}
                onPress={elem.onPress}
                icon={elem.icon}
                iconOnTopEdge
              />
            ))}
          </>
        )}
      </View>
      {!!images && (
        <PhotoPickerModal
          isVisible={modalVisible}
          modalText={modalText}
          images={images}
          isLoading={isLoading}
          onPress={onPress}
          setPhoto={setPhoto}
          close={() => setModalVisible(false)}
          searchImages={searchImages}
          fetchNext={fetchNext}
        />
      )}
      {isMobile() && (
        <MobileOptionChoiceModal
          options={options}
          visible={mobileOptionChoiceModal}
          setVisible={setMobileOptionChoiceModal}
        />
      )}
    </>
  );
}

type PhotoPickerModalProps = {
  isVisible: boolean;
  modalText: string;
  images: {
    file: ImageSourcePropType;
    uri: string;
    id?: string;
    author?: string;
    url?: string;
  }[];
  isLoading?: boolean;
  onPress?: (id: string) => void;
  setPhoto: (photo: PickPhotoOrDocumentResult) => void;
  close: () => void;
  searchImages?: (query: string) => void;
  fetchNext?: () => void;
};

function PhotoPickerModal({
  images,
  modalText,
  isVisible,
  isLoading,
  onPress,
  setPhoto,
  close,
  searchImages,
  fetchNext,
}: PhotoPickerModalProps) {
  const [searchQuery, setSearchQuery] = useState('');
  const [hoveredIndex, setHoveredIndex] = useState<null | number>(null);

  // Initial search with an empty query
  useEffect(() => {
    if (searchImages) {
      searchImages('');
    }
  }, []);

  // Search when query is updated, three characters minimum
  useEffect(() => {
    if (searchImages && (searchQuery.length > 2 || searchQuery.length === 0)) {
      searchImages(searchQuery);
    }
  }, [searchQuery]);

  const handleImagePressed = useCallback(
    (item: { file?: number; uri: string; id?: string; author?: string; url?: string }) => async () => {
      onPress && item.id && onPress(item.id);

      if (Platform.OS === 'web') {
        const [result] = await Asset.loadAsync(item.file || item.uri);
        let blob = await fetch(result.uri).then((r) => r.blob());
        let metadata = {
          type: 'image/png',
        };
        let file = new File([blob], 'mypicture.png', metadata);
        file.uri = result.uri;

        setPhoto(file);
      } else {
        const [result] = await Asset.loadAsync(item.uri);
        const compressedResult = await _compressImage({ assets: [result] });

        var newPhoto = {
          name: 'mypicture.png',
          fileName: 'mypicture.png',
          type: 'image/png',
          height: compressedResult.height,
          width: compressedResult.width,
          uri: compressedResult.uri,
        };

        setPhoto(newPhoto);
      }
      close();
    },
    [onPress, close]
  );

  return (
    <AppModalOverlay isVisible={isVisible} overlayStyle={{ height: 500, width: 300, padding: 0, borderRadius: 5 }}>
      <AppText style={{ textAlign: 'center', paddingVertical: 20, fontSize: 25 }}>{modalText}</AppText>
      <View style={{ paddingHorizontal: 20, paddingBottom: 10 }}>
        {searchImages && (
          <SearchTextInput
            style={{ marginTop: 10, marginBottom: 0 }}
            inputStyle={{
              marginHorizontal: 7.5,
              marginVertical: 7.5,
            }}
            onChangeText={setSearchQuery}
            value={searchQuery}
          />
        )}
      </View>
      <View style={{ flex: 1 }}>
        <FlatList
          numColumns={2}
          contentContainerStyle={{
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
          }}
          onEndReached={fetchNext}
          data={images}
          keyExtractor={(item) => item.uri}
          refreshing={isLoading}
          ListEmptyComponent={<AppText style={{ margin: 10 }}>{t('no_image_found')}</AppText>}
          renderItem={({ item, index }) => (
            <TouchableOpacity
              key={item.uri}
              style={{ backgroundColor: 'transparent' }}
              onPress={handleImagePressed(item)}
            >
              <Pressable
                style={{ margin: 5 }}
                onPress={handleImagePressed(item)}
                onHoverIn={() => setHoveredIndex(index)}
                onHoverOut={() => setHoveredIndex(null)}
              >
                <Image style={{ width: 100, height: 100 }} source={item.file || { uri: item.uri }} />
                {!isMobile() && item.author && item.url && (
                  <FadeInView isVisible={hoveredIndex === index} style={styles.authorOverlay}>
                    <AppText style={{ color: WHITE, fontFamily: FONT_FAMILY_DEFAULT.bold, fontSize: 8 }}>
                      By{' '}
                      <TouchableWithoutFeedback
                        style={{ backgroundColor: 'red' }}
                        onPress={(ev) => {
                          ev.preventDefault();
                          Linking.openURL(item.url || 'https://unsplash.com/?utm_source=Konecteam&utm_medium=referral');
                        }}
                      >
                        <Text style={{ textDecorationLine: 'underline' }}>{item.author}</Text>
                      </TouchableWithoutFeedback>{' '}
                      on{' '}
                      <TouchableWithoutFeedback
                        onPress={(ev) => {
                          ev.preventDefault();
                          Linking.openURL('https://unsplash.com/?utm_source=Konecteam&utm_medium=referral');
                        }}
                      >
                        <Text style={{ textDecorationLine: 'underline' }}>Unsplash</Text>
                      </TouchableWithoutFeedback>
                    </AppText>
                  </FadeInView>
                )}
              </Pressable>
            </TouchableOpacity>
          )}
        />
      </View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-around',
          padding: 10,
        }}
      >
        <AppButton title={t('back')} style={{ width: 100 }} onPress={close} />
      </View>
    </AppModalOverlay>
  );
}

export type MobileOptionChoiceModalProps = {
  options: {
    label: string;
    icon: string;
    onPress: () => void;
  }[];
  visible: boolean;
  setVisible: (value: boolean) => void;
};

export function MobileOptionChoiceModal({ options, visible, setVisible }: MobileOptionChoiceModalProps) {
  return (
    <AppModalOverlay overlayStyle={styles.overlayStyle} isVisible={visible} onBackdropPress={() => setVisible(false)}>
      {options.map((option, index) => (
        <>
          {index > 0 && <View style={{ width: '100%', height: 1, backgroundColor: LIGHT_GRAY }} />}
          <TouchableOpacity
            onPress={option.onPress}
            style={{ flexDirection: 'row', alignItems: 'center', padding: 20 }}
          >
            <Icon size={18} name={option.icon} type="feather" color={PRIMARY_COLOR} style={{ marginRight: 20 }} />
            <AppText style={{ color: PRIMARY_COLOR }}>{option.label}</AppText>
          </TouchableOpacity>
        </>
      ))}
    </AppModalOverlay>
  );
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  image: {
    width: 150,
    height: 150,
    marginBottom: 10,
    borderRadius: 20,
  },
  document: {
    width: 150,
    marginBottom: 10,
  },
  buttonContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  button: {
    width: 150,
    margin: 10,
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: LIGHT_GRAY,
  },
  overlayStyle: isMobile()
    ? {
        width: '90%',
        padding: 0,
        position: 'absolute',
        bottom: 0,
      }
    : { borderRadius: 20, padding: 0 },
  authorOverlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.4)',
    justifyContent: 'flex-end',
    padding: 5,
    zIndex: 5,
  },
});
