import React from 'react';
import { t } from '../../services/translations';
import { AppButton } from '../../components/atomic/AppButton';
import { setLoading } from '../../store/action';
import { useDispatch } from 'react-redux';
import { TwoColumnsLayout } from '../../components/containers/TwoColumnsLayout';
import AppText, { Mode } from '../../components/atomic/AppText';
import { Space1, Space2 } from '../../styles/spaces';
import { ValidationCodeField } from '../../components/atomic/ValidationCodeField';
import { FormButtons } from '../../components/containers/FormButtons';
import { FormBody } from '../../components/containers/FormBody';
import { sendRequestForgottenPasswordCode } from '../../services/api/login';
import { alertInfo } from '../../services/utils';
import { ScreenNames } from '../../ScreenNames';

const registrationBackground = require('../../../assets/registration-background.png');

export function ForgottenPasswordStep2(props: { route; navigation }) {
  const dispatch = useDispatch();

  const _sendCode = (code: string) => {
    dispatch(setLoading(true));
    sendRequestForgottenPasswordCode(props.route.params.emailAddress, code)
      .then(() => {
        props.navigation.navigate(ScreenNames.ForgottenPasswordStep3, {
          emailAddress: props.route.params.emailAddress,
        });
      })
      .catch((e) => {
        alertInfo(t('invalid_code'));
      })
      .finally(() => dispatch(setLoading(false)));
  };

  return (
    <TwoColumnsLayout background={registrationBackground}>
      <FormBody>
        <AppText mode={Mode.HEADER1}>{t('forgotten_password_step_2_title')}</AppText>
        <AppText>{t('forgotten_password_step_2_text')}</AppText>
        <Space1 />
        <ValidationCodeField onCodeEntered={_sendCode} />
      </FormBody>
      <FormButtons>
        <Space2 />
        <AppButton
          title={t('back_button')}
          onPress={() => props.navigation.navigate(ScreenNames.Login)}
          enabled={true}
        />
      </FormButtons>
    </TwoColumnsLayout>
  );
}
