import { useEffect, useMemo, useState } from 'react';
import { OptionalObjectSchema } from 'yup/lib/object';
import { SpreadingPostData } from '../models/SpreadingPostData';

const useEditContribution = <T extends SpreadingPostData & { [key: string]: any } = any>(
  id: number | undefined,
  initial: Partial<T>,
  fetcher: (id: number) => Promise<Partial<T>>,
  validationSchema: OptionalObjectSchema<any>
) => {
  const [data, setData] = useState<Partial<T>>(initial);
  const [loading, setLoading] = useState(!!id);

  useEffect(() => {
    if (!id) return;
    fetcher(id).then((data) => {
      const copy = { ...initial };
      Object.assign(copy, data);
      setData(copy);
      setLoading(false);
    });
  }, [id]);

  const valid = useMemo(() => {
    return !!validationSchema.isValidSync(data);
  }, [data, validationSchema]);

  const onChange = (key: keyof T) => (value: any) => setData((prev) => ({ ...prev, [key]: value }));

  return { data: data as T, loading, onChange, valid };
};

export default useEditContribution;
