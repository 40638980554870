import React from 'react';
import { t } from '../services/translations';
import { ProfileScreen } from '../screens/loggedIn/ProfileScreen';
import { DirectoryList } from '../screens/loggedIn/DirectoryScreen';
import { ContributionType } from '../types';
import { createStackNavigator } from '@react-navigation/stack';
import { SuggestionsAdministrationRoute } from './SuggestionsAdministrationRoute';
import { CompanyNewsAdministrationRoute } from './CompanyNewsAdministrationRoute';
import { SurveyAdministrationRoute } from './SurveyAdministrationRoute';
import { ContributionListScreen } from '../screens/loggedIn/contribution/ContributionListScreen';
import { View } from 'react-native';
import { EditPasswordScreen } from '../screens/loggedIn/EditPasswordScreen';
import { GetContributionsMode } from '../services/api/getContributionsMode';
import { ScreenNames } from '../ScreenNames';
import { CategoriesAdministrationRoute } from './CategoriesAdministrationRoute';
import LoggedInDrawerNavigation from './LoggedInDrawerNavigation';
import useLoggedUser from '../hooks/useLoggedUser';
import EditUserScreen from '../screens/loggedIn/admin/EditUserScreen';
import { LoginEditProfileScreen } from '../screens/loggedIn/LoginEditProfileScreen';
import { EditMyProfileScreen } from '../screens/loggedIn/EditMyProfileScreen';
import BackIcon from '../components/atomic/BackIcon';

const Stack = createStackNavigator();

export function LoggedInRoute() {
  let user = useLoggedUser();

  if (user.loginCount === 0) {
    return <LoginEditProfileScreen />;
  }

  return (
    <View style={{ flex: 1 }}>
      <Stack.Navigator screenOptions={{ headerShown: false, headerBackImage: BackIcon }}>
        <Stack.Screen name={ScreenNames.LoggedInDrawerNavigation} component={LoggedInDrawerNavigation} />

        <Stack.Screen
          name={ScreenNames.Profile}
          component={ProfileScreen}
          options={{
            headerShown: false,
            title: t('profile_page_title'),
          }}
        />

        <Stack.Screen
          name={ScreenNames.EditMyProfile}
          component={EditMyProfileScreen}
          options={{
            headerShown: false,
            title: t('update_my_profile'),
          }}
        />
        <Stack.Screen
          name={ScreenNames.EditUser}
          component={EditUserScreen}
          options={{
            headerShown: false,
            title: t('edit_user'),
          }}
        />
        <Stack.Screen
          name={ScreenNames.EditPasswordScreen}
          component={EditPasswordScreen}
          options={{
            headerShown: false,
            title: t('change_password'),
          }}
        />

        <Stack.Screen
          name={ScreenNames.SuggestionsAdministration}
          component={SuggestionsAdministrationRoute}
          initialParams={{
            isAdminView: true,
          }}
          options={{
            headerShown: true,
            title: t('suggestions_box'),
          }}
        />
        <Stack.Screen
          name={ScreenNames.Directory}
          component={DirectoryList}
          options={{
            headerShown: true,
            title: t('contributors'),
          }}
          initialParams={{
            allowEdit: false,
          }}
        />
        <Stack.Screen
          name={ScreenNames.ContributionsAdministration}
          component={ContributionListScreen}
          options={{
            headerShown: true,
            title: t('contributions'),
          }}
          initialParams={{
            getContributionsMode: GetContributionsMode.all,
            contributionType: ContributionType.ALL,
            isAdminView: true,
          }}
        />
        <Stack.Screen
          name={ScreenNames.CompanyNewsAdministration}
          component={CompanyNewsAdministrationRoute}
          initialParams={{
            isAdminView: true,
          }}
          options={{
            headerShown: true,
            title: t('company_news'),
          }}
        />
        <Stack.Screen
          name={ScreenNames.CategoriesAdministration}
          component={CategoriesAdministrationRoute}
          initialParams={{
            isAdminView: true,
          }}
          options={{
            headerShown: true,
            title: t('categories_menu'),
          }}
        />
        <Stack.Screen
          name={ScreenNames.SurveyAdministration}
          component={SurveyAdministrationRoute}
          options={{
            headerShown: true,
            title: t('surveys'),
          }}
        />
      </Stack.Navigator>
    </View>
  );
}
