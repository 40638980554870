import React, { useEffect, useState } from 'react';
import { FlatList } from 'react-native';
import { User } from '../../entities/User';
import { isMobile } from '../../services/utils';
import { AppLoader } from '../atomic/AppLoader';
import { ProfileCard } from '../atomic/ProfileCard';

export type ProfileCardListProps = {
  users: User[];
  isLoading?: boolean;
  fetchNext?: () => Promise<void>;
  refresh?: () => Promise<void>;
};

export default function ProfileCardList({ users, isLoading, fetchNext, refresh }: ProfileCardListProps) {
  return (
    <>
      <FlatList
        horizontal={false}
        numColumns={isMobile() ? 2 : undefined}
        contentContainerStyle={
          isMobile()
            ? undefined
            : {
                flexDirection: 'row',
                flexWrap: 'wrap',
              }
        }
        data={users}
        renderItem={(item) => <ProfileCard user={item.item} />}
        keyExtractor={(item) => item.id.toString()}
        onEndReached={fetchNext}
        onRefresh={refresh}
        refreshing={isLoading}
      />
      {isLoading && <AppLoader />}
    </>
  );
}
