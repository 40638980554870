import { ActivityIndicator, View } from 'react-native';
import React from 'react';
import { PRIMARY_COLOR } from '../../styles/appColor';

export function AppLoader(props: { isVisible?: boolean }) {
  return (
    <View
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: '#00000066',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
      }}
    >
      <View
        style={{
          backgroundColor: 'white',
          padding: 30,
          flex: 0,
          borderRadius: 10,
          alignItems: 'center',
          alignContent: 'center',
          justifyContent: 'center',
        }}
      >
        <ActivityIndicator color={PRIMARY_COLOR} size="large" />
      </View>
    </View>
  );
}
