import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { _compressImage, alertInfo } from '../../services/utils';
import { saveLanguage } from '../../services/storage';
import { AppSafeAreaView } from '../../components/containers/AppSafeAreaView';
import { AppScreen } from '../../components/containers/AppScreen';
import { EditUserForm } from '../../components/molecules/EditUserForm';
import { t } from '../../services/translations';
import { getUserProfile } from '../../services/api/user.api';
import { BackButton } from '../../components/atomic/BackButton';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import { ScrollView } from 'react-native-gesture-handler';
import { AppButton, Type } from '../../components/atomic/AppButton';
import { ScreenNames } from '../../ScreenNames';
import useLoggedUser from '../../hooks/useLoggedUser';
import { setUser } from '../../store/user';

export function EditMyProfileScreen() {
  const user = useLoggedUser();
  const navigation = useNavigation<NavigationProp<any>>();
  const dispatch = useDispatch();

  const handleSubmitEnd = useCallback(
    async (values: any) => {
      saveLanguage(values.languageCode);
      alertInfo(t('profile_updated'));

      const updatedUser = await getUserProfile(user.id, user.token);
      await setUser({ ...user, ...updatedUser }, dispatch);
      navigation.goBack();
    },
    [user]
  );

  return (
    <AppScreen>
      <AppSafeAreaView style={{ flex: 1 }}>
        <ScrollView>
          <EditUserForm
            userId={user.id}
            onSubmitEnd={handleSubmitEnd}
            title={t('update_my_profile')}
            allowAvatarEdit
            displayHideEmail
          />
          <AppButton
            type={Type.LIGHT}
            title={t('change_password')}
            style={{ alignSelf: 'center', marginBottom: 20 }}
            onPress={() => {
              navigation.navigate(ScreenNames.EditPasswordScreen);
            }}
          />
          <BackButton />
        </ScrollView>
      </AppSafeAreaView>
    </AppScreen>
  );
}
