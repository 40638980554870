import React, { useState } from 'react';
import { t } from '../../services/translations';
import { TextFormField } from '../../components/atomic/FormField';
import { AppButton, Type } from '../../components/atomic/AppButton';
import { setLoading } from '../../store/action';
import { useDispatch } from 'react-redux';
import AppText, { Mode } from '../../components/atomic/AppText';
import { TwoColumnsLayout } from '../../components/containers/TwoColumnsLayout';
import { Space1, Space2 } from '../../styles/spaces';
import { FormButtons } from '../../components/containers/FormButtons';
import { FormBody } from '../../components/containers/FormBody';
import { sendRequestForgottenPasswordNewPassword } from '../../services/api/login';
import { alertInfo } from '../../services/utils';
import { ScreenNames } from '../../ScreenNames';

const registrationBackground = require('../../../assets/registration-background.png');

export function ForgottenPasswordStep3(props: { route; navigation }) {
  const [password, setPassword] = useState(__DEV__ ? 'testtest' : '');
  const [passwordConfirmation, setPasswordConfirmation] = useState(__DEV__ ? 'testtest' : '');
  const dispatch = useDispatch();
  const isFormReady = password.length >= 4 && password === passwordConfirmation;
  const _sendNewPassword = () => {
    if (!isFormReady) return;
    dispatch(setLoading(true));
    sendRequestForgottenPasswordNewPassword(props.route.params.emailAddress, password)
      .then(() => {
        alertInfo(t('new_password_success'));
        props.navigation.navigate(ScreenNames.Login);
      })
      .catch((e) => {
        alertInfo(t('unknown_error'));
      })
      .finally(() => dispatch(setLoading(false)));
  };

  return (
    <TwoColumnsLayout background={registrationBackground}>
      <FormBody>
        <AppText mode={Mode.HEADER1}>{t('forgotten_password_step_3_title')}</AppText>
        <AppText>{t('forgotten_password_step_3_text')}</AppText>
        <Space1 />
        <TextFormField secureTextEntry={true} title={t('password')} value={password} onChangeText={setPassword} />
        <TextFormField
          secureTextEntry={true}
          title={t('password_confirmation')}
          value={passwordConfirmation}
          onChangeText={setPasswordConfirmation}
          onSubmitEditing={_sendNewPassword}
        />
        {password !== passwordConfirmation && passwordConfirmation.length > 0 ? (
          <AppText mode={Mode.ERROR}>{t('passwords_dont_match')}</AppText>
        ) : null}
      </FormBody>
      <FormButtons>
        <Space2 />
        <AppButton title={t('send_button')} onPress={_sendNewPassword} type={Type.PRIMARY} enabled={isFormReady} />
        <Space2 />
        <AppButton
          title={t('back_button')}
          onPress={() => props.navigation.navigate(ScreenNames.Login)}
          enabled={true}
        />
      </FormButtons>
    </TwoColumnsLayout>
  );
}
