import { AnyAction, Dispatch } from 'redux';
import { ScreenNames } from '../ScreenNames';
import { GetContributionsMode } from '../services/api/getContributionsMode';
import { UserSaved } from '../services/storage';
import { navigationRef } from '../services/utils';
import { ContributionType } from '../types';
import { getCustomModuleContributionsDetails } from '../services/api/custom.module.api';
import { setLoading } from '../store/action';

export const navigateToNotification = (
  contributionType: ContributionType,
  postId: number,
  isAdminView: boolean,
  user: UserSaved,
  dispatch: Dispatch<AnyAction>
) => {
  if (contributionType === ContributionType.ALL) {
    navigationRef.current?.navigate(ScreenNames.CompanyAdminMain, {
      screen: ScreenNames.ContributionsAdministration,
      initial: false,
      params: {
        getContributionsMode: GetContributionsMode.all,
        contributionType: ContributionType.ALL,
        isSignaled: true,
        isAdminView: true,
        isNotif: true,
      },
    });
  } else if (contributionType === ContributionType.COMPANY_NEWS) {
    navigationRef.current?.navigate(ScreenNames.LoggedInDrawerNavigation, {
      screen: ScreenNames.DetailCompanyNews,
      params: {
        id: postId,
        isNotif: true,
      },
    });
  } else if (contributionType === ContributionType.CUSTOM_MODULE) {
    navigateToCustomModuleDetails(postId, user, dispatch);
  } else {
    navigationRef.current?.navigate(ScreenNames.LoggedInDrawerNavigation, {
      screen: ScreenNames.ContributionItemDetailScreen,
      params: {
        id: postId,
        contributionType: contributionType,
        isNotif: true,
        isAdminView,
      },
    });
  }
};

async function navigateToCustomModuleDetails(postId: number, user: UserSaved, dispatch: Dispatch<AnyAction>) {
  dispatch(setLoading(true));
  let customModule = await getCustomModuleContributionsDetails(user, postId);
  dispatch(setLoading(false));

  navigationRef.current?.reset({
    index: 1,
    routes: [
      {
        name: customModule.module?.identifier || '',
        state: {
          index: 0,
          routes: [{ name: ScreenNames.CustomModuleTabs }],
        },
      },
      {
        name: ScreenNames.CustomModuleDetails,
        params: {
          id: postId,
          isNotif: true,
        },
      },
    ],
  });
}
