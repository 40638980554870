import React, { useEffect, useState } from 'react';
import { Category } from '../../entities/Category';
import { GlobalCategory } from '../../utils/Category';
import { CategoryChip } from '../atomic/CategoryChip';
import HorizontalFlatList from './HorizontalList';

export interface CategoriesListProps {
  categories: Category[];
  onSelect?: (category?: Category) => void;
}

export const CategoriesList = ({ categories, onSelect }: CategoriesListProps) => {
  const [selectedCategory, setSelectedCategory] = useState<Category | undefined>();

  const onPress = (category: Category) => {
    if (category.id === GlobalCategory.id || category.id === selectedCategory?.id) setSelectedCategory(undefined);
    else setSelectedCategory(category);
  };

  useEffect(() => {
    onSelect?.(selectedCategory);
  }, [selectedCategory, onSelect]);

  return (
    <HorizontalFlatList
      data={[GlobalCategory, ...categories]}
      renderItem={(category) => (
        <CategoryChip
          style={{ marginEnd: 10 }}
          selected={selectedCategory?.id === category.item.id}
          onPress={() => onPress(category.item)}
          category={category.item}
        />
      )}
      keyExtractor={(cat) => cat.id.toString()}
    />
  );
};
