import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { StyleSheet, View } from 'react-native';
import { useQuery } from '@tanstack/react-query';
import { CountUp } from 'use-count-up';
import { format } from 'date-fns';
import { AppScreen } from '../../../components/containers/AppScreen';
import { getCompanyStatistics, getTopNewsStatistics } from '../../../services/api/statistics.api';
import { setLoading } from '../../../store/action';
import { alertInfo } from '../../../services/utils';
import { dateLocale, t } from '../../../services/translations';
import StatisticsSquare from '../../../components/atomic/StatisticSquare';
import AppText, { Mode } from '../../../components/atomic/AppText';
import { PRIMARY_COLOR } from '../../../styles/appColor';
import { ResponsiveFlatList } from '../../../components/atomic/ResponsiveFlatList';
import { ContributionItemRowCompanyNews } from '../../../components/atomic/ContributionItemSquare';
import { useNavigation } from '@react-navigation/native';
import { ScreenNames } from '../../../ScreenNames';
import { ContributionType } from '../../../types';
import { StackNavigationProp } from '@react-navigation/stack';
import { ScrollView, TouchableOpacity } from 'react-native-gesture-handler';
import { MActuPost } from '../../../entities/MActuPost';
import { getCompanyGeoSites, getCompanyServices } from '../../../services/api/company.api';
import { NewsCountStatistics } from '../../../components/molecules/NewsCountStatistics';
import { ContributionCountStatistics } from '../../../components/molecules/ContributionCountStatistics';
import { Chip } from '../../../components/atomic/Chip';
import useLoggedUser from '../../../hooks/useLoggedUser';

export default function StatisticsScreen() {
  const user = useLoggedUser();
  const dispatch = useDispatch();
  const navigation = useNavigation<StackNavigationProp<any>>();
  const locale = dateLocale();
  const entrepriseId = user?.entrepriseId || 0;

  const newDate = new Date();
  const newDateMinusOneYear = new Date(newDate);
  newDateMinusOneYear.setFullYear(newDateMinusOneYear.getFullYear() - 1);

  const {
    data: services,
    isLoading: isLoadingServices,
    isError: isErrorServices,
  } = useQuery(['services', entrepriseId], () => getCompanyServices(entrepriseId, user.token));

  const {
    data: siteGeos,
    isLoading: isLoadingSiteGeos,
    isError: isErrorSiteGeos,
  } = useQuery(['siteGeos', entrepriseId], () => getCompanyGeoSites(entrepriseId, user.token));

  // List years from company creation date to now
  const years = useMemo(() => {
    let companyCreationDate = new Date(user.entreprise.dateCreation);
    let companyCreationYear = companyCreationDate.getFullYear();
    let nowYear = new Date().getFullYear();

    return [...Array(nowYear - companyCreationYear + 1).keys()].map((i) => {
      return {
        label: (companyCreationYear + i).toString(),
        value: companyCreationYear + i,
      };
    });
  }, [user]);

  const {
    data: generalData,
    isLoading: isLoadingGeneral,
    isError: isErrorGeneral,
  } = useQuery(['statistics-general'], async () => ({
    company: await getCompanyStatistics(user),
    topNews: await getTopNewsStatistics(user),
  }));

  const isLoading = isLoadingGeneral || isLoadingServices || isLoadingSiteGeos;
  const isError = isErrorGeneral || isErrorServices || isErrorSiteGeos;

  // Display big loading indicator if refreshing
  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [isLoading, dispatch]);

  // Display error message if needed
  useEffect(() => {
    if (isError) {
      alertInfo(t('error_occurred'));
    }
  }, [isError]);

  const onPressNews = (news: MActuPost) => () => {
    navigation.push(ScreenNames.LoggedInDrawerNavigation, {
      screen: ScreenNames.DetailCompanyNews,
      params: {
        id: news.id,
        contributionType: ContributionType.COMPANY_NEWS,
        isNotif: false,
        isAdminView: false,
      },
    });
  };

  return (
    <AppScreen>
      <ScrollView>
        {generalData && (
          <>
            <AppText style={styles.subtitle} mode={Mode.BOLD}>
              {t('general_statistics')}
            </AppText>
            <View style={styles.generalContainer}>
              <StatisticsSquare
                title={t('creation_date')}
                value={format(new Date(generalData.company.creationDate), 'd MMMM yyyy', { locale })}
              />
              <StatisticsSquare
                title={t('total_users_count')}
                value={<CountUp isCounting end={generalData.company.usersCount} duration={1.5} />}
              />
              <StatisticsSquare
                title={t('total_news_count')}
                value={<CountUp isCounting end={generalData.company.newsCount} duration={1.5} />}
              />
              <StatisticsSquare
                title={t('total_contribution_count')}
                value={<CountUp isCounting end={generalData.company.contributionCount} duration={1.5} />}
              />
            </View>
          </>
        )}
        <View style={styles.chartContainer}>
          <AppText style={styles.subtitle} mode={Mode.BOLD}>
            {t('news_count')}
          </AppText>
          <NewsCountStatistics years={years} services={services || []} siteGeos={siteGeos || []} />
        </View>
        <View style={styles.chartContainer}>
          <AppText style={styles.subtitle} mode={Mode.BOLD}>
            {t('contribution_count')}
          </AppText>
          <ContributionCountStatistics years={years} services={services || []} siteGeos={siteGeos || []} />
        </View>
        {generalData &&
          generalData.topNews.topSeen &&
          generalData.topNews.topInterest &&
          generalData.topNews.topCommented && (
            <>
              <AppText style={styles.subtitle} mode={Mode.BOLD}>
                {t('trendy_news')}
              </AppText>
              <ResponsiveFlatList
                style={styles.newsContainer}
                data={[
                  { title: t('most_seen'), subtitle: null, news: generalData.topNews.topSeen },
                  { title: t('most_interesting'), subtitle: null, news: generalData.topNews.topInterest },
                  {
                    title: t('most_commented'),
                    subtitle: t('comment_count', { count: generalData.topNews.topCommentedCount || 0 }),
                    news: generalData.topNews.topCommented,
                  },
                ]}
                renderItem={({ item }) => {
                  return (
                    <TouchableOpacity onPress={onPressNews(item.news)}>
                      <ContributionItemRowCompanyNews
                        item={item.news}
                        navigation={navigation}
                        isAdminView={false}
                        onPress={onPressNews(item.news)}
                        style={{ width: '100%' }}
                      />
                      <Chip text={item.title} subtext={item.subtitle} color={PRIMARY_COLOR} style={styles.newsChip} />
                    </TouchableOpacity>
                  );
                }}
              />
            </>
          )}
      </ScrollView>
    </AppScreen>
  );
}

const styles = StyleSheet.create({
  generalContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  subtitle: {
    color: PRIMARY_COLOR,
  },
  chartContainer: {
    marginVertical: 16,
  },
  newsContainer: {
    marginTop: 16,
  },
  newsChip: {
    position: 'absolute',
    top: 12,
    left: 16,
    padding: 10,
    minHeight: 36,
  },
});
