import React from 'react';
import { SwitchProps } from 'react-native';
import { useField } from 'formik';
import AppSwitch from '../AppSwitch';

export type SwitchFieldProps = Omit<Omit<SwitchProps, 'value'>, 'onValueChange'> & {
  name: string;
};

export default function SwitchField({ name, ...rest }: SwitchFieldProps) {
  const [field, , helper] = useField(name);

  return <AppSwitch onValueChange={helper.setValue} value={!!field.value} style={{ marginRight: 28 }} />;
}
