import React, { useEffect } from 'react';
import { LinearGradient } from 'expo-linear-gradient';
import { DARK_GRAY, PRIMARY_COLOR, WHITE } from '../../styles/appColor';
import { StyleSheet, View } from 'react-native';
import { useQuery } from '@tanstack/react-query';
import { validateTVParameters } from '../../services/url.utils';
import { ScreenNames } from '../../ScreenNames';
import { getScreenDetails } from '../../services/api/tv.api';
import { AppLoader } from '../../components/atomic/AppLoader';
import { NavigationProp, ParamListBase, RouteProp } from '@react-navigation/native';
import useTodayDateTime from '../../hooks/useTodayDateTime';
import AppText from '../../components/atomic/AppText';
import Weather from '../../components/atomic/Weather';
import AppQrCode from '../../components/atomic/AppQrCode';
import ColoredCard from '../../components/atomic/ColoredCard';
import RssFeed from '../../components/molecules/RssFeed';
import TVScreenNewsFeed from '../../components/molecules/TVScreenNewsFeed';
import { vh } from '../../utils/Utils';
import TVScreenContributionFeed from '../../components/molecules/TVScreenContributionFeed';
import { isValidColor, lightenDarkenColor } from '../../utils/Color';
import { getDocumentURI } from '../../services/api/helper.api';
import { useScreenOrientation } from '../../hooks/useScreenOrientation';
import FullScreenButtonOverlay from '../../components/atomic/FullScreenButtonOverlay';

declare var document: any;

export type TVInternScreenProps = {
  route: RouteProp<any, any>;
  navigation: NavigationProp<ParamListBase>;
};

export default function TVInternScreen({ route, navigation }: TVInternScreenProps) {
  const code = route.params?.code;
  const [paused, setPaused] = React.useState(false);
  const orientation = useScreenOrientation();

  // Redirect to main screen if code is invalid
  useEffect(() => {
    if (!validateTVParameters(code)) {
      navigation.navigate(ScreenNames.Main);
    }
  }, [code, navigation]);

  // Every seconds, check if CRISP is loaded and hide it
  useEffect(() => {
    const interval = setInterval(() => {
      const crispElems = document.getElementsByClassName('crisp-client');
      if (crispElems.length > 0) {
        clearInterval(interval);
        crispElems[0].style.display = 'none';
      }
    }, 1000);

    return () => {
      clearInterval(interval);
      const crispElems = document.getElementsByClassName('crisp-client');
      if (crispElems.length > 0) {
        crispElems[0].style.display = 'block';
      }
    };
  }, []);

  const todayDateTime = useTodayDateTime();
  const { data: screenDetails, isLoading: isLoadingDetails } = useQuery(['screenDetails', code], () =>
    getScreenDetails(code)
  );
  const isLoading = isLoadingDetails;

  const backgroundColor = (
    isValidColor(screenDetails?.backgroundColor) ? screenDetails?.backgroundColor : PRIMARY_COLOR
  ) as string;
  const backgroundSecondaryColor = lightenDarkenColor(backgroundColor, 30);

  if (isLoading || screenDetails === undefined) {
    return <AppLoader />;
  } else {
    return (
      <LinearGradient
        colors={[backgroundColor || PRIMARY_COLOR, backgroundSecondaryColor || WHITE]}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 1 }}
        style={styles.container}
      >
        <View style={styles.header}>
          <View style={styles.dateTimeContainer}>
            <AppText style={styles.timeText}>{todayDateTime.time}</AppText>
            {orientation === 'landscape' && (
              <View style={styles.dateContainer}>
                <AppText style={styles.dateText}>{todayDateTime.shortDate}</AppText>
                <AppText style={styles.dateText}>{todayDateTime.ephemerisOfTheDay}</AppText>
              </View>
            )}
          </View>
          {screenDetails?.logoId && (
            <View style={styles.logoContainer}>
              <View style={styles.logoCard}>
                <img src={getDocumentURI(screenDetails.logoId)} style={styles.logo} />
              </View>
            </View>
          )}
          {orientation === 'landscape' && (
            <View style={styles.logoContainer}>
              <View style={styles.logoCard}>
                <img src={require('../../../assets/konecteam-logo.svg')} style={styles.logo} />
              </View>
            </View>
          )}
          <View style={styles.weatherContainer}>
            {screenDetails?.latitude && screenDetails?.longitude && (
              <Weather latitude={screenDetails.latitude} longitude={screenDetails.longitude} />
            )}
          </View>
        </View>
        <View style={orientation === 'landscape' ? styles.columnContainer : styles.verticalColumnContainer}>
          <View style={styles.column}>
            <TVScreenContributionFeed code={code} paused={paused} />
          </View>
          <View style={styles.column}>
            <TVScreenNewsFeed code={code} paused={paused} setPaused={setPaused} />
          </View>
        </View>
        <View style={styles.footer}>
          {screenDetails?.rssFeed && (
            <ColoredCard style={styles.rssContainer} color="#778899">
              <RssFeed rssUrl={screenDetails.rssFeed} paused={paused} />
            </ColoredCard>
          )}
          <AppQrCode />
        </View>
        {/*<FullScreenButtonOverlay />*/}
      </LinearGradient>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  columnContainer: {
    flex: 1,
    flexDirection: 'row',
    zIndex: 1,
    paddingHorizontal: 5,
  },
  verticalColumnContainer: {
    flex: 1,
    flexDirection: 'column-reverse',
    zIndex: 1,
    paddingHorizontal: 5,
  },
  header: {
    height: vh(8),
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: vh(1),
    flexDirection: 'row',
    color: DARK_GRAY,
    marginTop: vh(1),
  },
  dateTimeContainer: {
    flexDirection: 'row',
    paddingTop: vh(0.25),
  },
  timeText: {
    fontSize: vh(6),
  },
  dateText: {
    fontSize: vh(2.5),
  },
  dateContainer: {
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    marginBottom: vh(2),
    marginLeft: vh(2),
  },
  logoContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  logoCard: {
    height: '100%',
    backgroundColor: WHITE,
    borderRadius: vh(1),
    padding: vh(1),
    shadowColor: DARK_GRAY,
    shadowOpacity: 0.5,
    shadowRadius: 5,
    shadowOffset: { width: 0, height: 0 },
    opacity: 0.8,
  },
  logo: {
    height: '100%',
  },
  weatherContainer: {
    justifyContent: 'flex-end',
  },
  column: {
    flex: 1,
    margin: vh(1),
    flexDirection: 'column',
  },
  rssContainer: {
    flex: 1,
    marginRight: vh(2),
  },
  footer: {
    flexDirection: 'row',
    margin: vh(2),
  },
});
