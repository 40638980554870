import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getUserProfile, updateUserProfile } from '../../services/api/user.api';
import { setLoading } from '../../store/action';
import { getPictureURI } from '../../services/api/helper.api';
import { alertInfo } from '../../services/utils';
import { t } from '../../services/translations';
import Form from '../atomic/formik/Form';
import UserFormFields, { UserFormFieldsProps, schema } from './UserFormFields';
import useLoggedUser from '../../hooks/useLoggedUser';

export type EditUserFormProps = {
  userId: number;
  onSubmitEnd?: (values: any) => Promise<void>;
} & UserFormFieldsProps;

export function EditUserForm({ userId, onSubmitEnd, ...rest }: EditUserFormProps) {
  const user = useLoggedUser();
  const token = user?.token;
  const dispatch = useDispatch();

  const {
    data,
    isLoading: isLoadingDetails,
    isFetched,
    isError: isErrorDetails,
  } = useQuery(['user', userId], () => getUserProfile(userId, token!), { cacheTime: 0 });

  const mutation = useMutation({
    mutationFn: async (values: any) => {
      try {
        await updateUserProfile(userId, token, values, values.photo);
        if (onSubmitEnd) {
          await onSubmitEnd(values);
        }
      } catch (err: any) {
        console.error(err, err?.response?.data);
        throw err;
      }
    },
  });

  const isLoading = isLoadingDetails || mutation.isLoading;
  const isError = isErrorDetails || mutation.isError;

  // Display big loading indicator if refreshing
  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [isLoading, dispatch]);

  const initialValues = useMemo(
    () => ({
      email: data?.aspNetUsers.email || '',
      firstName: data?.prenom || '',
      lastName: data?.nom || '',
      service: data?.entrepriseServiceId,
      geoSite: data?.entrepriseSiteGeoId,
      group: data?.entrepriseGroups[0]?.entrepriseGroupId,
      jobTitle: data?.fonction || '',
      landlineNumber: data?.telephone || '',
      mobileNumber: data?.portable || '',
      visibleZone: data?.visibleZone || false,
      languageCode: data?.languageCode || 'fr-FR',
      photo: data?.fichierIdProfil ? getPictureURI(data.fichierIdProfil) : null,
      hideEmail: data?.hideEmail,
      hidePhone: data?.hidePhone,
    }),
    [data]
  );

  // Display error message if needed
  useEffect(() => {
    if (isError) {
      alertInfo(t('profile_failed_to_update'));
    }
  }, [isError]);

  return isFetched ? (
    <Form
      onSubmit={mutation.mutateAsync as any}
      initialValues={initialValues}
      validationSchema={schema}
      validateOnMount
    >
      <UserFormFields {...rest} />
    </Form>
  ) : null;
}
