import { User } from '../entities/User';
import { ScreenNames } from '../ScreenNames';
import { navigationRef } from '../services/utils';

export const navigateToMessaging = () => {
  navigationRef.current?.navigate(ScreenNames.Messaging);
};

export const navigateToMessagingCreateThread = () => {
  navigationRef.current?.navigate(ScreenNames.Messaging, { screen: ScreenNames.MessagingCreateThread });
};

export const navigateToMessagingNewThread = (user: User) => {
  navigateToMessaging();
  navigationRef.current?.navigate(ScreenNames.Messaging, {
    screen: ScreenNames.MessagingThread,
    params: {
      contactId: user.id,
      threadName: `${user.prenom} ${user.nom}`,
    },
  });
};

export const navigateToMessagingThread = (thread: {
  id: number;
  contactLastName: string;
  contactFirstName: string;
}) => {
  navigationRef.current?.navigate(ScreenNames.Messaging, {
    screen: ScreenNames.MessagingThread,
    params: {
      threadId: thread.id,
      threadName: `${thread.contactFirstName} ${thread.contactLastName}`,
    },
  });
};
