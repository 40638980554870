import axios from 'axios';
import { apiUrlBase } from '../../../configuration';
import { headers } from './base';
import { addPhotoToFormData } from './formData.api';
import { getIdFromCode } from '../../languages/language_mapping';
import { User } from '../../entities/User';
import { UserSaved } from '../storage';
import { AspNetUsers } from '../../entities/AspNetUsers';
import { REMOVE_ACCOUNT_ENABLED } from '../features';
import { ErrorReason } from './login';
import { objectToFormData } from '../formUtil';

export async function getMe(token: string): Promise<User> {
  return axios
    .get(`${apiUrlBase}Users/me`, {
      headers: headers(token),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function searchUsers(user: UserSaved, query: string): Promise<User[]> {
  if (query.length === 0) {
    return Promise.resolve([]);
  }

  return axios
    .get(`${apiUrlBase}Users/search/${encodeURIComponent(query)}`, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function getCompanyUsers(user: UserSaved) {
  return getCompanyUsersFromCompanyId(user.entreprise?.id!, user?.token!);
}

export async function getCompanyUsersFromCompanyId(companyId: string | number, token: string): Promise<User[]> {
  return axios
    .get(apiUrlBase + 'Users/entreprise/' + companyId, {
      headers: headers(token),
    })
    .then((response) => response.data);
}

export async function getUsersByIds(user: UserSaved, ids: number[]): Promise<User[]> {
  return axios
    .get(`${apiUrlBase}Users/many/${ids.join(',')}`, {
      headers: headers(user.token),
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}

export async function removeUser(user: UserSaved, aspNetUsers: AspNetUsers) {
  return axios.delete(apiUrlBase + 'AspNetUsers', {
    headers: headers(user.token),
    data: {
      id: aspNetUsers.id,
      userName: aspNetUsers.userName,
      email: aspNetUsers.email,
    },
  });
}

export async function getUserProfile(userId: number | string, token: string): Promise<User> {
  return axios
    .get(`${apiUrlBase}Users/${userId}`, {
      headers: headers(token),
    })
    .then((r) => r.data);
}

export async function createUserProfile(token: string, userData, photo) {
  let formData = new FormData();
  let newUserData = {
    prenom: userData.firstName,
    nom: userData.lastName,
    serviceid: userData.service + '',
    sitegeoid: userData.geoSite + '',
    fonction: userData.jobTitle === '' ? JSON.stringify(null) : userData.jobTitle,
    telephone:
      userData.landlineNumber === '' || !userData.landlineNumber ? JSON.stringify(null) : userData.landlineNumber,
    portable: userData.mobileNumber === '' || !userData.mobileNumber ? JSON.stringify(null) : userData.mobileNumber,
    visibleZone: userData.visibleZone,
    email: userData.email,
    langueId: getIdFromCode(userData.language),
    dateSend: userData.dateSend,
    languageCode: userData.languageCode,
  };
  Object.keys(newUserData).forEach((key) => {
    formData.append(key, newUserData[key]);
  });

  if (photo !== null) {
    formData.append('files', photo);
  }

  return axios
    .put(apiUrlBase + 'Contributions/user/create/', formData, {
      headers: headers(token, 'multipart/form-data'),
    })
    .then((response) => {
      return response.data.json;
    })
    .catch((error) => {
      if (error.response.status == 400 && error.response.data.code === 'email_already_registered') {
        throw Error(ErrorReason.EMAIL_ALREADY_REGISTERED);
      }
      throw error;
    });
}

export async function updateUserProfile(userId: number | string, token: string, userData, photo) {
  let newUserData = objectToFormData({
    userId: Number(userId),
    prenom: userData.firstName,
    nom: userData.lastName,
    email: userData.email,
    serviceid: userData.service + '',
    sitegeoid: userData.geoSite + '',
    fonction: userData.jobTitle === '' ? JSON.stringify(null) : userData.jobTitle,
    telephone:
      userData.landlineNumber === '' || !userData.landlineNumber ? JSON.stringify(null) : userData.landlineNumber,
    portable: userData.mobileNumber === '' || !userData.mobileNumber ? JSON.stringify(null) : userData.mobileNumber,
    visibleZone: userData.visibleZone,
    langueId: getIdFromCode(userData.language),
    hideEmail: userData.hideEmail,
    hidePhone: userData.hidePhone,
    hasAcceptedCGU: userData.hasAcceptedCGU,
    languageCode: userData.languageCode,
  });

  if (photo !== null && photo.fileName !== null) {
    addPhotoToFormData(photo, newUserData);
  }

  return axios
    .put(
      `${apiUrlBase}Contributions/user/update/${userId}`,

      newUserData,
      {
        headers: headers(token, 'multipart/form-data'),
      }
    )
    .then((response) => response.data.json);
}

export async function putNewPassword(user: UserSaved, previousPassword: string, password: string) {
  return axios.post(
    apiUrlBase + 'Auth/updatePassword/' + user.aspNetUsers.id,
    JSON.stringify({
      // email: user.aspNetUsers.email,
      currentPassword: previousPassword,
      newPassword: password,
    }),
    {
      headers: headers(user.token),
    }
  );
}

export async function acceptCGU(user: UserSaved) {
  return axios.post(
    apiUrlBase + 'users/acceptCGU/' + user.id,
    {},
    {
      headers: headers(user.token),
    }
  );
}

export const removeAccount = (user: UserSaved, token: string) => {
  if (!REMOVE_ACCOUNT_ENABLED) return Promise.resolve();
  return axios.delete(apiUrlBase + 'Users/' + user.id, {
    headers: headers(token),
  });
};

export function getUsersExportCsvUrl(user: UserSaved) {
  return `${apiUrlBase}Users/csv?access_token=${user.token}`;
}
