import React, { useEffect, useMemo, useState } from 'react';
import * as Sharing from 'expo-sharing';
import * as FileSystem from 'expo-file-system';
import { AppScreen } from '../../components/containers/AppScreen';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';
import { RootState } from '../../services/storage';
import { FlatList, RefreshControl, StyleSheet, TouchableOpacity, View } from 'react-native';
import { ProfileRow } from '../../components/atomic/ProfileRow';
import { t } from '../../services/translations';
import { setContributeurListNeedsRefresh, setDirectoryListNeedsRefresh, setLoading } from '../../store/action';
import { SearchTextInput } from '../../components/atomic/SearchTextInput';
import { getCompanyUsers, getUsersExportCsvUrl } from '../../services/api/user.api';
import { User } from '../../entities/User';
import { DataTableHeader } from '../atomic/DataTableHeader';
import { isMobile } from '../../services/utils';
import { PRIMARY_COLOR } from '../../styles/appColor';
import AppText from '../atomic/AppText';
import { Chip } from '../atomic/Chip';
import { useQuery } from '@tanstack/react-query';
import { getCompanyGeoSites, getCompanyServices } from '../../services/api/company.api';
import { contributionColors } from '../../services/api/contributions';
import HorizontalFlatList from '../containers/HorizontalList';
import useLoggedUser from '../../hooks/useLoggedUser';
import FontAwesome6 from 'react-native-vector-icons/FontAwesome6';

const colorList = Object.values(contributionColors);

export type UsersListProps = {
  allowEdit?: boolean;
  onPress?: (user: User) => void;
  showAdminTag: boolean;
};

export function UsersList({ allowEdit, onPress, showAdminTag }: UsersListProps) {
  const [usersList, setUsersList] = useState<User[]>([]);
  const [refreshing, setRefreshing] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [filterServices, setFilterService] = useState<number[]>([]);
  const [filterSiteGeos, setFilterSiteGeos] = useState<number[]>([]);
  const [sortBy, setSortBy] = useState<null | { key: keyof User; order: 'asc' | 'desc' }>(null);
  const user = useLoggedUser();
  const directoryListNeedsRefresh = useSelector<RootState>((state) => state.directoryListNeedsRefresh);
  const contributeursListNeedsRefresh = useSelector<RootState>((state) => state.contributeursListNeedsRefresh);
  const companyId = user?.entrepriseId;
  const dispatch = useDispatch();

  const { data: companyServices } = useQuery(['company-service', companyId], () =>
    getCompanyServices(companyId, user.token)
  );
  const { data: companySiteGeos } = useQuery(['company-site-geos', companyId], () =>
    getCompanyGeoSites(companyId, user.token)
  );

  useEffect(() => {
    onRefresh();
  }, [directoryListNeedsRefresh]);

  useEffect(() => {
    dispatch(setLoading(refreshing));
  }, [refreshing, dispatch]);

  function onRefresh() {
    setRefreshing(true);
    getCompanyUsers(user).then((json) => {
      setUsersList(json);
      setRefreshing(false);
      dispatch(setDirectoryListNeedsRefresh(false));
    });
  }

  // Refresh after an update
  useEffect(() => {
    if (contributeursListNeedsRefresh) {
      onRefresh();
      dispatch(setContributeurListNeedsRefresh(false));
    }
  }, [contributeursListNeedsRefresh, onRefresh]);

  async function exportToCsv() {
    let fileUrl = getUsersExportCsvUrl(user);

    if (!isMobile()) {
      window.open(fileUrl, '_blank');
    } else {
      let uri = FileSystem.documentDirectory + 'export.csv';
      await FileSystem.downloadAsync(fileUrl, uri);
      await Sharing.shareAsync(uri, { dialogTitle: 'CSV' });
    }
  }

  const filteredData = useMemo(() => {
    let data = usersList;
    data = data.filter((u) => {
      return (
        (u.prenom && u.prenom.toLowerCase().includes(filterText.toLowerCase())) ||
        (u.nom && u.nom.toLowerCase().includes(filterText.toLowerCase()))
      );
    });

    if (filterServices.length > 0) {
      data = data.filter((u) => filterServices.includes(u.entrepriseServiceId));
    }

    if (filterSiteGeos.length > 0) {
      data = data.filter((u) => filterSiteGeos.includes(u.entrepriseSiteGeoId));
    }

    if (sortBy) {
      data = _.sortBy(data, (item) => item[sortBy.key]);

      if (sortBy.order === 'asc') {
        data.reverse();
      }
    }

    return data;
  }, [usersList, filterText, filterServices, filterSiteGeos, sortBy]);

  return (
    <AppScreen
      header={
        <View>
          <View style={styles.headerContainer}>
            <SearchTextInput style={styles.searchInput} onChangeText={setFilterText} defaultValue={filterText} />
            {allowEdit && (
              <TouchableOpacity
                style={{ alignItems: 'center', marginLeft: 24, marginBottom: 14 }}
                onPress={exportToCsv}
              >
                <FontAwesome6 size={24} name="file-csv" color={PRIMARY_COLOR} />
                <AppText style={{ fontSize: 10 }}>{t('export')}</AppText>
              </TouchableOpacity>
            )}
          </View>
          <HorizontalFlatList
            data={companyServices || []}
            keyExtractor={(item) => item.id.toString()}
            renderItem={({ item, index }) => (
              <Chip
                key={item.id}
                text={item.libelle}
                color={colorList[index % colorList.length]}
                style={{ marginLeft: 8 }}
                onPress={() => {
                  if (filterServices.includes(item.id)) {
                    setFilterService(filterServices.filter((id) => id !== item.id));
                  } else {
                    setFilterService([...filterServices, item.id]);
                  }
                }}
                selected={filterServices.includes(item.id)}
              />
            )}
          />
          <HorizontalFlatList
            data={companySiteGeos || []}
            keyExtractor={(item) => item.id.toString()}
            renderItem={({ item, index }) => (
              <Chip
                key={item.id}
                text={item.geoSite}
                color={colorList[index % colorList.length]}
                style={{ marginLeft: 8 }}
                onPress={() => {
                  if (filterSiteGeos.includes(item.id)) {
                    setFilterSiteGeos(filterSiteGeos.filter((id) => id !== item.id));
                  } else {
                    setFilterSiteGeos([...filterSiteGeos, item.id]);
                  }
                }}
                selected={filterSiteGeos.includes(item.id)}
              />
            )}
          />
          <View style={styles.headerContainer}>
            <DataTableHeader<User> style={styles.userNameCountContainer}>
              {allowEdit && <FontAwesome6 name="user" size={18} style={styles.userIcon} />}
              {t('users_count', { count: filteredData.length })}
            </DataTableHeader>
            {allowEdit && (
              <DataTableHeader<User>
                sortKey="dateInscription"
                containerStyle={styles.dateContainer}
                sortBy={sortBy}
                setSortBy={setSortBy}
              >
                {t('register_date')}
              </DataTableHeader>
            )}
          </View>
        </View>
      }
    >
      <FlatList
        style={styles.list}
        data={filteredData}
        renderItem={(u) => (
          <ProfileRow
            showAdminTag={showAdminTag}
            user={u.item}
            allowEdit={allowEdit}
            onPress={onPress && (() => onPress(u.item))}
          />
        )}
        keyExtractor={(item) => item.id.toString()}
        refreshControl={<RefreshControl refreshing={refreshing} onRefresh={onRefresh} />}
      />
    </AppScreen>
  );
}

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  searchInput: { flex: 1, height: 60 },
  filterInput: {
    borderBottomWidth: 0,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    marginTop: 0,
  },
  filterContainer: {
    marginLeft: 5,
    width: 110,
    height: 60,
    justifyContent: 'center',
  },
  dateContainer: {
    flex: 1,
    alignItems: 'center',
    marginRight: 50,
  },
  userNameCountContainer: { width: 250 },
  userIcon: { paddingRight: 10 },
  list: {
    marginTop: 10,
  },
});
