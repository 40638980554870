import { useEffect } from 'react';
import { logout } from '../../services/api/login';
import { useDispatch } from 'react-redux';
import { ScreenNames } from '../../ScreenNames';
import useLoggedUser from '../../hooks/useLoggedUser';
import { useNavigation } from '@react-navigation/native';

export function LogoutTrigger() {
  const dispatch = useDispatch();
  const user = useLoggedUser();
  const navigation = useNavigation();

  useEffect(() => {
    logout(user.token, dispatch).then(() => {
      navigation.navigate(ScreenNames.Main);
    });
  }, []);
  return null;
}
