import React, { useCallback, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { match } from 'ts-pattern';
import { ContributionEntrepriseGroup } from '../../entities/ContributionEntrepriseGroup';
import { MActuEntrepriseService } from '../../entities/MActuEntrepriseService';
import { MActuEntrepriseSiteGeo } from '../../entities/MActuEntrepriseSiteGeo';
import { contributionColors } from '../../services/api/contributions';
import { t } from '../../services/translations';
import { SpreadingType } from '../../types';
import HorizontalFlatList from '../containers/HorizontalList';
import { Chip } from './Chip';

const colorList = Object.values(contributionColors);

export type SpreadingChipListProps = {
  item: {
    id: number;
    spreadingType: SpreadingType;
    entrepriseServices: MActuEntrepriseService[];
    entrepriseSiteGeos: MActuEntrepriseSiteGeo[];
    entrepriseGroups: ContributionEntrepriseGroup[];
  };
  monocolor?: boolean;
  inline?: boolean;
};

export function SpreadingChipListContrib({ item, monocolor, inline }: SpreadingChipListProps) {
  const spreadingItems = useMemo(
    () =>
      match(item)
        .with({ spreadingType: 0 }, () => [t('company_wide')])
        .with({ spreadingType: 1 }, () => [t('some_users')])
        .with({ spreadingType: 2 }, () => item.entrepriseServices.map((e) => e.entrepriseService.libelle))
        .with({ spreadingType: 3 }, () => item.entrepriseSiteGeos.map((e) => e.entrepriseSiteGeo.siteGeo))
        .with({ spreadingType: 4 }, () => [
          ...item.entrepriseServices.map((e) => e.entrepriseService.libelle),
          ...item.entrepriseSiteGeos.map((e) => e.entrepriseSiteGeo.siteGeo),
        ])
        .with({ spreadingType: 5 }, () => item.entrepriseGroups.map((e) => e.entrepriseGroup.label))
        .otherwise(() => [t('company_wide')]),
    [item]
  );

  const getLabelColor = useCallback((label: string) => {
    if (label.length == 0) {
      return colorList[0];
    }

    let hash = 0;
    for (let i = 0; i < label.length; i++) {
      const char = label.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash;
    }

    return colorList[Math.abs(hash) % colorList.length];
  }, []);

  return inline != true ? (
    <View>
      <HorizontalFlatList
        data={spreadingItems}
        keyExtractor={(item) => item}
        renderItem={({ item }) => (
          <Chip text={item} style={styles.chip} color={monocolor ? colorList[0] : getLabelColor(item)} />
        )}
      />
    </View>
  ) : (
    spreadingItems.map((item) => (
      <Chip small={true} text={item} style={styles.chip} color={monocolor ? colorList[0] : getLabelColor(item)} />
    ))
  );
}

export default function SpreadingChipList({ item, monocolor, inline }: SpreadingChipListProps) {
  const spreadingItems = useMemo(
    () =>
      match(item)
        .with({ spreadingType: SpreadingType.ENTREPRISE }, () => [t('company_wide')])
        .with({ spreadingType: SpreadingType.USERS }, () => [t('some_users')])
        .with({ spreadingType: SpreadingType.SERVICES }, () =>
          item.entrepriseServices.map((e) => e.entrepriseService.libelle)
        )
        .with({ spreadingType: SpreadingType.SITES }, () =>
          item.entrepriseSiteGeos.map((e) => e.entrepriseSiteGeo.siteGeo)
        )
        .with({ spreadingType: SpreadingType.SITES_AND_SERVICES }, () => [
          ...item.entrepriseServices.map((e) => e.entrepriseService.libelle),
          ...item.entrepriseSiteGeos.map((e) => e.entrepriseSiteGeo.siteGeo),
        ])
        .with({ spreadingType: SpreadingType.GROUPS }, () => item.entrepriseGroups.map((e) => e.entrepriseGroup.label))
        .otherwise(() => [t('company_wide')]),
    [item]
  );

  const getLabelColor = useCallback((label: string) => {
    if (label.length == 0) {
      return colorList[0];
    }

    let hash = 0;
    for (let i = 0; i < label.length; i++) {
      const char = label.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash;
    }

    return colorList[Math.abs(hash) % colorList.length];
  }, []);

  return (
    <>
      {inline != true ? (
        <View>
          <HorizontalFlatList
            data={spreadingItems}
            keyExtractor={(item) => item}
            renderItem={({ item }) => (
              <Chip text={item} style={styles.chip} color={monocolor ? colorList[0] : getLabelColor(item)} />
            )}
          />
        </View>
      ) : (
        spreadingItems.map((item) => (
          <Chip small={true} text={item} style={styles.chip} color={monocolor ? colorList[0] : getLabelColor(item)} />
        ))
      )}
    </>
  );
}

const styles = StyleSheet.create({
  chip: {
    margin: 5,
  },
});
