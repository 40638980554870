import { ActivityIndicator, ScrollView, StyleSheet, View } from 'react-native';
import * as Yup from 'yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { ListItem } from '@rneui/themed';
import { t } from '../../../services/translations';
import { AppScreen } from '../../../components/containers/AppScreen';
import { PRIMARY_COLOR, WHITE } from '../../../styles/appColor';
import Form from '../../../components/atomic/formik/Form';
import { listSiteGeoScreenSettings, updateSiteGeoScreenSettings } from '../../../services/api/tv.api';
import useLoggedUser from '../../../hooks/useLoggedUser';
import {
  UpdateSiteGeoScreenSettings,
  UpdateSiteGeoScreenSettingsElem,
} from '../../../entities/UpdateSiteGeoScreenSettings';
import { setLoading } from '../../../store/action';
import { alertInfo, openURL } from '../../../services/utils';
import SubmitButton from '../../../components/atomic/formik/SubmitButton';
import TextField from '../../../components/atomic/formik/TextField';
import { useState } from 'react';
import { HypertextLink } from '../../../components/atomic/HypertextLink';
import { generateTVInternLink } from '../../../services/url.utils';
import { FormField } from '../../../components/atomic/FormField';
import LocationField from '../../../components/atomic/formik/LocationField';
import { getCompanyGeoSites } from '../../../services/api/company.api';
import { lightenDarkenColor } from '../../../utils/Color';

export const schema = Yup.object({
  backgroundColor: Yup.string().required(t('field_required')),
  siteGeoScreenSettings: Yup.array().of(
    Yup.object({
      entrepriseSiteGeoId: Yup.number().required(t('field_required')),
      latitude: Yup.number().nullable(),
      longitude: Yup.number().nullable(),
      rssFeed: Yup.string().nullable(),
      facebookPageUrl: Yup.string().nullable(),
      linkedInPageUrl: Yup.string().nullable(),
    })
  ),
});

export default function EditSiteGeoScreenSettings() {
  const user = useLoggedUser();
  const dispatch = useDispatch();
  const {
    data: screenSettings,
    refetch,
    isLoading: isLoadingListScreenSettings,
  } = useQuery(['listSiteGeoScreenSettings'], () => listSiteGeoScreenSettings(user), { cacheTime: 0 });

  const { data: siteGeos, isLoading: isLoadingSiteGeos } = useQuery(
    ['companyGeoSites'],
    () => getCompanyGeoSites(user.entrepriseId, user.token),
    { cacheTime: 0 }
  );

  const [expandedId, setExpandedId] = useState<number | null>(null);

  const mutation = useMutation({
    mutationFn: async (values: UpdateSiteGeoScreenSettings) => {
      dispatch(setLoading(true));

      try {
        await updateSiteGeoScreenSettings(user, values);
        await refetch();
      } catch (err: any) {
        alertInfo(t('error_occurred'));
      } finally {
        dispatch(setLoading(false));
      }
    },
  });

  const initialValues = (screenSettings || {
    backgroundColor: '',
    siteGeoScreenSettings: [],
  }) as UpdateSiteGeoScreenSettings;

  siteGeos?.forEach((siteGeo) => {
    if (!initialValues.siteGeoScreenSettings.find((item) => item.entrepriseSiteGeoId === siteGeo.id)) {
      initialValues.siteGeoScreenSettings.push({
        entrepriseSiteGeoId: siteGeo.id,
        title: siteGeo.geoSite,
        latitude: null,
        longitude: null,
        rssFeed: null,
        facebookPageUrl: null,
        linkedInPageUrl: null,
      } as UpdateSiteGeoScreenSettingsElem);
    }
  });

  const isLoading = mutation.isLoading || isLoadingListScreenSettings || isLoadingSiteGeos;

  return (
    <ScrollView style={styles.container}>
      <AppScreen>
        {isLoading ? (
          <ActivityIndicator color={PRIMARY_COLOR} size="large" />
        ) : (
          <Form
            onSubmit={async (values) => await mutation.mutateAsync(values)}
            initialValues={initialValues}
            validationSchema={schema}
            validateOnMount
          >
            <TextField name="backgroundColor" mandatory required title={t('custom_color')} />
            {screenSettings?.siteGeoScreenSettings?.map((item, index) => (
              <ListItem.Accordion
                key={item.entrepriseSiteGeoId}
                isExpanded={item.entrepriseSiteGeoId === expandedId}
                onPress={() => {
                  if (item.entrepriseSiteGeoId === expandedId) {
                    setExpandedId(null);
                  } else {
                    setExpandedId(item.entrepriseSiteGeoId);
                  }
                }}
                content={
                  <ListItem.Content>
                    <ListItem.Title>{item.title}</ListItem.Title>
                  </ListItem.Content>
                }
              >
                <View style={styles.accordionContent}>
                  <FormField title={t('screen_url')}>
                    <HypertextLink
                      text={generateTVInternLink(item.code)}
                      onPress={() => {
                        openURL(generateTVInternLink(item.code));
                      }}
                    />
                  </FormField>
                  <LocationField
                    latitudeName={`siteGeoScreenSettings.${index}.latitude`}
                    longitudeName={`siteGeoScreenSettings.${index}.longitude`}
                    label={t('location')}
                  />
                  <TextField name={`siteGeoScreenSettings.${index}.rssFeed`} title={t('news_url')} />
                  <TextField name={`siteGeoScreenSettings.${index}.facebookPageUrl`} title={t('facebook_page_url')} />
                  <TextField name={`siteGeoScreenSettings.${index}.linkedInPageUrl`} title={t('linkedin_page_url')} />
                </View>
              </ListItem.Accordion>
            ))}
            <SubmitButton text={t('save')} />
          </Form>
        )}
      </AppScreen>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: WHITE,
  },
  accordionContent: {
    paddingHorizontal: 16,
    paddingVertical: 8,
  },
});
