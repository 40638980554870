import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { t } from '../../services/translations';
import { AppButton, Type } from '../atomic/AppButton';
import { FileErrorMessage, FilePicker } from '../atomic/FilePicker';
import { TextFormField } from '../atomic/FormField';

export type OrganizationalChartFormProps = {
  initialValues?: {
    label?: string;
    fileName?: string;
  };
  onSubmit: (args: { label: string; file: any }) => Promise<void>;
};

export function OrganizationalChartForm({ initialValues, onSubmit }: OrganizationalChartFormProps) {
  const [label, setLabel] = useState('');
  const [file, setFile] = useState(undefined);
  const [defaultFilename, setDefaultFilename] = useState<string | undefined>(undefined);
  const [fileError, setFileError] = useState<undefined | FileErrorMessage>(undefined);

  // Check form validity
  const isFormReady = useMemo(() => {
    let isFormReady = true;
    isFormReady = isFormReady && label.length > 0;
    isFormReady = isFormReady && (file !== undefined || defaultFilename !== undefined);
    isFormReady = isFormReady && fileError === undefined;

    return isFormReady;
  }, [label, file, fileError]);

  // Submit form
  const handleSubmit = useCallback(() => {
    return onSubmit({
      label,
      file,
    });
  }, [onSubmit, label, file]);

  // Update form datas
  useEffect(() => {
    if (initialValues?.label) {
      setLabel(initialValues.label);
    }

    if (initialValues?.fileName) {
      setDefaultFilename(initialValues.fileName);
    }
  }, [initialValues]);

  return (
    <KeyboardAwareScrollView keyboardShouldPersistTaps="handled" style={{ padding: 10 }}>
      <TextFormField mandatory onChangeText={setLabel} value={label} title={t('title') + '*'} />
      <FilePicker
        setFile={(file) => {
          setFile(file);
          setDefaultFilename(undefined);
        }}
        file={file}
        setError={setFileError}
        maxSizeMB={10}
        defaultFilename={defaultFilename}
        allowedExtensions={['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp']}
      />
      <View style={{ marginTop: 30, marginBottom: 100 }}>
        <AppButton title={t('publish_button')} type={Type.PRIMARY} onPress={handleSubmit} enabled={isFormReady} />
      </View>
    </KeyboardAwareScrollView>
  );
}
