import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { WHITE } from '../../styles/appColor';
import { vh } from '../../utils/Utils';
import WeatherIcon from './WeatherIcon';

export type WeatherVerticalCardProps = {
  hour: number;
  temperature: number;
  rain: boolean;
  showers: boolean;
  snowfall: boolean;
  cloudCover: number;
  isDay: boolean;
};

export default function WeatherVerticalCard({
  hour,
  temperature,
  rain,
  showers,
  snowfall,
  cloudCover,
  isDay,
}: WeatherVerticalCardProps) {
  return (
    <View style={styles.container}>
      <Text style={styles.text}>
        {hour}
        <Text style={styles.subText}>h</Text>
      </Text>
      <WeatherIcon
        rain={rain}
        showers={showers}
        snowfall={snowfall}
        cloudCover={cloudCover}
        isDay={isDay}
        color={WHITE}
        style={styles.icon}
      />
      <Text style={styles.text}>
        {temperature}
        <Text style={styles.subText}>°</Text>
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgba(0,0,0,0.15)',
    paddingVertical: vh(2),
    paddingHorizontal: vh(4),
    margin: vh(2),
  },
  text: {
    color: WHITE,
    fontSize: vh(4),
  },
  subText: {
    color: WHITE,
    fontSize: vh(2),
  },
  icon: {
    width: vh(6),
    height: vh(6),
    marginTop: 10,
  },
});
