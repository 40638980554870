import React, { useEffect, useMemo, useState } from 'react';
import { ImageBackground, StyleSheet, View } from 'react-native';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import AppText from '../atomic/AppText';
import { WHITE } from '../../styles/appColor';
import { useQuery } from '@tanstack/react-query';
import { getScreenNews } from '../../services/api/tv.api';
import { NewsSummary } from '../../entities/NewsSummary';
import { getContributionImageSource } from '../../services/api/helper.api';
import { ContributionType } from '../../types';
import { addAlpha, lightenDarkenColor } from '../../utils/Color';
import { LinearGradient } from 'expo-linear-gradient';
import { FONT_FAMILY_DEFAULT } from '../../styles/globalStyle';
import { getReadableDate } from '../../services/utils';
import TVScreenSpreadingChipsList from '../atomic/TVScreenSpreadingChipsList';
import { vh } from '../../utils/Utils';
import ProgressCircleOverlay from '../atomic/ProgressCircleOverlay';
import TVScreenYoutubeVideo from '../atomic/TVScreenYoutubeVideo';
import ColoredCard from '../atomic/ColoredCard';
import { ProfilePicture } from '../atomic/ProfilePicture';
import AutoScrollView from '../atomic/AutoScrollView';

export type TVScreenNewsFeedProps = {
  code: string;
  paused?: boolean;
  setPaused?: (paused: boolean) => void;
};

const VIDEO_PLAY_DELAY = 5200;

export default function TVScreenNewsFeed({ code, setPaused }: TVScreenNewsFeedProps) {
  const nodeRef = React.useRef(null);
  const videoPlayerNodeRef = React.useRef(null);
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const [playVideo, setPlayVideo] = useState(false);
  const [videoWillPlayProgress, setVideoWillPlayProgress] = useState(0);
  const { data: items } = useQuery<NewsSummary[]>(['screenNewsFeed', code], () => getScreenNews(code), {
    refetchInterval: 60 * 1000,
  });
  const currentItem = items?.[currentItemIndex];
  const baseColor = addAlpha('#5E0707', 0.6);
  const darkerColor = addAlpha(lightenDarkenColor('#5E0707', 30), 0.6);

  // Progress smoothly from 0 to 1 in 12 seconds
  useEffect(() => {
    if (currentItem?.videoUrl) {
      const interval = setInterval(() => {
        setVideoWillPlayProgress((progress) => {
          if (progress >= 1) {
            clearInterval(interval);
            setPaused?.(true);
            setPlayVideo(true);

            return 0;
          }

          return progress + 0.01;
        });
      }, VIDEO_PLAY_DELAY / 100);
      return () => clearInterval(interval);
    }
  }, [currentItem?.videoUrl, setPaused]);

  const handleVideoEnd = () => {
    setPlayVideo(false);
    setPaused?.(false);
    setCurrentItemIndex((currentItemIndex) => {
      if (currentItemIndex === (items || []).length - 1) {
        return 0;
      }
      return currentItemIndex + 1;
    });
  };

  const goToNextItem = () => {
    setCurrentItemIndex((currentItemIndex) => {
      if (currentItemIndex === (items || []).length - 1) {
        return 0;
      }

      return currentItemIndex + 1;
    });
  };

  const description = useMemo(() => {
    if (!currentItem) {
      return '';
    }

    // Remove all color: rgb(x, x, x); from the description
    currentItem.description = currentItem.description.replace(/color: rgb\(\d+, \d+, \d+\);/g, '');

    return currentItem.description;
  }, [currentItem]);

  if (!currentItem) {
    return <View />;
  }

  return (
    <ColoredCard style={styles.newsCard} color="#5E0707">
      <SwitchTransition mode="out-in">
        <CSSTransition<HTMLDivElement>
          key={currentItem.id}
          nodeRef={nodeRef}
          addEndListener={(done) => {
            (nodeRef.current as any)?.addEventListener('transitionend', done, false);
          }}
          classNames="fade"
        >
          <div ref={nodeRef} style={styles.container}>
            <ImageBackground
              ref={nodeRef}
              source={getContributionImageSource(currentItem.imageId || undefined, ContributionType.COMPANY_NEWS)}
              style={{ flex: 1, width: '100%', height: '100%' }}
              imageStyle={{ resizeMode: 'cover' }}
            >
              <ProgressCircleOverlay progress={videoWillPlayProgress} color={WHITE} display={!!currentItem.videoUrl}>
                <LinearGradient
                  style={styles.content}
                  colors={[baseColor, darkerColor]}
                  start={{ x: 0, y: 0 }}
                  end={{ x: 1, y: 1 }}
                >
                  <View style={styles.header}>
                    <TVScreenSpreadingChipsList item={currentItem} />
                    <AppText style={styles.headerText}>
                      {getReadableDate(
                        currentItem?.visibleDate ? currentItem?.visibleDate : currentItem?.creationDate,
                        true
                      )}
                    </AppText>
                  </View>
                  <AppText style={styles.title} numberOfLines={3}>
                    {currentItem?.title}
                  </AppText>
                  <View style={styles.descriptionContainer}>
                    <AutoScrollView
                      key={currentItem?.id}
                      style={styles.description}
                      paused={!!currentItem?.videoUrl}
                      onReachEnd={!currentItem?.videoUrl ? goToNextItem : undefined}
                      afterDelay={7500}
                      beforeDelay={7500}
                    >
                      <div style={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
                    </AutoScrollView>
                  </View>
                  <View style={styles.footer}>
                    <ProfilePicture pictureId={currentItem.profilePictureId} style={styles.authorIcon} size="small" />
                    <AppText numberOfLines={1} style={styles.authorName}>
                      {currentItem.authorFirstName} {currentItem.authorLastName}
                    </AppText>
                  </View>
                </LinearGradient>
              </ProgressCircleOverlay>
            </ImageBackground>
          </div>
        </CSSTransition>
      </SwitchTransition>
      <div
        ref={videoPlayerNodeRef}
        className={playVideo && currentItem.videoUrl ? 'grow-slow-enter grow-slow-enter-active' : 'grow-slow-enter'}
        style={styles.videoPlayerContainer}
      >
        {playVideo && currentItem.videoUrl && (
          <TVScreenYoutubeVideo youtubeUrl={currentItem.videoUrl} onVideoEnd={handleVideoEnd} />
        )}
      </div>
    </ColoredCard>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-between',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
  },
  newsCard: {
    padding: 0,
    flex: 1,
  },
  content: {
    flex: 1,
    padding: vh(2),
    width: '100%',
    height: '100%',
  },
  header: {
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  headerText: {
    fontSize: vh(2),
    color: WHITE,
  },
  title: {
    fontSize: vh(4),
    marginBottom: 20,
    color: WHITE,
    overflow: 'hidden',
  },
  descriptionContainer: {
    flex: 1,
  },
  description: {
    width: '100%',
    height: '100%',
    flex: 1,
    color: WHITE,
    fontSize: vh(2),
    lineHeight: vh(2.5),
    fontFamily: FONT_FAMILY_DEFAULT.regular,
    marginTop: vh(1),
    marginBottom: vh(1),
  },
  videoPlayerContainer: {
    position: 'fixed' as any,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 100,
    backgroundColor: 'black',
  },
  footer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  authorIcon: {
    marginRight: vh(1),
    width: vh(3),
    height: vh(3),
  },
  authorName: {
    fontSize: vh(2),
    color: WHITE,
  },
});
