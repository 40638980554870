import React, { useState } from 'react';
import { RefreshControl, FlatList, View, StyleSheet, Platform, AppState } from 'react-native';
import { MessagingThreadRow } from '../../components/atomic/MessagingThreadRow';
import { SearchTextInput } from '../../components/atomic/SearchTextInput';
import useMessagingThreads from '../../hooks/useMessagingThreads';
import { navigateToMessagingCreateThread } from '../../utils/Messaging';
import { FloatingButtons } from '../loggedIn/contribution/FloatingButtons';

export function MessagingMainScreen() {
  const [search, setSearch] = useState<string | undefined>();
  const { threads, isLoading, fetchNext, refresh } = useMessagingThreads(search);

  // Refresh when this screen is displayed by a notification on iOS
  React.useEffect(() => {
    if (Platform.OS === 'ios') {
      const subscription = AppState.addEventListener('change', (nextAppState) => {
        if (nextAppState === 'active') {
          refresh();
        }
      });

      return () => {
        subscription.remove();
      };
    }
  }, [refresh]);

  return (
    <View style={styles.container}>
      <SearchTextInput onChangeText={setSearch} value={search} style={styles.search} />
      <FlatList
        refreshControl={<RefreshControl refreshing={isLoading} onRefresh={refresh} />}
        onEndReached={fetchNext}
        data={threads}
        renderItem={({ item }) => <MessagingThreadRow messagingThread={item} />}
        keyExtractor={(item) => item.id.toString()}
      />
      <FloatingButtons onPress={navigateToMessagingCreateThread} />
    </View>
  );
}

const styles = StyleSheet.create({
  container: Platform.OS === 'web' ? {
    paddingTop: 16,
    backgroundColor: '#ffffff',
    flex: 1,
    width: '40vw'
  } :  {
    paddingTop: 16,
    backgroundColor: '#ffffff',
    flex: 1,
  },
  search: {
    marginHorizontal: 16,
  },
});
