import React, { ReactNode, useCallback, useMemo } from 'react';
import { View, StyleSheet, ViewProps, StyleProp, ViewStyle, LayoutChangeEvent } from 'react-native';
import 'setimmediate';
import Animated, { useAnimatedStyle, useSharedValue, withSpring, runOnJS } from 'react-native-reanimated';
import { GestureDetector, Gesture } from 'react-native-gesture-handler';

export type ZoomProps = {
  style?: StyleProp<ViewStyle>;
  contentContainerStyle?: StyleProp<ViewStyle>;
  children: ReactNode;
};

export default function Zoom({ style, contentContainerStyle, children }: ZoomProps) {
  const baseScale = useSharedValue(1);
  const pinchScale = useSharedValue(1);
  const lastScale = useSharedValue(1);
  const isZoomedIn = useSharedValue(false);
  const isPanGestureEnabled = useSharedValue(false);

  const containerDimensions = useSharedValue({ width: 0, height: 0 });
  const contentDimensions = useSharedValue({ width: 1, height: 1 });

  const translateX = useSharedValue(0);
  const translateY = useSharedValue(0);
  const lastOffsetX = useSharedValue(0);
  const lastOffsetY = useSharedValue(0);

  const getContentContainerSize = useCallback(() => {
    return {
      width: containerDimensions.value.width,
      height: (contentDimensions.value.height * containerDimensions.value.width) / contentDimensions.value.width,
    };
  }, []);

  const zoomIn = useCallback(() => {
    const { width, height } = getContentContainerSize();

    // TODO: MAKE SMARTER CHOISE BASED ON AVAILABLE FREE VERTICAL SPACE
    let newScale = width > height ? (width / height) * 0.8 : (height / width) * 0.8;
    if (newScale < 1.4) newScale = 1.4;
    else if (newScale > 1.5) newScale = 1.5;

    lastScale.value = newScale;

    baseScale.value = withSpring(newScale);
    pinchScale.value = withSpring(1);

    const newOffsetX = 0;
    lastOffsetX.value = newOffsetX;

    const newOffsetY = 0;
    lastOffsetY.value = newOffsetY;

    translateX.value = newOffsetX;
    translateY.value = newOffsetY;

    isZoomedIn.value = true;
    isPanGestureEnabled.value = true;
  }, [baseScale, pinchScale, lastOffsetX, lastOffsetY, translateX, translateY, isZoomedIn, lastScale]);

  const zoomOut = useCallback(() => {
    const newScale = 1;
    lastScale.value = newScale;
    baseScale.value = withSpring(newScale);
    pinchScale.value = withSpring(1);

    const newOffsetX = 0;
    lastOffsetX.value = newOffsetX;

    const newOffsetY = 0;
    lastOffsetY.value = newOffsetY;

    translateX.value = withSpring(newOffsetX);
    translateY.value = withSpring(newOffsetY);

    isZoomedIn.value = false;

    isPanGestureEnabled.value = false;
  }, [baseScale, pinchScale, lastOffsetX, lastOffsetY, translateX, translateY, lastScale, isZoomedIn]);

  const handlePanOutside = useCallback(() => {
    const { width, height } = getContentContainerSize();
    const maxOffset = {
      x:
        width * lastScale.value < containerDimensions.value.width
          ? 0
          : (width * lastScale.value - containerDimensions.value.width) / 2 / lastScale.value,
      y:
        height * lastScale.value < containerDimensions.value.height
          ? 0
          : (height * lastScale.value - containerDimensions.value.height) / 2 / lastScale.value,
    };

    const isPanedXOutside = lastOffsetX.value > maxOffset.x || lastOffsetX.value < -maxOffset.x;
    if (isPanedXOutside) {
      const newOffsetX = lastOffsetX.value >= 0 ? maxOffset.x : -maxOffset.x;
      lastOffsetX.value = newOffsetX;

      translateX.value = withSpring(newOffsetX);
    } else {
      translateX.value = lastOffsetX.value;
    }

    const isPanedYOutside = lastOffsetY.value > maxOffset.y || lastOffsetY.value < -maxOffset.y;
    if (isPanedYOutside) {
      const newOffsetY = lastOffsetY.value >= 0 ? maxOffset.y : -maxOffset.y;
      lastOffsetY.value = newOffsetY;

      translateY.value = withSpring(newOffsetY);
    } else {
      translateY.value = lastOffsetY.value;
    }
  }, [lastOffsetX, lastOffsetY, lastScale, translateX, translateY]);

  const onDoubleTap = useCallback(() => {
    if (isZoomedIn.value) zoomOut();
    else zoomIn();
  }, [zoomIn, zoomOut, isZoomedIn]);

  const onLayout = useCallback(
    ({
      nativeEvent: {
        layout: { width, height },
      },
    }: LayoutChangeEvent) => {
      containerDimensions.value = {
        width,
        height,
      };
    },
    []
  );

  const onLayoutContent = useCallback(
    ({
      nativeEvent: {
        layout: { width, height },
      },
    }: LayoutChangeEvent) => {
      contentDimensions.value = {
        width,
        height,
      };
    },
    []
  );

  const onPinchEnd = useCallback(
    (scale: number) => {
      const newScale = lastScale.value * scale;
      lastScale.value = newScale;
      if (newScale > 1) {
        isZoomedIn.value = true;
        baseScale.value = newScale;
        pinchScale.value = 1;

        handlePanOutside();
        isPanGestureEnabled.value = true;
      } else {
        zoomOut();
      }
    },
    [lastScale, baseScale, pinchScale, handlePanOutside, zoomOut, isZoomedIn]
  );

  const zoomGestures = useMemo(() => {
    const tapGesture = Gesture.Tap()
      .numberOfTaps(2)
      .onEnd(() => {
        runOnJS(onDoubleTap)();
      });

    const panGesture = Gesture.Pan()
      .onUpdate(({ translationX, translationY }) => {
        translateX.value = lastOffsetX.value + translationX / lastScale.value;
        translateY.value = lastOffsetY.value + translationY / lastScale.value;
      })
      .onEnd(({ translationX, translationY }) => {
        lastOffsetX.value += translationX / lastScale.value;
        lastOffsetY.value += translationY / lastScale.value;
        runOnJS(handlePanOutside)();
      })
      .onTouchesMove((e, state) => {
        if (isPanGestureEnabled.value) state.activate();
        else state.fail();
      })
      .minDistance(0)
      .minPointers(1)
      .maxPointers(2);

    const pinchGesture = Gesture.Pinch()
      .onUpdate(({ scale }) => {
        pinchScale.value = scale;
        isPanGestureEnabled.value = true;
      })
      .onEnd(({ scale }) => {
        pinchScale.value = scale;

        runOnJS(onPinchEnd)(scale);
      });

    return Gesture.Race(tapGesture, Gesture.Simultaneous(pinchGesture, panGesture));
  }, [
    handlePanOutside,
    lastOffsetX,
    lastOffsetY,
    onDoubleTap,
    onPinchEnd,
    isPanGestureEnabled,
    pinchScale,
    translateX,
    translateY,
    lastScale,
  ]);

  const animContentContainerStyle = useAnimatedStyle(() => ({
    transform: [
      { scale: baseScale.value * pinchScale.value },
      { translateX: translateX.value },
      { translateY: translateY.value },
    ],
  }));

  return (
    <GestureDetector gesture={zoomGestures}>
      <View style={[styles.container, style]} onLayout={onLayout} collapsable={false}>
        <Animated.View
          style={[animContentContainerStyle, contentContainerStyle, styles.contentContainer]}
          onLayout={onLayoutContent}
        >
          {children}
        </Animated.View>
      </View>
    </GestureDetector>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    overflow: 'hidden',
  },
  contentContainer: {
    flex: 1,
  },
});
