import React from 'react';
import { AppScreen } from '../../../components/containers/AppScreen';
import { ScrollView, TouchableOpacity, View } from 'react-native';
import AppText, { Mode } from '../../../components/atomic/AppText';
import { t } from '../../../services/translations';
import { globalStyle } from '../../../styles/globalStyle';
import { PRIMARY_COLOR } from '../../../styles/appColor';
import { ScreenNames } from '../../../ScreenNames';
import { Space1, Space2 } from '../../../styles/spaces';
import { DARK_GRAY } from '../../../styles/appColor';
import { hasLicenceExternalScreen, hasLicenceOrganizationalChart } from '../../../services/licences';
import { isMobile, openURL } from '../../../services/utils';
import useLoggedUser from '../../../hooks/useLoggedUser';
import FontAwesome6 from 'react-native-vector-icons/FontAwesome6';

function ItemRowScreenOpener(props: {
  disabled?: boolean;
  target: string;
  title: string;
  navigation: any;
  icon?: string;
}) {
  return <ItemRow onPress={() => props.navigation.navigate(props.target)} {...props} />;
}

function ItemRow(props: { disabled?: boolean; title: string; onPress: () => void; icon?: string }) {
  return (
    <TouchableOpacity
      disabled={props.disabled}
      onPress={props.onPress}
      style={[globalStyle.listRow, { opacity: props.disabled ? 0.5 : 1 }]}
    >
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        {props.icon && <FontAwesome6 size={18} name={props.icon} style={{ width: 40 }} />}
        <AppText
          mode={Mode.BOLD}
          style={{
            flex: 1,
          }}
        >
          {props.title}
        </AppText>

        <FontAwesome6 size={18} name="chevron-right" color={PRIMARY_COLOR} />
      </View>
    </TouchableOpacity>
  );
}

export function CompanyAdminMainScreen(props: { navigation: any }) {
  const user = useLoggedUser();
  const isAdmin = user.lovIdType === 13;

  return (
    <AppScreen>
      <ScrollView>
        <ItemRowScreenOpener
          title={t('statistics')}
          icon="chart-bar"
          navigation={props.navigation}
          target={ScreenNames.StatisticsAdministration}
        />
        <ItemRowScreenOpener
          title={t('company_news_administration')}
          icon="newspaper"
          navigation={props.navigation}
          target={ScreenNames.CompanyNewsAdministration}
        />
        {isAdmin && (
          <>
            <ItemRowScreenOpener
              title={t('categories_menu')}
              icon="clipboard-list"
              navigation={props.navigation}
              target={ScreenNames.CategoriesAdministration}
            />
            <ItemRowScreenOpener
              title={t('suggestions_administration')}
              icon="lightbulb"
              navigation={props.navigation}
              target={ScreenNames.SuggestionsAdministration}
            />
          </>
        )}
        <ItemRowScreenOpener
          title={t('users_administration')}
          icon="users"
          navigation={props.navigation}
          target={ScreenNames.DirectoryAdministration}
        />
        {isAdmin && (
          <>
            <ItemRowScreenOpener
              title={t('contributions_administration')}
              icon="thumbtack"
              navigation={props.navigation}
              target={ScreenNames.ContributionsAdministration}
            />
          </>
        )}
        <ItemRowScreenOpener
          title={t('survey_administration')}
          navigation={props.navigation}
          icon="comment"
          target={ScreenNames.SurveyAdministration}
        />
        {isAdmin && hasLicenceOrganizationalChart(user) && (
          <ItemRowScreenOpener
            title={t('organizational_charts')}
            navigation={props.navigation}
            icon="sitemap"
            target={ScreenNames.OrganizationalChartAdmin}
          />
        )}
        <ItemRowScreenOpener
          title={t('groups')}
          navigation={props.navigation}
          icon="users-gear"
          target={ScreenNames.CompanyGroupAdmin}
        />
        {isAdmin && !isMobile() && (
          <>
            <ItemRowScreenOpener
              title={t('intern_tv_screen')}
              navigation={props.navigation}
              icon="tv"
              target={ScreenNames.InternScreenAdmin}
            />
            {hasLicenceExternalScreen(user) && (
              <ItemRowScreenOpener
                title={t('public_tv_screen')}
                navigation={props.navigation}
                icon="chromecast"
                target={ScreenNames.ScreenAdmin}
              />
            )}
            <ItemRowScreenOpener
              title={t('validation_map')}
              navigation={props.navigation}
              icon="check-circle"
              target={ScreenNames.ValidationMap}
            />
          </>
        )}
        <ItemRow
          title={isAdmin ? t('admin_guide') : t('subadmin_guide')}
          onPress={() =>
            openURL(
              'https://support-konecteam.notion.site/Guide-Responsable-Administrateur-a5a43f20354d4b1d829da8be7ab56f49?pvs=4'
            )
          }
          icon="book"
        />
        {isAdmin && user.entreprise?.registerCode && (
          <View style={{ marginLeft: 6 }}>
            <Space2 />
            <AppText style={{ fontSize: 16, flex: 1, color: DARK_GRAY }} mode={Mode.BOLD}>
              {t('company_code')}
            </AppText>
            <Space1 />
            <AppText
              style={{
                fontSize: 20,
                alignSelf: 'flex-start',
                borderWidth: 1,
                padding: 4,
                paddingHorizontal: 6,
              }}
            >
              {user.entreprise.registerCode}
            </AppText>
          </View>
        )}
      </ScrollView>
    </AppScreen>
  );
}
