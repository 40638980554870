import React, { useEffect } from 'react';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { useDispatch } from 'react-redux';
import { fetchUserData } from '../../services/storage';
import { ContributionListScreen } from './contribution/ContributionListScreen';
import { t } from '../../services/translations';
import { ContributionType } from '../../types';
import { GetContributionsMode } from '../../services/api/getContributionsMode';
import { isMobile } from '../../services/utils';
import { ScreenNames } from '../../ScreenNames';
import useLoggedUser from '../../hooks/useLoggedUser';

export function LoggedInHomeScreen(props: { navigation }) {
  const Tab = createMaterialTopTabNavigator();
  const dispatch = useDispatch();
  const loggedUser = useLoggedUser();

  // Refetch user data every minute
  useEffect(() => {
    const interval = setInterval(() => {
      fetchUserData(loggedUser.token, dispatch);
    }, 1 * 60 * 1000);

    return () => clearInterval(interval);
  }, [loggedUser.token, dispatch]);

  return (
    <Tab.Navigator screenOptions={{ swipeEnabled: isMobile() }}>
      <Tab.Screen
        name={ScreenNames.CompanyNewsScreen}
        component={ContributionListScreen}
        options={{ title: t('contribution_company_news_plural') }}
        initialParams={{
          getContributionsMode: GetContributionsMode.all,
          contributionType: ContributionType.COMPANY_NEWS,
        }}
      />
      <Tab.Screen
        name={ScreenNames.ContributionsAllScreen}
        component={ContributionListScreen}
        options={{ title: t('contributions') }}
        initialParams={{
          getContributionsMode: GetContributionsMode.all,
          contributionType: ContributionType.ALL,
        }}
      />
    </Tab.Navigator>
  );
}
