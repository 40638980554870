import React, { useState } from 'react';
import { useField } from 'formik';
import PhotoFormField, { PhotoFormFieldProps } from '../../containers/PhotoFormField';
import { PickPhotoOrDocumentResult } from '../../../services/utils';

export type PhotoFieldProps = Omit<
  Omit<Omit<Omit<PhotoFormFieldProps, 'photo'>, 'onPhotoChanged'>, 'videoUrl'>,
  'onVideoUrlChanged'
> & {
  name: string;
};

export default function PhotoField({ name, ...rest }: PhotoFieldProps) {
  const [, , helper] = useField(name);
  const [videoUrlField, , videoUrlHelper] = useField('videoUrl');
  const [photo, setPhoto] = useState<PickPhotoOrDocumentResult | null>(null);

  return (
    <PhotoFormField
      {...rest}
      photo={photo}
      videoUrl={videoUrlField.value}
      onPhotoChanged={(file) => {
        helper.setValue(file || undefined, true);
        setPhoto(file);
      }}
      onVideoUrlChanged={videoUrlHelper.setValue}
    />
  );
}
