import { Dimensions, ScrollView, View } from 'react-native';
import React, { useEffect, useState } from 'react';
import { TextFormField } from '../../components/atomic/FormField';
import AppText, { Mode } from '../../components/atomic/AppText';
import { t } from '../../services/translations';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../store/action';
import { Image } from '@rneui/base';
import { AppScreen } from '../../components/containers/AppScreen';
import { AppButton, Type } from '../../components/atomic/AppButton';
import {
  CompanyGeoSiteReduced,
  CompanyGroupReduced,
  CompanyServiceReduced,
  getCompanyGeoSites,
  getCompanyServices,
  getLoggedUserCompanyGroups,
} from '../../services/api/company.api';
import { alertInfo, isMobile } from '../../services/utils';
import { Space2 } from '../../styles/spaces';
import { BackButton } from '../../components/atomic/BackButton';
import { ProfilePicture } from '../../components/atomic/ProfilePicture';
import { genericBackgroundHeader, headerBackgroundPopup } from '../../services/assets';
import { logout } from '../../services/api/login';
import { TwoActionsGenericPopup } from '../../components/containers/TwoActionsButtonPopup';
import { Header2 } from '../../components/containers/Header2';
import { ResponsiveRow } from '../../components/containers/ResponsiveRow';
import { ResponsiveColumn } from '../../components/containers/ResponsiveColumn';
import { LinearGradient } from 'expo-linear-gradient';
import { PRIMARY_COLOR, SECONDARY_COLOR } from '../../styles/appColor';
import { ScreenNames } from '../../ScreenNames';
import { removeAccount } from '../../services/api/user.api';
import useLoggedUser from '../../hooks/useLoggedUser';

export function ProfileScreen(props: { navigation }) {
  const [companyServices, setCompanyServices] = useState<CompanyServiceReduced[]>([]);
  const [companyGeoSites, setCompanyGeoSites] = useState<CompanyGeoSiteReduced[]>([]);
  const [companyGroups, setCompanyGroups] = useState<CompanyGroupReduced[]>([]);

  const dispatch = useDispatch();
  const user = useLoggedUser();

  useEffect(() => {
    dispatch(setLoading(true));

    const companyServicePromise = getCompanyServices(user.entrepriseId, user.token!).then(setCompanyServices);
    const companyGeoSitesPromise = getCompanyGeoSites(user.entrepriseId, user.token!).then(setCompanyGeoSites);
    const companyGroupsPromise = getLoggedUserCompanyGroups(user.token!).then(setCompanyGroups);
    const promise = Promise.all([companyServicePromise, companyGeoSitesPromise, companyGroupsPromise]);
    promise.finally(() => dispatch(setLoading(false)));
  }, []);

  let service = companyServices.filter((v) => v.id === user.entrepriseServiceId).pop()?.libelle;
  let geoSite = companyGeoSites.filter((v) => v.id === user.entrepriseSiteGeoId).pop()?.geoSite;
  let group = companyGroups
    .filter((v) => user.entrepriseGroups.find((e) => e.entrepriseGroupId === v.id) !== undefined)
    .reverse()
    .pop()?.label;

  return (
    <AppScreen style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}>
      <ScrollView contentContainerStyle={{ flexDirection: 'column' }} bounces={false}>
        <View style={{ flexDirection: 'column', alignItems: 'center', marginBottom: 25 }}>
          {isMobile() ? (
            <>
              <Image
                source={headerBackgroundPopup}
                style={{
                  width: Dimensions.get('window').width,
                  height: Dimensions.get('window').width / 2.603,
                }}
                resizeMode="cover"
              />

              <ProfilePicture
                pictureId={user.fichierIdProfil}
                size="big"
                borderStyle={{ marginTop: -80 }}
                borderColor="white"
              />
            </>
          ) : (
            <>
              <LinearGradient
                colors={[PRIMARY_COLOR, SECONDARY_COLOR]}
                start={{ x: 0, y: 0.5 }}
                end={{ x: 1, y: 0.5 }}
                style={{ width: '100%' }}
              >
                <Image
                  source={genericBackgroundHeader}
                  style={{ width: '100%', height: 200 }}
                  resizeMode="repeat"
                  resizeMethod="auto"
                />
              </LinearGradient>
              <ProfilePicture
                pictureId={user.fichierIdProfil}
                size="big"
                borderStyle={{ marginTop: -45 }}
                borderColor="white"
              />
            </>
          )}
        </View>
        <View style={{ padding: 10 }}>
          <AppText
            mode={Mode.BOLD}
            style={{
              fontSize: 18,
              marginBottom: 30,
              textAlign: 'center',
            }}
          >
            {user.prenom} {user.nom}
          </AppText>
          <AppButton
            title={t('update_my_profile')}
            type={Type.SECONDARY}
            style={{ marginBottom: 39, maxWidth: 350, alignSelf: 'center' }}
            onPress={() => props.navigation.navigate(ScreenNames.EditMyProfile)}
          />
          <Header2>{t('personal_information')}</Header2>
          <ResponsiveRow>
            <ResponsiveColumn first={true}>
              <TextFormField displayOnly title={t('landline_number')} value={user.telephone} onChangeText={() => {}} />
            </ResponsiveColumn>
            <ResponsiveColumn>
              <TextFormField displayOnly title={t('mobile_number')} value={user.portable} onChangeText={() => {}} />
            </ResponsiveColumn>
          </ResponsiveRow>
          <Space2 />
          <Header2>{t('professional_information')}</Header2>

          <ResponsiveRow>
            <ResponsiveColumn first={true}>
              <TextFormField displayOnly title={t('job_title')} value={user.fonction} onChangeText={() => {}} />
            </ResponsiveColumn>
            <ResponsiveColumn></ResponsiveColumn>
          </ResponsiveRow>

          <ResponsiveRow>
            <ResponsiveColumn first={true}>
              <TextFormField displayOnly title={t('service_faculty')} value={service} onChangeText={() => {}} />
            </ResponsiveColumn>
            <ResponsiveColumn>
              <TextFormField displayOnly title={t('geo_site')} value={geoSite} onChangeText={() => {}} />
            </ResponsiveColumn>
            <ResponsiveColumn>
              <TextFormField displayOnly title={t('group')} value={group} onChangeText={() => {}} />
            </ResponsiveColumn>
          </ResponsiveRow>
          <TwoActionsGenericPopup
            title={t('are_you_sure_remove_account')}
            description={t('remove_account_description')}
            button1Title={t('yes')}
            button2Title={t('no')}
            onPressButton1={() => {
              removeAccount(user, user.token as string).then(() => {
                return logout(user.token, dispatch).then(() => {
                  alertInfo(t('account_has_been_removed'));
                });
              });
            }}
            onPressButton2={() => {}}
          >
            <AppText
              style={{
                color: '#c7c7c7',
                alignSelf: 'flex-end',
                marginBottom: 10,
              }}
            >
              {t('remove_my_account')}
            </AppText>
          </TwoActionsGenericPopup>
        </View>
      </ScrollView>
      <BackButton />
    </AppScreen>
  );
}
