import { ContributionType, IntToContributionType } from '../../types';
import { language, t } from '../translations';
import { NEWS_TRANSLATION_ENABLED } from '../features';
import { Contributions } from '../storage';

const typeKey: { [key: number]: keyof Contributions } = {
  [ContributionType.DOCUMENT]: 'kiosques',
  [ContributionType.SELL]: 'ventes',
  [ContributionType.EVENT]: 'evenements',
  [ContributionType.FORMATION]: 'formations',
  [ContributionType.CARPOOL]: 'covoiturages',
  [ContributionType.ACTIVITY]: 'hobbies',
  [ContributionType.MUTUAL_AID]: 'services',
  [ContributionType.SURVEY]: 'cSondages',
  [ContributionType.COMPANY_NEWS]: 'mActuNews',
};

export function getDataContentByType(data: any, contributionType: ContributionType) {
  switch (contributionType) {
    case ContributionType.SELL:
      return data?.vente;
    case ContributionType.ACTIVITY:
      return data?.hobbie;
    case ContributionType.MUTUAL_AID:
    case ContributionType.CARPOOL:
    case ContributionType.EVENT:
    case ContributionType.FORMATION:
    case ContributionType.LOST_PROPERTY:
    case ContributionType.SURVEY:
    case ContributionType.SUGGESTION:
    case ContributionType.COMPANY_NEWS:
    case ContributionType.DOCUMENT:
      return data;
  }
}

export function getTitleByType(typedData: any, contributionType: ContributionType, translated?: boolean) {
  switch (contributionType) {
    case ContributionType.ACTIVITY:
      if (typedData.activite === undefined) {
        return typedData.contributionHobbie[0].activite;
      }
      return typedData.activite;
    case ContributionType.MUTUAL_AID:
      if (typedData.theme === undefined) {
        return typedData.contributionService[0].theme;
      }
      return typedData.theme;
    case ContributionType.CARPOOL:
      if (typedData.libelleDepart === undefined)
        return (
          typedData.contributionCovoiturage[0].libelleDepart + '-' + typedData.contributionCovoiturage[0].libelleArrivee
        );
      else return typedData.libelleDepart + '-' + typedData.libelleArrivee;
    case ContributionType.LOST_PROPERTY:
      if (typedData.description === undefined) {
        return typedData.contributionObjet[0].description;
      }
      return typedData.description;
    case ContributionType.EVENT:
      if (typedData.titre === undefined) {
        return typedData.contributionEvenement[0].titre;
      }
      return typedData.titre;
    case ContributionType.FORMATION:
      if (typedData.titre === undefined) {
        return typedData.contributionEvenement[0].titre;
      }
      return typedData.titre;
    case ContributionType.SELL:
      if (typedData.titre === undefined) {
        return typedData.contributionVente[0].titre;
      }
      return typedData.titre;
    case ContributionType.DOCUMENT:
      if (typedData.title === undefined) {
        return typedData.contributionKiosque[0].title;
      }
      return typedData.titre;
    case ContributionType.SURVEY:
      if (typedData.titre === undefined) {
        return typedData.cSondage[0].titre;
      }
      return typedData.titre;
    case ContributionType.COMPANY_NEWS:
      if (typedData.titre === undefined) {
        return t('company_news');
      }

      if (translated && NEWS_TRANSLATION_ENABLED) {
        let traduction = JSON.parse(typedData.traduction);
        if (traduction != null) {
          for (const traductionElement of traduction) {
            if (traductionElement.lng == language()) {
              return traductionElement.titre;
            }
          }
        }
      }
      return typedData.titre;
    case ContributionType.SUGGESTION:
      return typedData.titre;
    default:
      return '';
  }
}

export function getDescriptionByType(typedData: any, contributionType: ContributionType, translated?: boolean) {
  switch (contributionType) {
    case ContributionType.ACTIVITY:
      if (typedData.activite === undefined) {
        return typedData.contributionHobbie[0].description;
      }
      return typedData.description;
    case ContributionType.MUTUAL_AID:
      if (typedData.theme === undefined) {
        return typedData.contributionService[0].description;
      }
      return typedData.description;
    case ContributionType.CARPOOL:
      if (typedData.libelleDepart === undefined) return typedData.contributionCovoiturage[0].description;
      else return typedData.description;
    case ContributionType.LOST_PROPERTY:
      if (typedData.description === undefined) {
        return typedData.contributionObjet[0].description;
      }
      return typedData.description;
    case ContributionType.EVENT:
      if (typedData.titre === undefined) {
        return typedData.contributionEvenement[0].description;
      }
      return typedData.description;
    case ContributionType.FORMATION:
      if (typedData.titre === undefined) {
        return typedData.contributionEvenement[0].description;
      }
      return typedData.description;
    case ContributionType.SELL:
      if (typedData.titre === undefined) {
        return typedData.contributionVente[0].description;
      }
      return typedData.description;
    case ContributionType.DOCUMENT:
      if (typedData.description === undefined) {
        return typedData.contributionKiosque[0].description;
      }
      return typedData.description;
    case ContributionType.SURVEY:
      if (typedData.titre === undefined) {
        return typedData.cSondage[0].texte;
      }
      return typedData.texte;
    case ContributionType.COMPANY_NEWS:
      if (typedData.texte === undefined) {
        console.error('Failed to retrieve company news');
        console.error(typedData);
        return t('company_news');
      }
      if (translated && NEWS_TRANSLATION_ENABLED) {
        let traduction = JSON.parse(typedData.traduction);
        if (traduction != null) {
          for (const traductionElement of traduction) {
            if (traductionElement.lng == language()) {
              return traductionElement.description;
            }
          }
        }
      }
      return typedData.texte;
    case ContributionType.SUGGESTION:
      return typedData.texte;
    default:
      return '';
  }
}

export function resolveContributionType(item) {
  try {
    if (item.contributionTypeId != null) {
      let typeFromId = IntToContributionType(item.contributionTypeId);
      if (typeFromId != ContributionType.UNKNOWN) return typeFromId;
    }
    if (item.contributionVente != null && item.contributionVente.length !== 0) return ContributionType.SELL;
    if (item.contributionCovoiturage != null && item.contributionCovoiturage.length !== 0)
      return ContributionType.CARPOOL;
    if (
      item.contributionEvenement != null &&
      item.contributionEvenement.length !== 0 &&
      0 === item.contributionEvenement[0].type
      // && [null, ContributionEvenementType.EVENT].includes(item.contributionEvenement[0].type)
    )
      return ContributionType.EVENT;
    if (
      item.contributionEvenement != null &&
      item.contributionEvenement.length !== 0 &&
      1 === item.contributionEvenement[0].type
    )
      return ContributionType.FORMATION;
    if (item.contributionHobbie != null && item.contributionHobbie.length !== 0) return ContributionType.ACTIVITY;
    if (item.contributionObjet != null && item.contributionObjet.length !== 0) return ContributionType.LOST_PROPERTY;
    if (item.contributionService != null && item.contributionService.length !== 0) return ContributionType.MUTUAL_AID;
    if (item.cSondage != null && item.cSondage.length !== 0) return ContributionType.SURVEY;
    if (item.contributionKiosque != null && item.contributionKiosque.length !== 0) return ContributionType.DOCUMENT;
    return ContributionType.UNKNOWN;
  } catch (e) {
    console.error(e);
    return ContributionType.UNKNOWN;
  }
}

export function getIdFromItem(item, contributionType: ContributionType) {
  let base = item;
  switch (contributionType) {
    case ContributionType.ACTIVITY:
      if (item.contributionHobbie?.length > 0) {
        base = item.contributionHobbie[0];
      }
      break;
    case ContributionType.MUTUAL_AID:
      if (item.contributionService?.length > 0) {
        base = item.contributionService[0];
      }
      break;
    case ContributionType.CARPOOL:
      if (item.contributionCovoiturage?.length > 0) {
        base = item.contributionCovoiturage[0];
      }
      break;
    case ContributionType.LOST_PROPERTY:
      if (item.contributionObjet?.length > 0) {
        base = item.contributionObjet[0];
      }
      break;
    case ContributionType.EVENT:
      if (item.contributionEvenement?.length > 0) {
        base = item.contributionEvenement[0];
      }
      break;
    case ContributionType.FORMATION:
      if (item.contributionEvenement?.length > 0) {
        base = item.contributionEvenement[0];
      }
      break;
    case ContributionType.SELL:
      if (item.contributionVente?.length > 0) {
        base = item.contributionVente[0];
      }
      break;
    case ContributionType.SURVEY:
      if (item.cSondage?.length > 0) {
        base = item.cSondage[0];
      }
      break;
    case ContributionType.COMPANY_NEWS:
    default:
      break;
  }
  return base.id;
}

/**
 * The structure of the response of the api changes depending on the endpoint.
 * This function returns a formatted type for requests to retrieve multiple contribution types.
 * @param data
 * @param contributionType The type of the contribution. Generally, resolveContributionType is called before.
 */
export function extractContributionFromResponse(data: any, contributionType: ContributionType) {
  switch (contributionType) {
    case ContributionType.EVENT:
      return data.contributionEvenement[0];
    case ContributionType.FORMATION:
      return data.contributionEvenement[0];
    case ContributionType.SELL:
      return data.contributionVente[0];
    case ContributionType.ACTIVITY:
      return data.contributionHobbie[0];
    case ContributionType.MUTUAL_AID:
      return data.contributionService[0];
    case ContributionType.CARPOOL:
      return data.contributionCovoiturage[0];
    case ContributionType.LOST_PROPERTY:
      return data.contributionObjet[0];
    case ContributionType.SURVEY:
      return data.cSondage[0];
    case ContributionType.DOCUMENT:
      return data.contributionKiosque[0];
    case ContributionType.SUGGESTION:
    case ContributionType.COMPANY_NEWS:
    case ContributionType.ALL:
    case ContributionType.UNKNOWN:
    default:
      return data;
  }
}

export function contributionTypeToText(_contributionType: ContributionType) {
  switch (_contributionType) {
    case ContributionType.SELL:
      return t('contribution_sell');
    case ContributionType.ACTIVITY:
      return t('contribution_activity');
    case ContributionType.MUTUAL_AID:
      return t('contribution_mutual_aid');
    case ContributionType.CARPOOL:
      return t('contribution_carpool');
    case ContributionType.EVENT:
      return t('contribution_event');
    case ContributionType.FORMATION:
      return t('contribution_training');
    case ContributionType.LOST_PROPERTY:
      return t('contribution_lost_property');
    case ContributionType.SURVEY:
      return t('contribution_survey_label');
    case ContributionType.SUGGESTION:
      return t('contribution_suggestion');
    case ContributionType.COMPANY_NEWS:
      return t('contribution_company_news');
    case ContributionType.DOCUMENT:
      return t('contribution_document');
    case ContributionType.UNKNOWN:
      return t('contribution_unknown');
    case ContributionType.ALL:
    default:
      return t('contribution_all');
  }
}

export const contributionColors = {
  [ContributionType.SELL]: '#e1b5f5',
  [ContributionType.ACTIVITY]: '#515c59',
  [ContributionType.MUTUAL_AID]: '#b0ca41',
  [ContributionType.CARPOOL]: '#47a0a7',
  [ContributionType.EVENT]: '#e0d03c',
  [ContributionType.FORMATION]: '#e04c3c',
  [ContributionType.LOST_PROPERTY]: '#170386',
  [ContributionType.SURVEY]: '#55ae48',
  [ContributionType.UNKNOWN]: '#4F5ADF',
  [ContributionType.COMPANY_NEWS]: '#F49550',
  [ContributionType.DOCUMENT]: '#6ef450',
  [ContributionType.SUGGESTION]: '#4F5ADF',
  [ContributionType.ALL]: '#4F5ADF',
};

export function contributionTypeToColor(_contributionType: ContributionType): string {
  return (contributionColors as any)[_contributionType] || '#4F5ADF';
}
