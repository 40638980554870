import { DrawerScreenProps } from '@react-navigation/drawer';
import { Icon } from '@rneui/base';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useDispatch } from 'react-redux';
import { AppButton, Type } from '../../../../components/atomic/AppButton';
import { AppCheckbox } from '../../../../components/atomic/AppCheckbox';
import AppSwitch from '../../../../components/atomic/AppSwitch';
import AppText, { Mode } from '../../../../components/atomic/AppText';
import { FormField, FormFieldPicker, RTEFormField, TextFormField } from '../../../../components/atomic/FormField';
import LanguageSelector from '../../../../components/atomic/LanguageSelector';
import PublicationModePicker from '../../../../components/atomic/PublicationModePicker';
import { DefaultBackground } from '../../../../components/containers/AppScreen';
import FormLoading from '../../../../components/containers/FormLoading';
import PhotoFormField from '../../../../components/containers/PhotoFormField';
import PickUsersForm from '../../../../components/containers/PickUsersForm';
import useCategories from '../../../../hooks/useCategories';
import useEditCompanyNews from '../../../../hooks/useEditCompanyNews';
import { getLibelleFromCode } from '../../../../languages/language_mapping';
import { CompanyNewsUpload } from '../../../../models/CompanyNews';
import { getContributionImageSource } from '../../../../services/api/helper.api';
import { postCompanyNews, putCompanyNews } from '../../../../services/api/news.contributions.api';
import { NEWS_PUBLICATION_MODE_ENABLED } from '../../../../services/features';
import { availableLanguages, t } from '../../../../services/translations';
import {
  alertInfo,
  hasSubAdminRight,
  isAdmin,
  isMobile,
  PickPhotoOrDocumentResult,
  sendSuccessText,
  submitButtonText,
} from '../../../../services/utils';
import { setContributionsListNeedsRefresh, setLoading } from '../../../../store/action';
import { ContributionType } from '../../../../types';
import { ExtractUri } from '../../../../utils/Medias';
import useLoggedUser from '../../../../hooks/useLoggedUser';

type ScreenProps = DrawerScreenProps<any>;

const CompanyNewsFormScreen = (props: ScreenProps) => {
  const dispatch = useDispatch();
  const user = useLoggedUser();
  const [photo, setPhoto] = useState<PickPhotoOrDocumentResult | null>(null);
  const { data, loading, onChange, valid } = useEditCompanyNews(props.route.params?.id);
  const { categories } = useCategories(ContributionType.COMPANY_NEWS);

  useEffect(() => {
    if (data.videoUrl === '' && !!data?.videoUrl) onChange('videoUrl')(ExtractUri(data.videoUrl));
  }, [data.videoUrl]);

  if (loading) return <FormLoading />;

  const submit = () => {
    const hasRights =
      isAdmin(user) ||
      hasSubAdminRight(user, data.entrepriseServiceIds, data.entrepriseSiteGeoIds, data.entrepriseGroupIds);
    const body: CompanyNewsUpload = { ...data };

    const onSubmitted = () => {
      dispatch(setLoading(false));
      alertInfo(sendSuccessText(user, ContributionType.COMPANY_NEWS, hasRights));
      dispatch(setContributionsListNeedsRefresh(true));
      props.navigation.goBack();
    };

    dispatch(setLoading(true));
    const promise = !props.route.params?.id
      ? postCompanyNews(user, body, photo || undefined)
      : putCompanyNews(user, props.route.params.id, body, photo || undefined);
    promise.then(onSubmitted).catch(() => {
      alertInfo(t('error_occurred'));
      dispatch(setLoading(false));
    });
  };

  return (
    <DefaultBackground style={{ padding: isMobile() ? 10 : 30 }}>
      <KeyboardAwareScrollView
        enableResetScrollToCoords={false}
        keyboardShouldPersistTaps="never"
        style={{ padding: 10 }}
        keyboardDismissMode="on-drag"
      >
        <View style={{ flex: 1, flexDirection: 'column', alignItems: 'center' }}>
          <PhotoFormField
            defaultPhoto={getContributionImageSource(data.fichierId, ContributionType.COMPANY_NEWS)}
            photo={photo}
            videoUrl={data.videoUrl}
            allowPdf
            onPhotoChanged={setPhoto}
            onVideoUrlChanged={onChange('videoUrl')}
          />
        </View>
        <TextFormField mandatory onChangeText={onChange('titre')} value={data.titre} title={t('title') + '*'} />
        <RTEFormField onChangeText={onChange('texte')} defaultValue={data.texte} title={t('description') + '*'} />
        <FormField title={t('translations')}>
          {data.translations!.map((tr, k) => {
            return (
              <View key={k} style={{ borderTopWidth: 1 }}>
                <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                  <AppText
                    mode={Mode.BOLD}
                    style={{
                      marginBottom: 10,
                      fontSize: 20,
                      textAlign: 'center',
                      flex: 1,
                      marginTop: 10,
                    }}
                  >
                    {getLibelleFromCode(tr.lng)}
                  </AppText>
                  <TouchableOpacity
                    onPress={() => {
                      let newTranslations = data.translations!.filter((el) => el.lng !== tr.lng);
                      onChange('translations')(newTranslations);
                    }}
                  >
                    <Icon name="close" />
                  </TouchableOpacity>
                </View>
                <TextFormField
                  mandatory
                  onChangeText={(value) => {
                    let newTranslations = data.translations!.map((el) =>
                      el.lng === tr.lng ? { ...el, titre: value } : el
                    );
                    onChange('translations')(newTranslations);
                  }}
                  value={tr.titre}
                  title={t('title') + ' (' + tr.lng + ')*'}
                />
                <RTEFormField
                  onChangeText={(value) => {
                    let newTranslations = data.translations!.map((el) =>
                      el.lng === tr.lng ? { ...el, description: value } : el
                    );
                    onChange('translations')(newTranslations);
                  }}
                  defaultValue={tr.description}
                  title={t('description') + ' (' + tr.lng + ')*'}
                />
              </View>
            );
          })}
          <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginBottom: 10 }}>
            {availableLanguages
              .filter((e) => data.translations!.findIndex((x) => x.lng === e.code) < 0)
              .map((e) => (
                <LanguageSelector
                  key={e.code}
                  language={e}
                  setTranslations={onChange('translations')}
                  texte={data.texte}
                  titre={data.titre}
                  translations={data.translations}
                />
              ))}
          </View>
          <View style={{ marginVertical: 20 }}>
            <AppCheckbox title={t('featured')} onValueChange={onChange('featured')} value={data.featured ?? false} />
          </View>
          <PublicationModePicker
            mode={data.publicationMode}
            visibleStartDate={data.visibleStartDate}
            visibleEndDate={data.visibleEndDate}
            onChangeMode={onChange('publicationMode')}
            onChangeStartDate={onChange('visibleStartDate')}
            onChangeEndDate={onChange('visibleEndDate')}
          />
        </FormField>
        <PickUsersForm onChange={onChange} data={data} />
        <FormFieldPicker<string>
          style={{ marginTop: 24 }}
          title={t('categories')}
          displayOnly={NEWS_PUBLICATION_MODE_ENABLED}
          defaultValue={data.categoryId?.toString()}
          onChangeText={(value) => {
            if (value === t('select_an_item')) onChange('categoryId')(null);
            else onChange('categoryId')(isNaN(parseInt(value)) ? null : parseInt(value));
          }}
          data={categories.map((category) => ({ label: category.name, value: category.id.toString() }))}
        />
        <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
          <View style={{ flexDirection: 'row', marginTop: 40, marginRight: 40 }}>
            <AppSwitch
              onValueChange={onChange('commentsActivated')}
              value={!!data?.commentsActivated}
              style={{ marginRight: 28 }}
            />
            <AppText>{t('authorize_comments')}</AppText>
          </View>
          <View style={{ flexDirection: 'row', marginTop: 40, marginRight: 40 }}>
            <AppSwitch
              onValueChange={onChange('notifActivated')}
              value={!!data?.notifActivated}
              style={{ marginRight: 28 }}
            />
            <AppText>{t('generate_notif')}</AppText>
          </View>
          {isAdmin(user) && (
            <View style={{ flexDirection: 'row', marginTop: 40 }}>
              <AppSwitch
                onValueChange={onChange('emailActivated')}
                value={!!data?.emailActivated}
                style={{ marginRight: 28 }}
              />
              <AppText>{t('generate_email')}</AppText>
            </View>
          )}
        </View>
        <View style={{ marginTop: 30, marginBottom: 100 }}>
          <AppButton
            title={submitButtonText(
              user,
              props.route.params?.id,
              ContributionType.COMPANY_NEWS,
              data.entrepriseServiceIds,
              data.entrepriseSiteGeoIds,
              data.entrepriseGroupIds
            )}
            type={Type.PRIMARY}
            onPress={submit}
            enabled={valid}
          />
        </View>
      </KeyboardAwareScrollView>
    </DefaultBackground>
  );
};

export default CompanyNewsFormScreen;
