import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { ImageSourcePropType } from 'react-native';
import { unsplashAccessKey } from '../../configuration';

export type UnsplashImage = {
  file: ImageSourcePropType;
  uri: string;
  author: string;
  url: string;
};

export default function useUnsplash() {
  const [, setQuery] = useState('');
  const [, setLastQuery] = useState('');
  const [, setPage] = useState(0);
  const [, setLastPage] = useState(0);
  const [images, setImages] = useState<UnsplashImage[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const searchForUnsplash = async (query: string, page: number) => {
    setIsLoading(true);

    const isQueryEmpty = !query || query.trim().length === 0;

    try {
      const response = !isQueryEmpty
        ? await axios.get('https://api.unsplash.com/search/photos', {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            params: { query, client_id: unsplashAccessKey, page: page + 1 },
          })
        : await axios.get('https://api.unsplash.com/photos', {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            params: { client_id: unsplashAccessKey },
          });

      if (response.status == 200) {
        const results = response.data.results || response.data;
        const images = results.map((image: any) => ({
          uri: image.urls.regular,
          id: image.id,
          author: image.user.name,
          url: `${image.user.links.html}?utm_source=Konecteam&utm_medium=referral`,
        })) as UnsplashImage[];

        setImages((old) => {
          const newArr = [...old];
          newArr.splice(page * 10, 10, ...images);

          return newArr;
        });
      }
    } catch (err: any) {
    } finally {
      setIsLoading(false);
      setLastQuery(query);
      setLastPage(page);
    }
  };

  // Search every seconds to avoid  over-requesting
  useEffect(() => {
    const intervalId = setInterval(async () => {
      setQuery((query) => {
        setLastQuery((lastQuery) => {
          setPage((page) => {
            setLastPage((lastPage) => {
              if (query !== lastQuery || page !== lastPage) {
                searchForUnsplash(query, page);
              }

              return lastPage;
            });

            return page;
          });

          return lastQuery;
        });

        return query;
      });
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const searchImages = useCallback(async (query: string) => {
    setPage(0);
    setIsLoading(query !== '');
    setQuery(query);
    setImages([]);
  }, []);

  const fetchNext = useCallback(() => {
    setPage((page) => page + 1);
  }, []);

  const notifyImageDownload = useCallback(
    async (id: string) => {
      await axios.get(`https://api.unsplash.com/photos/${id}/download`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        params: { client_id: unsplashAccessKey },
      });
    },
    [unsplashAccessKey]
  );

  return {
    images,
    isLoading,
    notifyImageDownload,
    searchImages,
    fetchNext,
  };
}
