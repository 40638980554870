import axios from 'axios';
import { headers } from './base';
import { apiUrlBase } from '../../../configuration';
import { Cinterest } from '../../entities/Cinterest';
import { Paginated } from '../../entities/Paginated';
import { UserSaved } from '../storage';

export async function getServiceInterests(
  user: UserSaved,
  id: number,
  cursor: string | null
): Promise<Paginated<Cinterest, string>> {
  return axios
    .get(`${apiUrlBase}ContributionServices/${id}/interests/paginated`, {
      headers: headers(user.token),
      params: { cursor },
    })
    .then((response) => {
      if (response.status == 200) return response.data;
      else return null;
    });
}
