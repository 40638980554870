import React, { useCallback, useMemo } from 'react';
import { StyleProp, StyleSheet, Text, TouchableOpacity, View, ViewStyle } from 'react-native';
import Swipeable from 'react-native-gesture-handler/Swipeable';
import AppText from './AppText';
import { ProfilePicture } from './ProfilePicture';
import { FONT_FAMILY_DEFAULT, globalStyle } from '../../styles/globalStyle';
import { MessagingThread } from '../../entities/MessagingThread';
import { navigateToMessagingThread } from '../../utils/Messaging';
import { t } from '../../services/translations';
import { deleteMessagingThread } from '../../store/messaging';
import { useDispatch } from 'react-redux';
import { IconButton } from './IconButton';
import { isMobile } from '../../services/utils';
import { Badge } from './Badge';
import { ALERT_COLOR } from '../../styles/appColor';
import useLoggedUser from '../../hooks/useLoggedUser';

export function MessagingThreadRow({ messagingThread }: { messagingThread: MessagingThread }) {
  const user = useLoggedUser();
  const dispatch = useDispatch();

  const lastMessageStyle: StyleProp<ViewStyle> = useMemo(
    () => ({
      fontSize: 12,
      marginBottom: 11,
      fontFamily: messagingThread.seen ? FONT_FAMILY_DEFAULT.regular : FONT_FAMILY_DEFAULT.bold,
    }),
    [messagingThread]
  );

  const deleteThread = useCallback(() => {
    deleteMessagingThread(dispatch, user, messagingThread.id);
  }, [dispatch, user, messagingThread.id]);

  const rightSwipeActions = useCallback(
    () => (
      <TouchableOpacity
        onPress={deleteThread}
        style={{
          backgroundColor: ALERT_COLOR,
          justifyContent: 'center',
          alignItems: 'flex-end',
        }}
      >
        <Text
          style={{
            fontWeight: '600',
            paddingHorizontal: 30,
            paddingVertical: 20,
            color: '#fff',
          }}
        >
          {t('delete')}
        </Text>
      </TouchableOpacity>
    ),
    [deleteThread]
  );

  return (
    <Swipeable renderRightActions={rightSwipeActions}>
      <TouchableOpacity onPress={() => navigateToMessagingThread(messagingThread)}>
        <View style={[globalStyle.listRow, styles.container]}>
          {messagingThread.unseenCount && messagingThread.unseenCount > 0 ? (
            <Badge value={messagingThread.unseenCount} />
          ) : null}
          <ProfilePicture pictureId={messagingThread.contactFichierIdProfil} size="medium" />
          <View style={styles.content}>
            <AppText
              style={{
                fontSize: 14,
                fontFamily: FONT_FAMILY_DEFAULT.bold,
                marginBottom: 11,
              }}
            >
              {messagingThread.contactFirstName} {messagingThread.contactLastName}
            </AppText>
            <AppText style={lastMessageStyle} numberOfLines={2}>
              {messagingThread.lastMessage}
            </AppText>
          </View>
          {!isMobile() ? <IconButton icon="delete" onPress={deleteThread} color="#b07476" /> : undefined}
        </View>
      </TouchableOpacity>
    </Swipeable>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    backgroundColor: 'white',
    marginHorizontal: 10,
  },
  content: {
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
    marginLeft: 10,
  },
});
